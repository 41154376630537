/**
 * @fileoverview
 *
 * PIXI particle config for the cursor.
 * @see {@link http://pixijs.io/particle-emitter/docs/}
 */

import { BehaviorEntry, EmitterConfigV3 } from "@pixi/particle-emitter";
import { Texture } from "pixi.js";
import particle from "../../../resources/textures/particle-smoke.webp";
import particleCircle from "../../../../../ChooseYourSpellGame/game/resources/textures/sparks.png";

const MOVE_SPEED: BehaviorEntry = {
  type: "moveSpeed",
  config: {
    speed: {
      list: [
        { time: 0, value: 1 },
        { time: 1, value: 1024 },
      ],
    },
  },
};

const SCALE: BehaviorEntry = {
  type: "scale",
  config: {
    scale: {
      list: [
        { time: 0, value: 0.05 },
        { time: 1, value: 3 },
      ],
    },
  },
};

const ROTATION: BehaviorEntry = {
  type: "rotationStatic",
  config: {
    min: 0,
    max: 360,
  },
};

const BLEND_MODE: BehaviorEntry = {
  type: "blendMode",
  config: { blendMode: "add" },
};

const TEXTURE: BehaviorEntry = {
  type: "textureRandom",
  config: {
    textures: [
      Texture.from(particle),
      Texture.from(particle),
      Texture.from(particleCircle),
    ],
  },
};

const BASE_CONFIG = {
  lifetime: { min: 1, max: 2 },
  frequency: 0.01,
  emitterLifetime: -1,
  maxParticles: 1024,
  pos: { x: -100, y: -100 },
};

export function createEmitterConfig(startColor: string, endColor: string) {
  const color = {
    type: "color",
    config: {
      color: {
        list: [
          { time: 0, value: startColor },
          { time: 1, value: endColor },
        ],
      },
    },
  };

  const config: EmitterConfigV3 = {
    ...BASE_CONFIG,
    behaviors: [MOVE_SPEED, ROTATION, SCALE, color, BLEND_MODE, TEXTURE],
  };

  return config;
}
