import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";

import { Instructions } from "../Instructions";
import { Quote } from "../Quote";

import styles from "./SceneManager.module.css";

interface SceneManagerProps {
  scene: JSX.Element;
  sceneWidth?: string;
  background?: string;
  quoteStopPercent?: number;
  sceneStopPercent?: number;
  onHasAnimationCompleted?: (ended: boolean) => void;
}

export const SceneManager = ({
  background,
  scene,
  sceneWidth = "100vw",
  sceneStopPercent = 50,
  onHasAnimationCompleted,
}: SceneManagerProps) => {
  const tl = useRef<GSAPTimeline>();
  const app = useRef(null);

  useLayoutEffect(() => {
    tl.current?.progress(0).kill();

    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline({
          autoRemoveChildren: true,
          smoothChildTiming: true,
          delay: 0.5,
        })
        .to("#quote-wrapper", { duration: 2, opacity: 0 }, 3.8)
        .to(
          "#scroll-wrapper",
          {
            // perf: uses CSS transition to animate this off main thread
            style: `transform: translate3d(0, 0, 0)`,
            duration: 2,
          },
          "<-0.2"
        )
        .to("#instructions-wrapper", { duration: 2, opacity: 1 }, ">+1")
        .to(
          "#instructions-wrapper",
          {
            duration: 1,
            opacity: 0,
            onComplete: () => {
              onHasAnimationCompleted && onHasAnimationCompleted(true);
            },
          },
          ">+0.4"
        );
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={app}>
      <div id="scene" className={styles.wrapper}>
        <div id="quote-wrapper" className={styles.quoteWrapper}>
          <Quote />
        </div>
        <div id="instructions-wrapper" className={styles.instructionsWrapper}>
          <Instructions />
        </div>
        <div
          id="scroll-wrapper"
          className={styles.scrollWrapper}
          style={{
            transform: `translate(calc(0.5 * ${sceneWidth} - 50vw), 0)`,
          }}
        >
          {background && (
            <img
              id="background"
              src={background}
              className={styles.background}
              style={{
                transform: `translate3d(${-sceneStopPercent}%, 0, 0)`,
              }}
              role="presentation"
            />
          )}
          <div id="scene-wrapper" className={styles.sceneWrapper}>
            {scene}
          </div>
        </div>
      </div>
    </div>
  );
};
