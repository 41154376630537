import {
  getInteractionOptions,
  InteractionOption,
  InteractionType,
} from "../../../../config/interactions";
import { TEXTURES } from "../resources";

export const OPTIONS = getInteractionOptions(InteractionType.CHOOSE_SPELL);

export enum OptionIdentifier {
  CASTLE_REDECORATE,
  CASTLE_MOVES,
  WITCHES_COMMAND,
  WITCHES_CURSE,
}

export interface RuneData {
  option: InteractionOption;
  texturePath: string;
  text: string;
  position: DOMPoint;
}

export const RUNE_DATA: RuneData[] = [
  {
    option: OPTIONS[OptionIdentifier.CASTLE_REDECORATE],
    texturePath: TEXTURES.RUNE_1,
    text: "Cowardice",
    position: new DOMPoint(0, 0),
  },
  {
    option: OPTIONS[OptionIdentifier.CASTLE_MOVES],
    texturePath: TEXTURES.RUNE_2,
    text: "Vanity",
    position: new DOMPoint(192, 128),
  },
  {
    option: OPTIONS[OptionIdentifier.WITCHES_COMMAND],
    texturePath: TEXTURES.RUNE_3,
    text: "Greed",
    position: new DOMPoint(0, 256),
  },
  {
    option: OPTIONS[OptionIdentifier.WITCHES_CURSE],
    texturePath: TEXTURES.RUNE_4,
    text: "Envy",
    position: new DOMPoint(192, 384),
  },
];
