import { Assets, BLEND_MODES, filters, Sprite } from "pixi.js";
import { MinimalContainer } from "../../../../../StarDemonGame/core/utilities/minimal-container";
import { TEXTURES } from "../../../resources";

export class ObjectToFindCollection extends MinimalContainer {
  readonly objectMask: Sprite;
  readonly filter = new filters.AlphaFilter();

  constructor() {
    super();
    const texture = Assets.get(TEXTURES.OBJECT_MASK);
    this.objectMask = new Sprite(texture);
    // Set a blending mode on this entire framebuffer.
    this.filter.blendMode = BLEND_MODES.ADD;
    this.filters = [this.filter];
  }

  // Add the mask after all the objects to find glow visuals have been added.
  onObjectsAdded() {
    this.addChild(this.objectMask);
  }
}
