import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Texture } from "pixi.js";
import { MinimalContainer } from "../../../../../StarDemonGame/core/utilities/minimal-container";
import particle from "../../../../../StarDemonGame/game/resources/textures/particle-smoke.webp";
import sparkParticle from "../../../resources/textures/sparks.png";
import circleParticle from "../../../resources/textures/circle.png";

const config = {
  lifetime: { min: 0.5, max: 2 },
  frequency: 0.01,
  emitterLifetime: -1,
  maxParticles: 500,
  addAtBack: true,
  pos: { x: 0, y: 0 },
  behaviors: [
    {
      type: "alpha",
      config: {
        alpha: {
          list: [
            { time: 0, value: 0.53 },
            { time: 1, value: 0 },
          ],
        },
      },
    },
    {
      type: "moveSpeed",
      config: {
        speed: {
          list: [
            { time: 0, value: 1 },
            { time: 1, value: 200 },
          ],
        },
        minMult: 1,
      },
    },
    {
      type: "scale",
      config: {
        scale: {
          list: [
            { time: 0, value: 0.001 },
            { time: 1, value: 1 },
          ],
        },
        minMult: 1,
      },
    },
    {
      type: "color",
      config: {
        color: {
          list: [
            { time: 0, value: "#85888d" },
            { time: 1, value: "#100f0c" },
          ],
        },
      },
    },
    { type: "rotationStatic", config: { min: -120, max: -60 } },
    {
      type: "spawnShape",
      config: {
        type: "torus",
        data: { x: 0, y: 0, radius: 80, innerRadius: 0, affectRotation: false },
      },
    },
    {
      type: "textureRandom",
      config: { textures: [Texture.from(particle)] },
    },
  ],
};

const sparksConfig = {
  alpha: {
    start: 1,
    end: 0,
  },
  scale: {
    start: 0.2,
    end: 1,
    minimumScaleMultiplier: 0.1,
  },
  color: {
    start: "#f78e05",
    end: "#ff0d00",
  },
  speed: {
    start: 1,
    end: 256,
    minimumSpeedMultiplier: 0.1,
  },
  acceleration: {
    x: 0,
    y: -64,
  },
  maxSpeed: 0,
  startRotation: {
    min: 0,
    max: 360,
  },
  noRotation: false,
  rotationSpeed: {
    min: 0,
    max: 0,
  },
  lifetime: {
    min: 0.2,
    max: 2,
  },
  blendMode: "screen",
  frequency: 0.005,
  emitterLifetime: -1,
  maxParticles: 256,
  pos: {
    x: 0,
    y: 0,
  },
  addAtBack: false,
  spawnType: "circle",
  spawnCircle: {
    x: 0,
    y: 0,
    r: 80,
  },
};

export class Particles extends MinimalContainer {
  readonly smoke: Emitter;
  readonly sparks: Emitter;

  constructor() {
    super();

    this.sparks = new Emitter(
      this,
      upgradeConfig(sparksConfig, [
        Texture.from(particle),
        Texture.from(sparkParticle),
        Texture.from(circleParticle),
      ])
    );
    this.sparks.spawnChance = 0;
    this.sparks.emit = true;
    this.sparks.autoUpdate = true;

    this.smoke = new Emitter(this, config);
    this.smoke.spawnChance = 0;
    this.smoke.emit = true;
    this.smoke.autoUpdate = true;
  }
}
