import { useEffect, useRef } from "react";
import { InteractionOption } from "../../config/interactions";
import { Game, GameEvent } from "./game";
import sophie2x from "../../assets/images/star-demon/sophie-2x.webp";
import sophie from "../../assets/images/star-demon/sophie.webp";
import styles from "./StarDemonGame.module.css";

interface StarDemonGameProps {
  active: boolean;
  onOptionSelected: (option: InteractionOption) => void;
}

export const StarDemonGame = ({
  active,
  onOptionSelected,
}: StarDemonGameProps) => {
  const container = useRef<HTMLDivElement | null>(null);
  const game = useRef<Game | null>(null);

  function onOptionSelect(event: CustomEventInit) {
    const option = event.detail;
    onOptionSelected(option);
  }

  useEffect(() => {
    const containerElement = container.current;
    if (!game.current && containerElement) {
      game.current = new Game(containerElement);
      containerElement.addEventListener(GameEvent.GAME_OVER, onOptionSelect);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener(
          GameEvent.GAME_OVER,
          onOptionSelect
        );
      }
      game.current?.dispose();
      game.current = null;
    };
  }, []);

  useEffect(() => {
    if (game.current) game.current.active = active;
  }, [active]);

  return (
    <div className={styles.wrapper}>
      <div ref={container} />
      <img
        src={sophie}
        srcSet={`${sophie}, ${sophie2x} 2x`}
        width={211}
        height={355}
        alt=""
        role="presentation"
        className={styles.sophie}
      />
    </div>
  );
};

StarDemonGame.defaultProps = {
  active: false,
};
