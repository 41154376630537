import { Instructions } from "../Instructions";

import styles from "./InstructionsModal.module.css";

interface InstructionsModalProps {
  open: boolean;
}

export const InstructionsModal = ({ open }: InstructionsModalProps) => {
  return open ? (
    <div className={styles.wrapper}>
      <Instructions />
    </div>
  ) : null;
};
