import { useTranslation } from "react-i18next";

//assets
import foreground from "./assets/foreground.png";
import qrGlobal from "./assets/qr_global.png";
//TODO : Get correct qr code for china!
import qrChina from "./assets/qr_china.png";

import styles from "./DeviceNotice.module.css";

export const DeviceNotice = () => {
  const { t, i18n } = useTranslation();
  return (
    <div role="status" className={styles.wrapper}>
      <div className={styles.header}>
        <h2
          className="heading-two"
          dangerouslySetInnerHTML={{ __html: t("desktop-redirect.title") }}
        />
      </div>
      <div className={styles.qrContainer}>
        <div className={styles.qr}>
          <img
            className={styles.qrImage}
            src={i18n.language === "zh" ? qrChina : qrGlobal}
            role="img"
          />
          <p
            className={styles.qrCopy}
            dangerouslySetInnerHTML={{ __html: t("desktop-redirect.copy") }}
          />
        </div>
        <img
          className={styles.foreground}
          src={foreground}
          role="presentation"
        />
      </div>
      <div className={styles.cloudsWrapper}>
        <div className={styles.clouds}></div>
      </div>
    </div>
  );
};
