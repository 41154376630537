/**
 * @fileoverview
 * TODO(pimdewit): docs.
 */

import { clamp } from "../../../../DoorDialGame/math";
import { SvgPath } from "../../resources/svg-path";
import { pathElement } from "./path-element";
import { samplePointsFromPath } from "./sample-points-from-path";

const POINT_CACHE = new Map<SvgPath, DOMPointReadOnly[]>();
const LENGTH_CACHE = new Map<SvgPath, number>();

export class PathTracer {
  readonly element: SVGGeometryElement;
  readonly length: number;
  readonly lastPoint = new DOMPoint();
  points: DOMPointReadOnly[] = [];
  offset = { x: 0, y: 0 };
  progress = 0;

  constructor(path: SvgPath, step = 16) {
    this.element = pathElement(path);
    const pointCache = POINT_CACHE.get(path);
    const lengthCache = LENGTH_CACHE.get(path);
    if (pointCache && lengthCache) {
      this.length = lengthCache;
      this.points = pointCache;
    } else {
      // An SVGGeometryElement is needed as it exposes the `getTotalLength` API.
      this.length = this.element.getTotalLength();

      // Ensure there is a minimum of 2 points.
      const stepClamped = clamp(step, 1, Math.floor(this.length / 2));
      this.points = samplePointsFromPath(
        this.element,
        this.length,
        stepClamped
      );

      POINT_CACHE.set(path, this.points);
      LENGTH_CACHE.set(path, this.length);
    }
  }

  get currentPosition() {
    return this.lastPoint;
  }

  update() {
    const { x, y } = this.element.getPointAtLength(this.length * this.progress);
    this.lastPoint.x = x;
    this.lastPoint.y = y;
  }
}
