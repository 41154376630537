import {
  getInteractionOptions,
  InteractionType,
} from "../../../../config/interactions";
import { TEXTURES } from "../resources";

export enum OptionIdentifier {
  MASK,
  SOFT_TOY,
  WATERING_CAN,
  FEATHERS,
  ARROW,
  HUNTERS_HORN,
  MOON,
  SNAKE,
}

export const OPTIONS = getInteractionOptions(InteractionType.HIDE_AMONG_THINGS);

export interface ObjectData {
  id: number;
  hitBox: DOMRect;
  lensSize: number;
  glowMap: {
    // Path to the glow image.
    path: string;
    // x, y, width, height of the glow image.
    rect: DOMRect;
  };
}

export const OBJECTS_TO_FIND: ObjectData[] = [
  {
    id: OptionIdentifier.MASK,
    hitBox: new DOMRect(315, 420, 90, 143),
    lensSize: 240,
    glowMap: {
      path: TEXTURES.GLOW_MASK,
      rect: new DOMRect(221, 316, 281, 353),
    },
  },
  {
    id: OptionIdentifier.SOFT_TOY,
    hitBox: new DOMRect(1262, 602, 140, 76),
    lensSize: 256,
    glowMap: {
      path: TEXTURES.GLOW_TOY,
      rect: new DOMRect(1166, 518, 321, 254),
    },
  },
  {
    id: OptionIdentifier.WATERING_CAN,
    hitBox: new DOMRect(351, 571, 114, 114),
    lensSize: 200,
    glowMap: {
      path: TEXTURES.GLOW_WATERING_CAN,
      rect: new DOMRect(243, 484, 318, 278),
    },
  },
  {
    id: OptionIdentifier.FEATHERS,
    hitBox: new DOMRect(568, 556, 116, 90),
    lensSize: 280,
    glowMap: {
      path: TEXTURES.GLOW_FEATHER,
      rect: new DOMRect(515, 507, 237, 208),
    },
  },
  {
    id: OptionIdentifier.ARROW,
    hitBox: new DOMRect(1406, 688, 104, 104),
    lensSize: 220,
    glowMap: {
      path: TEXTURES.GLOW_ARROW,
      rect: new DOMRect(1227, 491, 456, 521),
    },
  },
  {
    id: OptionIdentifier.HUNTERS_HORN,
    hitBox: new DOMRect(1272, 440, 208, 100),
    lensSize: 300,
    glowMap: {
      path: TEXTURES.GLOW_HORN,
      rect: new DOMRect(1088, 248, 580, 489),
    },
  },
  {
    id: OptionIdentifier.MOON,
    hitBox: new DOMRect(1515, 121, 88, 103),
    lensSize: 240,
    glowMap: {
      path: TEXTURES.GLOW_MOON,
      rect: new DOMRect(1417, 13, 287, 314),
    },
  },
  {
    id: OptionIdentifier.SNAKE,
    hitBox: new DOMRect(743, 39, 72, 147),
    lensSize: 250,
    glowMap: {
      path: TEXTURES.GLOW_SNAKE,
      rect: new DOMRect(644, -42, 274, 338),
    },
  },
];
