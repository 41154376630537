import React, { useEffect, useState } from "react";
import { FeedCalciferGame } from "../components/FeedCalciferGame";
import { SceneManager } from "../components/SceneManager";
import { preload } from "../components/StarDemonGame/game/resources";

interface FeedCalciferProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const FeedCalcifer = ({ setRouteIndex }: FeedCalciferProps) => {
  const [introCompleted, setIntroCompleted] = useState<boolean>(false);

  useEffect(() => {
    preload();
  }, []);

  return (
    <SceneManager
      quoteStopPercent={10}
      sceneWidth="174.8 * var(--dvh)"
      sceneStopPercent={50}
      onHasAnimationCompleted={setIntroCompleted}
      scene={
        <FeedCalciferGame
          introCompleted={introCompleted}
          setRouteIndex={setRouteIndex}
        />
      }
    />
  );
};
