import { useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../components/HidingAmongThingsGame/game/resources/textures/diffuse.webp";
import { HidingAmongThingsGame } from "../components/HidingAmongThingsGame/HidingAmongThingsGame";
import { SceneManager } from "../components/SceneManager";
import {
  getInteractionOptions,
  InteractionOption,
  InteractionType,
} from "../config/interactions";
import { ROUTES } from "../constants/routes";
import { useInteractionStore } from "../store/interactionStore";
import { useAnalyticsEvent, EventCategories } from "../hooks/useAnalyticsEvent";

interface HideAmongThingsProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  routeIndex: number;
}

export const HideAmongThings = ({
  routeIndex,
  setRouteIndex,
}: HideAmongThingsProps) => {
  const navigate = useNavigate();
  const [interactive, setInteractive] = useState(false);
  const { updateHistory } = useInteractionStore();
  const options = getInteractionOptions(InteractionType.HIDE_AMONG_THINGS);
  const event = useAnalyticsEvent();

  const handleChange = (option: InteractionOption) => {
    updateHistory(
      option.points % options.length,
      InteractionType.FEED_CALCIFER
    );

    event(EventCategories.MainExperience, "hide-among-things", option.id);

    setTimeout(() => {
      setRouteIndex(routeIndex + 1);
      navigate(ROUTES.FEED_CALCIFER);
    }, 560);
  };

  const handleHasAnimationCompleted = (ended: boolean) => setInteractive(ended);

  return (
    <SceneManager
      background={background}
      quoteStopPercent={25}
      sceneStopPercent={50}
      sceneWidth="200 * var(--dvh)"
      onHasAnimationCompleted={handleHasAnimationCompleted}
      scene={
        <HidingAmongThingsGame
          onChange={handleChange}
          interactive={interactive}
        />
      }
    />
  );
};
