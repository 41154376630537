import gsap from "gsap";
import { Assets, Container, Texture } from "pixi.js";
import { StarData } from "../../data";
import { TEXTURES } from "../../resources";
import { StarVisual } from "../graphics/star-visual";
import { PathTracer } from "../path-tracer";

interface StarParameters {
  duration: number;
  /** GSAP.ease. */
  ease: string;
}

const DEFAULT_OPTIONS: StarParameters = {
  duration: 8,
  ease: "Power3.easeOutIn",
};

export class Star {
  readonly path: PathTracer;
  readonly options: StarParameters;
  readonly visual: StarVisual;
  readonly starPosition = new DOMPoint();
  timeline = gsap.timeline({ paused: true });

  constructor(readonly starData: StarData, options: Partial<StarParameters>) {
    this.options = { ...DEFAULT_OPTIONS, ...options };
    this.path = new PathTracer(starData.path);
    this.path.offset.x = starData.offset.x;
    this.path.offset.y = starData.offset.y;

    this.visual = new StarVisual(
      this.path,
      Assets.get(TEXTURES.TRAIL_LINE) as Texture,
      starData.colors
    );

    const { duration, ease } = this.options;
    // PathTracer.progress is the key to keeping positions synced with the path.
    this.timeline.to(this.path, { duration, ease, progress: 1 });
    // Once the main path animation is done, animate the progress past its boundaries so the shaders hide the trails.
    this.timeline.to(this.path, { ease: "Power3.easeIn", progress: 2.5 });
    this.timeline.to(this.visual.ray.scale, { x: 0, y: 0 });
  }

  async spawn(
    starContainer: Container,
    displacementContainer: Container
  ): Promise<void> {
    // Add visuals to framebuffer.
    for (const t of this.visual.trails) {
      displacementContainer.addChild(t.mesh);
    }
    starContainer.addChild(this.visual.ray);
    starContainer.addChild(this.visual.particles.container);

    this.timeline.play();

    return new Promise((resolve) => {
      this.timeline.eventCallback("onComplete", resolve);
    });
  }

  readonly update = () => {
    this.path.update();

    const { offset, currentPosition } = this.path;
    const starCenterX = currentPosition.x + offset.x;
    const starCenterY = currentPosition.y + offset.y;

    this.visual.particles.updatePointer(starCenterX, starCenterY);
    this.visual.ray.position.set(starCenterX, starCenterY);
    this.visual.update();
    this.starPosition.x = starCenterX;
    this.starPosition.y = starCenterY;
  };

  dispose() {
    gsap.killTweensOf(this.visual.ray.scale);
    this.timeline.kill();
    for (const trail of this.visual.trails) trail.mesh.destroy();
  }
}
