import { Assets } from "pixi.js";

import background from "./textures/diffuse.webp";
import lens from "./textures/lens.webp";
import lensProgressRing from "./textures/lens-progress-ring.webp";
import displacementMap from "./textures/displacement-map.webp";
import objectMask from "./textures/object-mask.webp";
import glowArrow from "./textures/object-glows/arrows.webp";
import glowFeather from "./textures/object-glows/feathers.webp";
import glowMask from "./textures/object-glows/mask.webp";
import glowWateringCan from "./textures/object-glows/watering-can.webp";
import glowHorn from "./textures/object-glows/horn.webp";
import glowSoftToy from "./textures/object-glows/soft-toy.webp";
import glowMoon from "./textures/object-glows/moon.webp";
import glowSnake from "./textures/object-glows/snake.webp";

export const TEXTURES = {
  BACKGROUND: background,
  LENS: lens,
  LENS_PROGRESS: lensProgressRing,
  DISPLACEMENT_MAP: displacementMap,
  OBJECT_MASK: objectMask,
  GLOW_FEATHER: glowFeather,
  GLOW_ARROW: glowArrow,
  GLOW_MASK: glowMask,
  GLOW_WATERING_CAN: glowWateringCan,
  GLOW_HORN: glowHorn,
  GLOW_TOY: glowSoftToy,
  GLOW_MOON: glowMoon,
  GLOW_SNAKE: glowSnake,
} as const;

/** Preload all assets used by this game. */
export async function preload() {
  const isLoading = Object.keys(Assets.loader.promiseCache).find((path) =>
    path.includes(TEXTURES.GLOW_SNAKE)
  );
  if (!!isLoading || Assets.get(TEXTURES.GLOW_SNAKE)) return;

  const promises = Object.values(TEXTURES).map((path) => Assets.load(path));
  await Promise.all(promises);
}
