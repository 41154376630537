import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { setThemeBackground } from "../../utils/theme";
import { ROUTE_ORDER } from "../Navigation/Navigation";
import styles from "./ProgressBar.module.css";
import { gsap } from "gsap";

interface ProgressBarProps {
  routeIndex: number;
  isDarkMode: boolean;
  className?: string;
}

export const ProgressBar = ({
  routeIndex,
  isDarkMode,
  className,
}: ProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const progressRef = useRef<HTMLDivElement>(null);
  // Start and end screens have no progress bar so -2
  const stageCount = ROUTE_ORDER.length - 1;
  // Render each stage as a UI element
  const stageElements = Array.from(Array(stageCount).keys());

  useEffect(() => {
    setProgress(((routeIndex - 1) / (stageCount - 1)) * 100);
  }, [stageCount, routeIndex]);

  useEffect(() => {
    gsap.to(progressRef.current, {
      xPercent: progress,
      duration: 1,
      ease: "power2.inOut",
    });
  }, [progress]);

  return (
    <div
      className={cn(styles.wrapper, isDarkMode && styles.isDarkMode, className)}
    >
      <div className={cn(styles.bar, setThemeBackground(isDarkMode))}></div>
      <div className={styles.stages}>
        {stageElements.map((index) => (
          <div
            key={index}
            className={cn(styles.stage, setThemeBackground(isDarkMode))}
          ></div>
        ))}
      </div>
      <div ref={progressRef} className={styles.progress}>
        <div
          className={cn(
            styles.progressIndicator,
            setThemeBackground(isDarkMode)
          )}
        ></div>
      </div>
    </div>
  );
};
