import { useCallback, useLayoutEffect, useRef, useState } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import Splitting from "splitting";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Howl } from "howler";

import characters from "../../assets/images/homepage/characters.webp";
import cloudBottom from "../../assets/images/homepage/cloud-bottom.webp";
import cloudRight from "../../assets/images/homepage/cloud-right.webp";
import cloudLeft from "../../assets/images/homepage/cloud-left.webp";
import door from "../../assets/images/homepage/door.webp";
import doorFill from "../../assets/images/homepage/door-fill.webp";

import { Logo } from "../Logo";
import { SvgButton } from "../SvgButton";

import { ROUTES } from "../../constants/routes";

import styles from "./HomePage.module.css";
import { Preloader } from "../Preloader";

interface HomePageProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  audio: Howl;
}

export const HomePage = ({ setRouteIndex, audio }: HomePageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [hasImagesLoaded, setHasImagesLoaded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const tl = useRef<GSAPTimeline>();
  const app = useRef(null);

  useLayoutEffect(() => {
    if (hasImagesLoaded) {
      Splitting({
        target: "#title",
        by: "chars",
        key: null,
      });
      const ctx = gsap.context(() => {
        tl.current && tl.current.progress(0).kill();
        tl.current = gsap
          .timeline({
            autoRemoveChildren: true,
            smoothChildTiming: true,
          })
          .to("#cloud-bottom-front", {
            delay: 1.5,
            duration: 0.75,
            scale: 2,
            translateY: "-20vh",
          })
          .to(
            "#cloud-bottom-front",
            {
              duration: 1.25,
              scale: 1,
            },
            "<"
          )
          .to(
            "#cloud-left",
            {
              duration: 1.25,
              opacity: 1,
              translateX: "0vw",
            },
            "<"
          )
          .to(
            "#cloud-right",
            {
              duration: 1.25,
              opacity: 1,
              translateX: "-5vw",
            },
            "<"
          )
          .to(
            "#door",
            {
              duration: 0.85,
              opacity: 0.3,
            },
            ">-1.9"
          )
          .to(
            "#door",
            {
              duration: 1.5,
              opacity: 1,
              scale: 1.6,
              transformOrigin: "center 80vh",
              y: "26vh",
            },
            "<"
          )
          .to("#door", { duration: 1.2, opacity: 0 }, ">")
          .to("#door-fill", { duration: 2, opacity: 1 }, ">-1.25")
          .to(
            app.current,
            {
              duration: 1.25,
              backgroundColor: "#f6f4e4",
              stagger: 0.1,
            },
            ">-1.6"
          )
          .to("#title", { opacity: 1 }, ">-0.2")
          .to(
            "#characters",
            {
              duration: 0.8,
              ease: "back.out(1)",
              top: "6vh", // only prop to give consistent results cross-browser to position perfectly
              visibility: "visible",
            },
            ">-2"
          )
          .to(
            "#cloud-bottom-back",
            {
              duration: 0.25,
              opacity: 1,
              scale: 2.8,
              translateY: "-33vh",
            },
            "<-0.1"
          )
          .from(
            "#title .word",
            {
              duration: 1.5,
              ease: "sine.out",
              opacity: 0,
              stagger: 0.1,
              y: 20,
            },
            ">-0.01"
          )
          .to("#body", { duration: 1, opacity: 1 }, ">-0.4")
          .to(
            "#start button",
            {
              duration: 0.25,
              opacity: 1,
              scale: 1,
              smoothOrigin: true,
              autoAlpha: 1,
            },
            ">-0.8"
          )
          .to("#copyright", { duration: 1, opacity: 1 }, "-=100%");
      }, app);

      return () => ctx.revert();
    }
  }, [hasImagesLoaded]);

  const handleRouteChange = useCallback(() => {
    setButtonDisabled(true);

    if (!buttonDisabled) {
      setTimeout(() => {
        audio.play("heartbeat");
        setRouteIndex(1);
        navigate(ROUTES.THE_DOOR_DIAL);
      }, 600);
    }
  }, [buttonDisabled]);

  return (
    <div className={styles.wrapper} ref={app}>
      <>
        <Preloader setHasLoaded={setHasImagesLoaded} />
        {hasImagesLoaded && <Logo logoColour="black" scale="0.8" />}
        <section>
          <div id="door" className={cn(styles.object, styles.door)}>
            <img src={door} role="presentation" />
          </div>
          <div id="door-fill" className={cn(styles.object, styles.doorFill)}>
            <img src={doorFill} role="presentation" />
          </div>
          <div id="characters" className={cn(styles.object, styles.characters)}>
            <img src={characters} role="presentation" />
          </div>
        </section>
        <section>
          <img
            id="cloud-left"
            src={cloudLeft}
            className={cn(styles.object, styles.cloudLeft)}
            role="presentation"
          />
          <img
            id="cloud-right"
            src={cloudRight}
            className={cn(styles.object, styles.cloudRight)}
            role="presentation"
          />
          <img
            id="cloud-bottom-back"
            src={cloudBottom}
            className={cn(
              styles.object,
              styles.cloudBottom,
              styles.cloudBottomBack
            )}
            role="presentation"
          />
          <img
            id="cloud-bottom-front"
            src={cloudBottom}
            className={cn(
              styles.object,
              styles.cloudBottom,
              styles.cloudBottomFront
            )}
            role="presentation"
          />
        </section>
        <span id="copyright" className={styles.copyright}>
          <span>&copy;</span> 2004 Studio Ghibli - NDDMT
        </span>
        <section className={styles.copyWrapper}>
          <h2
            id="title"
            className={cn(styles.title, "heading-two")}
            dangerouslySetInnerHTML={{ __html: `${t("home.title")}` }}
          />
          <p
            id="body"
            className={cn(styles.body, "body-large")}
            dangerouslySetInnerHTML={{
              __html: `${t("home.copy")}`,
            }}
          />
        </section>
        <nav id="start" className={styles.navigationWrapper}>
          <SvgButton
            disabled={buttonDisabled}
            handler={handleRouteChange}
            text={t("home.cta")}
            color="#f0ecd3"
          />
        </nav>
      </>
    </div>
  );
};
