import gsap from "gsap";
import { MAX_STARS_AMOUNT } from "./constants";
import { StarData, STARS } from "./data";
import { findClosestStarToPoint } from "./modules/find-closest-star-to-point";
import { getRandomArbitrary } from "./modules/get-random-arbitrary";
import { Graphics } from "./modules/graphics";
import { prepareVideoTexture } from "./modules/graphics/utilities/prepare-video-texture";
import { Star } from "./modules/star";
import { wait } from "./modules/wait";
import { STAR_VIDEO } from "./resources";

export enum GameEvent {
  GAME_OVER = "game-over",
}

export class Game {
  readonly graphics: Graphics;
  readonly stars = new Set<Star>();
  selectedStar: Star | null = null;
  starQueue: StarData[] = [];

  constructor(readonly containerElement: HTMLElement) {
    this.graphics = new Graphics(containerElement);
    prepareVideoTexture(STAR_VIDEO);
    this.onResize();
    this.addEventListeners();
  }

  private _active = false;

  set active(active: boolean) {
    if (active === this._active) return;
    this._active = active;

    if (active) {
      this.addUserInputListeners();
      this.onResize();
      this.graphics.ticker.add(this.animate);
    } else {
      this.graphics.ticker.remove(this.animate);
    }
  }

  addUserInputListeners() {
    window.addEventListener("pointerdown", this.onPointerDown);
  }

  removeUserInputListeners() {
    window.removeEventListener("pointerdown", this.onPointerDown);
  }

  addEventListeners() {
    window.addEventListener("resize", this.onResize);
  }

  removeEventListeners() {
    window.removeEventListener("resize", this.onResize);
  }

  async spawnStar() {
    if (!this.starQueue.length) this.starQueue = [...STARS];
    const starData = this.starQueue.pop() as StarData;

    const duration = getRandomArbitrary(4, 10);
    const star = new Star(starData, { duration });
    this.stars.add(star);

    const delay = getRandomArbitrary(150, 1000) * this.starQueue.length;
    await wait(delay);
    if (!this.selectedStar) {
      await star.spawn(this.graphics.layers.main, this.graphics.layers.trails);
    }

    this.stars.delete(star);
    star.dispose();
  }

  readonly animate = () => {
    while (this.stars.size < MAX_STARS_AMOUNT && !this.selectedStar) {
      this.spawnStar();
    }

    this.graphics.displacementSprite.position.y += 0.5;
    this.stars.forEach((star) => {
      star.update();
      // Sync star position with scaling settings.
      star.starPosition.x *= this.graphics.scaleLayer.scale.x;
      star.starPosition.x += this.graphics.scaleLayer.position.x;
      star.starPosition.y *= this.graphics.scaleLayer.scale.y;
      star.starPosition.y += this.graphics.scaleLayer.position.y;
    });

    if (this.selectedStar) {
      this.graphics.explosion.updatePointer(
        this.selectedStar.starPosition.x,
        this.selectedStar.starPosition.y
      );
    }
  };

  dispose() {
    this.active = false;
    this.removeEventListeners();
    this.removeUserInputListeners();
    this.stars.forEach((star) => star.dispose());
    for (const l of Object.values(this.graphics.layers)) l.destroy();
    this.graphics.dispose();
  }

  async selectStar(selectedStar: Star) {
    this.removeUserInputListeners();
    this.graphics.explosion.updatePointer(
      selectedStar.starPosition.x,
      selectedStar.starPosition.y
    );
    this.graphics.explosion.emitter.emit = true;
    this.graphics.explosion.createEmitterBig(selectedStar.starData);

    for (const star of Array.from(this.stars)) {
      const isSelected = star === selectedStar;
      star.timeline.kill();
      star.timeline = gsap.timeline();

      for (const t of star.visual.trails) {
        star.timeline.to(t.mesh, { alpha: 0 }, "trail");
      }

      // Scale the star sprite up/down.
      const scale = isSelected ? 2.5 : 0;
      const duration = isSelected ? 2 : 1;
      star.timeline.to(
        star.visual.ray.scale,
        { x: scale, y: scale, duration },
        "trail"
      );
      if (isSelected) {
        star.timeline.to(
          star.visual.ray.scale,
          {
            x: 50,
            y: 50,
            duration: 0.75,
            onStart: () => {
              const customEvent = new CustomEvent(GameEvent.GAME_OVER, {
                detail: selectedStar.starData.option,
              });
              this.containerElement.dispatchEvent(customEvent);
            },
          },
          "-=90%"
        );
      }
    }
  }

  private readonly onResize = () => {
    this.graphics.resize();
  };

  private readonly onPointerDown = async (event: PointerEvent) => {
    const pointer = new DOMPoint(event.x, event.y);
    const { star, distance } = findClosestStarToPoint(this.stars, pointer);

    if (!star || distance > 40) return;

    this.removeUserInputListeners();
    this.selectedStar = star;
    this.selectStar(star);
  };
}
