import { Texture, VideoResource } from "pixi.js";

export async function prepareVideoTexture(texture: Texture) {
  const video = texture.baseTexture.resource as VideoResource;
  video.source.loop = true;
  video.source.muted = true;
  video.updateFPS = 25;
  const isPlaying =
    video.source.currentTime > 0 && !video.source.paused && !video.source.ended;
  if (!isPlaying) await video.source.play();
  return video;
}
