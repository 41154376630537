import { distanceToVector2 } from "./distance-between-vector2";
import { Star } from "./star";

export function findClosestStarToPoint(stars: Set<Star>, point: DOMPoint) {
  let closestStar: Star | undefined;
  let closestStarDistance = Infinity;

  stars.forEach((star) => {
    const { x, y } = star.starPosition;
    const scaledStarPosition = new DOMPoint(x, y);
    const distance = distanceToVector2(scaledStarPosition, point);
    if (distance < closestStarDistance) {
      closestStar = star;
      closestStarDistance = distance;
    }
  });

  return { star: closestStar, distance: closestStarDistance };
}
