import cn from "classnames";
import styles from "./Navigation.module.css";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useInteractionStore } from "../../store/interactionStore";
import { setThemeColour } from "../../utils/theme";
import {
  useAnalyticsEvent,
  EventActions,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";

interface NavigationProps {
  routeIndex: number;
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  isDarkMode: boolean;
  hideNavigation: boolean;
}

export const ROUTE_ORDER = [
  ROUTES.HOME,
  ROUTES.THE_DOOR_DIAL,
  ROUTES.WHICH_HAT,
  ROUTES.CHOOSE_A_SPELL,
  ROUTES.HIDE_AMONG_THINGS,
  ROUTES.FEED_CALCIFER,
  ROUTES.YOUR_STAR_DEMON,
  ROUTES.CHARACTER_REVEAL,
];

export const Navigation = ({
  routeIndex,
  setRouteIndex,
  isDarkMode,
  hideNavigation,
}: NavigationProps) => {
  const { removePreviousHistory } = useInteractionStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const event = useAnalyticsEvent();

  useEffect(() => {
    if (location.pathname !== "/landing") {
      navigate(ROUTE_ORDER[routeIndex]);
    }
  }, [routeIndex]);

  const handleNext = () => {
    setRouteIndex((index) => index + 1);

    event(
      EventCategories.Navigation,
      EventActions.Skip,
      ROUTE_ORDER[routeIndex]
    );
  };

  const handlePrevious = () => {
    removePreviousHistory();
    setRouteIndex((index) => index - 1);
    event(
      EventCategories.Navigation,
      EventActions.Back,
      ROUTE_ORDER[routeIndex]
    );
  };

  const isGame = (index: number) => index > 0 && index < ROUTE_ORDER.length;

  const showButtons = (index: number) => index > 1;

  const gameButtonStyles = cn(
    styles.gameButton,
    isDarkMode && styles.isDarkMode,
    setThemeColour(isDarkMode),
    "caps-medium"
  );

  return !hideNavigation ? (
    <nav>
      <div
        className={cn(
          styles.stage,
          styles.game,
          isGame(routeIndex) && styles.isActive,
          showButtons(routeIndex) && styles.showButtons
        )}
      >
        <Button onClick={handlePrevious} className={gameButtonStyles}>
          {t("navigation.back")}
        </Button>
        {isGame(routeIndex) && (
          <ProgressBar
            routeIndex={routeIndex}
            isDarkMode={isDarkMode}
            className={styles.progressBar}
          />
        )}
        <Button
          onClick={handleNext}
          className={cn(
            ROUTE_ORDER[routeIndex] === ROUTES.CHARACTER_REVEAL &&
              styles.hiddenButton,
            gameButtonStyles
          )}
        >
          {t("navigation.skip")}
        </Button>
      </div>
    </nav>
  ) : null;
};
