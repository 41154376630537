import { Program } from "pixi.js";

// language=glsl
const vertexShaderSource = `
  precision mediump float;

  attribute vec2 aVertexPosition;
  attribute vec2 aTextureCoord;

  varying vec2 vTextureCoord;

  uniform mat3 projectionMatrix;
  uniform mat3 translationMatrix;
  uniform mat3 uTextureMatrix;

  void main(void) {
    gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    vTextureCoord = (uTextureMatrix * vec3(aTextureCoord, 1.0)).xy;
  }
`;

// language=glsl
const fragmentShaderSource = `
  precision mediump float;

  varying vec2 vTextureCoord;

  uniform vec4 uColor;
  uniform sampler2D uSampler;
  uniform float uProgress;
  uniform float uGradientOffset;
  uniform float uTime;

  void main(void) {
    vec3 color = texture2D(uSampler, vTextureCoord).rgb;
    // Increase saturation.
    color *= mix(color, vec3(1.0), 0.9);

    // Fade in & out.
    float fadeInAlpha = smoothstep(0.0, 1.0, vTextureCoord.x - uProgress + 0.5);
    float fadeOutAlpha = smoothstep(1.0, 0.0, (vTextureCoord.x - uProgress * .75) + 1.0);
    color.rgb -= fadeInAlpha + fadeOutAlpha;

    // Apply PIXI's "tint" method: https://pixijs.download/dev/docs/PIXI.Sprite.html#tint.
    color *= uColor.rgb;

    // Remove all colour if the texture exceeds the progress.
    if (vTextureCoord.x > uProgress) color = vec3(0.0);

    // TODO(pdewit): noise masking.
    gl_FragColor = vec4(color, 1.0);
  }
`;

export const uniforms = {
  uProgress: 0,
  uGradientOffset: 1.0,
  uTime: 0.0,
};

export const program = new Program(vertexShaderSource, fragmentShaderSource);
