import { IRendererOptions, Renderer, Ticker } from "pixi.js";
import { MinimalContainer } from "./utilities/minimal-container";

const DEFAULT_RENDERER_OPTIONS: IRendererOptions = {
  antialias: false,
  hello: false,
} as const;

/** Base set-up for creating. */
export class GameGraphics {
  /** PIXI renderer. */
  readonly renderer: Renderer;
  /** Container to store graphics on */
  readonly container: MinimalContainer;
  /** Keep track of delta. */
  readonly ticker = new Ticker();

  constructor(
    containerElement: HTMLElement,
    renderOptions: IRendererOptions = {},
    interactive = false
  ) {
    this.renderer = new Renderer({
      ...DEFAULT_RENDERER_OPTIONS,
      ...renderOptions,
    });
    this.container = new MinimalContainer(interactive);
    this.ticker.start();
    this.ticker.add(this._render);

    if (this.renderer.view instanceof HTMLElement) {
      containerElement.appendChild(this.renderer.view);
    }
  }

  /** Resize handler. */
  public resize(width = window.innerWidth, height = window.innerHeight) {
    this.renderer.resize(width, height);
  }

  /** Clear memory. */
  public dispose() {
    this.ticker.stop();
    this.ticker.destroy();
    this.renderer.removeAllListeners();
    // https://github.com/pixijs/pixijs/issues/2958
    this.renderer.destroy(true);
  }

  private readonly _render = () => {
    this.renderer.render(this.container);
  };
}
