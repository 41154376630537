import cn from "classnames";
import { Quote } from "../Quote";

import styles from "./index.module.css";

import textureOverlayAsset from "../../assets/images/door-dial/texture-overlay.webp";
import doorOverlayAsset from "../../assets/images/door-dial/door-overlay.webp";

interface DoorDialProps {
  className?: string;
}

export const DoorDialQuote = ({ className }: DoorDialProps) => {
  return (
    <>
      <div className={cn(styles.quoteContainer, className)}>
        <div className={styles.doorBackground}></div>
        <div className={styles.arc}>
          <Quote
            className={styles.quote}
            token="door-dial"
            background="transparent"
          />
        </div>
        <img
          className={styles.doorOverlay}
          src={doorOverlayAsset}
          role="presentation"
          alt=""
        />
        <img
          className={styles.textureOverlay}
          src={textureOverlayAsset}
          role="presentation"
          alt=""
        />
      </div>
    </>
  );
};
