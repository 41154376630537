import { clamp } from "../../../../DoorDialGame/math";
import {
  SCROLLER_PAN_HIT_BOX_SIZE,
  SCROLLER_PAN_SPEED_MULTIPLIER,
} from "../../constants";

/**
 * TODO(pimdewit): docs, clearer naming.
 */
export class Scroller {
  /** The viewport to scroll through. */
  readonly viewport = new DOMRect();
  /** The scrollable rect. */
  readonly scrollable = new DOMRect();
  /** Percentage from the edges that define the padding hit box. */
  readonly panAreaPercentage = SCROLLER_PAN_HIT_BOX_SIZE;
  /** Multiplier to control panning speed. */
  panSpeedMultiplier = SCROLLER_PAN_SPEED_MULTIPLIER;
  /** The scale applied to the scrollable area to make it fit. */
  scale = 1;

  /**
   * @param unprojectedWidth Width before scaling.
   * @param unprojectedHeight Height before scaling.
   */
  constructor(
    readonly unprojectedWidth: number,
    readonly unprojectedHeight: number
  ) {}

  get x() {
    return this.scrollable.x;
  }

  get y() {
    return this.scrollable.y;
  }

  goToCenter() {
    const centerX = this.scrollable.width / 2;
    const halfViewport = this.viewport.width / 2;
    this.scrollable.x = -(centerX - halfViewport);
  }

  /**
   * Calculates the width and height the scrollable rect should have to fit
   *   within the viewport. Similar to `background-size: cover;`
   * @param width Viewport width.
   * @param height Viewport height.
   */
  resize(width: number, height: number) {
    this.viewport.width = width;
    this.viewport.height = height;

    const horizontalRatio = width / this.unprojectedWidth;
    const verticalRatio = height / this.unprojectedHeight;

    this.scale = Math.max(horizontalRatio, verticalRatio);

    // TODO(pimdewit): check if we ever need to cater for vertical scrolling.
    this.scrollable.height = this.viewport.height;
    this.scrollable.width = Math.round(this.unprojectedWidth * this.scale);
  }

  /** Ensure the scrollable area stays within the viewport. */
  keepInBounds() {
    const min = -(this.scrollable.width - this.viewport.width);
    this.scrollable.x = clamp(this.scrollable.x, min, 0);
  }

  /**
   * Detect whether the pointer falls in the panning boundary.
   * @param x Pointer.x.
   */
  processInput(x: number) {
    const panSize = this.viewport.width * this.panAreaPercentage;
    const leftBoundary = this.viewport.x + panSize;
    const rightBoundary = this.viewport.width - panSize;

    if (x <= leftBoundary) {
      const distance = Math.min(Math.abs(x - leftBoundary), panSize);
      this.scrollable.x += distance * this.panSpeedMultiplier;
    } else if (x >= rightBoundary) {
      const distance = Math.min(Math.abs(x - rightBoundary), panSize);
      this.scrollable.x -= distance * this.panSpeedMultiplier;
    }

    this.keepInBounds();
  }

  applyScale(value: number) {
    return value * this.scale;
  }
}
