import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Howl } from "howler";

import { RewardsCarousel } from "../RewardsCarousel";
import { SvgButton } from "../SvgButton";
import { Logo } from "../Logo";

import { ROUTES } from "../../constants/routes";

import background from "../../assets/images/rewards-screen/background.webp";
import foreground from "../../assets/images/rewards-screen/foreground.webp";

import styles from "./RewardsScreen.module.css";
import { characterNames } from "../../constants/characterNames";
import { getCharacterChoice } from "../../config/characters";
import { useInteractionStore } from "../../store/interactionStore";

import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";
interface RewardsScreenProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  audio: Howl;
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RewardsScreen = ({
  setRouteIndex,
  audio,
  setHideNavigation,
}: RewardsScreenProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const event = useAnalyticsEvent();
  const { getTotalPoints, group } = useInteractionStore();
  const totalPoints = getTotalPoints();

  const character = getCharacterChoice(group, totalPoints) || "";

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.background}
        src={background}
        alt="clouds"
        role="presentation"
      />
      <div className={styles.logo}>
        <Logo logoColour="white" scale="0.7" />
      </div>
      <section className={styles.inner}>
        <h4 className={styles.title}>{t("rewards.instructions")}</h4>
        <div className={styles.carouselContainer}>
          <RewardsCarousel introCompleted={true} />
        </div>
      </section>
      <div className={styles.navigationWrapper}>
        <div className={styles.buttons}>
          <SvgButton
            className={styles.playAgainButton}
            handler={() => {
              // audio.stop is called here to prevent a memory leak.
              // When fading audio into another sprite, the track is still playing in
              //  the background, its just muted. audio.stop() clears these.
              audio.stop();
              audio.fade(0, 1, 1200).play("heartbeat");
              event(
                EventCategories.MainExperience,
                "Play Again",
                "Reward Screen"
              );
              setTimeout(() => {
                setRouteIndex(1);
                navigate(ROUTES.THE_DOOR_DIAL);
                setHideNavigation(false);
              }, 1200);
            }}
            text={t("navigation.play-again")}
            color="#8dbdfa"
            isSmall
          />
        </div>
        <a
          href={
            t("character-reveal.collection-url", {
              name: characterNames.find((c) => c.name === character)?.value,
            }) || ""
          }
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            event(
              EventCategories.MainExperience,
              "See the collection",
              t("character-reveal.collection-url", {
                name: characterNames.find((c) => c.name === character)?.value,
              }) || ""
            );
          }}
        >
          {t("navigation.discover-the-collection")}
        </a>
      </div>
      <img
        className={styles.foreground}
        src={foreground}
        alt="clouds"
        role="presentation"
      />
    </div>
  );
};
