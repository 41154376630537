import { Assets, Texture } from "pixi.js";
import backgroundTexturePath from "./textures/background.webp";
import displacementTexturePath from "./textures/displacement-small.webp";
import trailTexturePath from "./textures/line.webp";
import starTexturePath from "./textures/star.mp4";

export const TEXTURES = {
  BACKGROUND: backgroundTexturePath,
  STAR_RAY: starTexturePath,
  TRAIL_LINE: trailTexturePath,
  DISPLACEMENT: displacementTexturePath,
} as const;

// We need to load the star texture separately because it's a video, the rest are images.
// `PIXI.Texture.from()` puts it in an internal cache.
export const STAR_VIDEO = Texture.from(TEXTURES.STAR_RAY);

export async function preload() {
  const isLoading = Object.keys(Assets.loader.promiseCache).find((path) =>
    path.includes(TEXTURES.DISPLACEMENT)
  );
  if (!!isLoading || Assets.get(TEXTURES.DISPLACEMENT)) return;

  const promises = Object.values(TEXTURES).map((path) => Assets.load(path));
  await Promise.all(promises);
}
