/**
 * Paths the stars should follow.
 * The names of each can be found in Figma under the "Pim's" page.
 * @see {@link https://www.figma.com/file/RBQensRF6XZiTbbwl5HznA/Loewe-x-Howl's-Moving-Castle?node-id=832%3A6091&t=yQ8GmAIoHSAEPJle-0}
 */
export enum SvgPath {
  SWOOSH = "M289 1C186 113-15 357 2 442",
  SWOOSH_2 = "M232 1C185 193 74 553 1 463",
  SWOOSH_3 = "M292 1C239 229 88 168 50 268 9 376 147 498 1 597",
  WAVE = "M3 1c47 59 76 112 38 168s-54 73-24 132c24 47 1 132-14 169",
}
