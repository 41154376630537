// language=glsl
export const vertexShader = `
  attribute vec2 aVertexPosition;

  uniform mat3 projectionMatrix;

  varying vec2 vTextureCoord;

  uniform vec4 inputSize;
  uniform vec4 outputFrame;

  vec4 filterVertexPosition(void) {
    vec2 position = aVertexPosition * max(outputFrame.zw, vec2(0.)) + outputFrame.xy;
    return vec4((projectionMatrix * vec3(position, 1.0)).xy, 0.0, 1.0);
  }

  vec2 filterTextureCoord(void) {
    return aVertexPosition * (outputFrame.zw * inputSize.zw);
  }

  void main(void) {
    gl_Position = filterVertexPosition();
    vTextureCoord = filterTextureCoord();
  }
`;

// language=glsl
export const fragmentShader = `
  varying vec2 vTextureCoord;
  uniform sampler2D uSampler;
  uniform sampler2D uNoiseSampler;
  uniform float uProgress;
  uniform float uGlow;
  uniform float uTime;

  const vec3 glowColor = vec3(1.0, 0.3, 0);
  const vec3 sparkColor = vec3(1.0, 0.75, 0.0);
  
  // Simplex noise.
  vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

  float snoise(vec2 v) {
    const vec4 C = vec4(0.211324865405187, // (3.0-sqrt(3.0))/6.0
    0.366025403784439, // 0.5*(sqrt(3.0)-1.0)
    -0.577350269189626, // -1.0 + 2.0 * C.x
    0.024390243902439);// 1.0 / 41.0
    vec2 i  = floor(v + dot(v, C.yy));
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod289(i);// Avoid truncation effects in permutation
    vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0))
    + i.x + vec3(0.0, i1.x, 1.0));

    vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
    m = m*m;
    m = m*m;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * (a0*a0 + h*h);
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

  void main() {
    vec4 color = texture2D(uSampler, vTextureCoord);

    // x: positive, y: negative.
    vec2 noiseAnimationDirection = vec2(uTime * 0.5, -uTime);

    // ALPHA MASKING -----------------------------------------------------------

    vec2 animatedAlpha = vTextureCoord.xy + (noiseAnimationDirection * 0.05);
    vec4 alpha = texture2D(uNoiseSampler, animatedAlpha);
    float alphaInversed = 1.0 - color.a;

    // Remove pixels from the shader if the reveal alpha is higher than the progress.
    if (alpha.r >= uProgress) discard;

    // GLOW --------------------------------------------------------------------
    // Generate a large scale simplex noise that animates over the rune.
    // The noise should be big enough so it looks like an animating gradient.
    float noise = snoise((vTextureCoord * 0.5) + noiseAnimationDirection);
    vec3 glow = vec3(noise);
    glow *= sparkColor;
    glow.rgb = max(glow.rgb - alphaInversed, 0.0);

    // SPARKS ------------------------------------------------------------------
    // Generate a small scale simplex noise. They should look like sparks.

    // Speed up the sparks.
    noiseAnimationDirection *= 4.0;
    vec2 sparkSize = vTextureCoord * 12.0;
    float sparkNoise = snoise(sparkSize - noiseAnimationDirection);
    vec3 sparks = vec3(sparkNoise);
    sparks *= sparkColor;
    // Let some sparks fly out of the shape.
    //    sparks += alpha.rgb;
    sparks.rgb = max(sparks.rgb - alphaInversed, 0.0);

    // LAYER COLOURS ----------------------------------------------------------
    // Add all the colours together.

    // Add glow base colour.
    color.rgb *= glowColor * uGlow;
    // add animated glow.
    color.rgb += glow * uGlow;
    // Add flames.
    color.rgb += sparks * uGlow;
    
    gl_FragColor = color;
  }
`;
