import {
  Assets,
  BLEND_MODES,
  IApplicationOptions,
  Sprite,
  WRAP_MODES,
} from "pixi.js";
import { DisplacementFilter } from "../../../../HidingAmongThingsGame/game/modules/graphics/lens-visual/displacement-filter";
import { GameGraphics } from "../../../core/game-graphics";
import { MinimalContainer } from "../../../core/utilities/minimal-container";
import { FIGMA_ART_BOARD_SIZE } from "../../constants";
import { TEXTURES } from "../../resources";
import { Particles } from "./particles";
import {
  backgroundCover,
  BackgroundSize,
  backgroundSize,
} from "./utilities/background-cover";

const DEFAULT_RENDER_CONFIG: IApplicationOptions = {
  backgroundColor: 0x034465,
};

export class Graphics extends GameGraphics {
  readonly background: Sprite;
  readonly displacementSprite: Sprite;
  readonly displacementFilter: DisplacementFilter;
  readonly scaleLayer = new MinimalContainer();
  readonly explosion = new Particles();
  readonly layers = {
    trails: new MinimalContainer(),
    main: new MinimalContainer(),
  };

  constructor(containerElement: HTMLElement) {
    super(containerElement, DEFAULT_RENDER_CONFIG);
    const backgroundTexture = Assets.get(TEXTURES.BACKGROUND);
    this.background = new Sprite(backgroundTexture);
    this.container.addChild(this.background);

    for (const l of Object.values(this.layers)) this.scaleLayer.addChild(l);
    this.container.addChild(this.scaleLayer);

    const displacementTexture = Assets.get(TEXTURES.DISPLACEMENT);
    this.displacementSprite = new Sprite(displacementTexture);
    this.displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;
    this.displacementFilter = new DisplacementFilter(this.displacementSprite);
    this.displacementFilter.blendMode = BLEND_MODES.ADD;
    this.displacementFilter.size = 128;
    this.displacementFilter.distortion = 4;
    this.layers.trails.addChild(this.displacementFilter.sprite);
    this.layers.trails.filters = [this.displacementFilter];

    this.container.addChild(this.explosion.container);

    this.resize();

    this.ticker.add(this.updateParticles);
  }

  resize() {
    super.resize(window.innerWidth, window.innerHeight);

    const { width, height } = this.renderer;
    const { position, scale } = backgroundCover(this.background, width, height);
    this.background.scale.set(scale);
    this.background.position.set(position.x, position.y);

    const mainSize = backgroundSize(
      FIGMA_ART_BOARD_SIZE[0],
      FIGMA_ART_BOARD_SIZE[1],
      width,
      height,
      BackgroundSize.CONTAIN
    );

    this.scaleLayer.position.set(mainSize.position.x, 0);
    this.scaleLayer.scale.set(mainSize.scale);
  }

  dispose() {
    this.ticker.remove(this.updateParticles);
    this.displacementFilter.destroy();
    super.dispose();
  }

  private readonly updateParticles = (delta: number) => {
    this.explosion.emitter.update(delta / 100);
    this.explosion.emitterBig?.update(delta / 60);
  };
}
