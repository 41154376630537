import styles from "./index.module.css";
import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";

import { clamp } from "./math";
import { updatePhysics } from "./physics";

import { useInteractionStore } from "../../store/interactionStore";
import { getCharacterChoice } from "../../config/characters";

const { abs, sin, PI, round } = Math;

interface RewardsCarouselProps {
  introCompleted: boolean;
}

export interface RewardsCarouselState {
  timestamp: number;
  timestep: number;
  maxUpdates: number;
  index: number;
  bounds: DOMRect;
  positionPrev: number;
  position: number;
  speed: number;
  snap: boolean;
  friction: number;
  itemGap: number;
  items: Array<{
    path: string;
  }>;
  select: number;
  selectThreshold: number;
  stopped: boolean;
  timeBuffer: number;
  pointer: {
    isDown: boolean;
    xPrev: number;
    yPrev: number;
    x: number;
    y: number;
  };
}

export const RewardsCarousel = ({
  introCompleted = false,
}: RewardsCarouselProps) => {
  const itemGap = PI * 1.4;
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const { getTotalPoints, group } = useInteractionStore();
  const totalPoints = getTotalPoints();

  const getItems = () => {
    let characters = [
      "howl",
      "sophie",
      "heen",
      "markl",
      "witch",
      "castle",
      "calcifer",
      "turnip-head",
    ];

    const character = getCharacterChoice(group, totalPoints);

    // if a character chosen then move it to the front
    if (character) {
      // remove character choice
      characters = characters.filter((x) => {
        return x != character;
      });

      // add character choice to start of array
      characters.unshift(character);
    }

    const itemArray = [];

    for (let i = 0; i <= characters.length - 1; i++) {
      for (let j = 1; j <= 2; j++) {
        itemArray.push({
          path: `./assets/images/wallpapers/${characters[i]}-${j}.jpg`,
        });
      }
    }

    return itemArray;
  };

  // internal real-time state (performance: no setState)
  const [state] = useState<RewardsCarouselState>(() => ({
    timestamp: 0,
    timestep: 1000 / 60,
    maxUpdates: 4,
    index: -1,
    bounds: new DOMRect(),
    positionPrev: -2 * itemGap,
    position: -17 * itemGap, // # of items + 1 to get to first item
    speed: 0,
    snap: true,
    friction: 0.2,
    select: 0,
    selectThreshold: 0.93,
    stopped: false,
    timeBuffer: 0,
    skipped: 0,
    pointer: {
      isDown: false,
      xPrev: 0,
      yPrev: 0,
      x: 0,
      y: 0,
    },
    itemGap,
    items: getItems(),
  }));

  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  const itemRefs = state.items.map(() => useRef<HTMLImageElement>(null));

  const updateBounds = () => {
    if (itemsContainerRef.current) {
      state.bounds = itemsContainerRef.current.getBoundingClientRect();
    }
  };

  const updatePointerPosition = (event: React.PointerEvent) => {
    updateBounds();

    state.pointer.x = event.clientX - state.bounds.x;
    state.pointer.y = event.clientY - state.bounds.y;
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    if (!event.isPrimary) {
      return;
    }

    const { pointer } = state;

    event.preventDefault();

    updatePointerPosition(event);

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.add(styles.pointerDown);
    }

    pointer.isDown = true;
    pointer.xPrev = pointer.x;
    pointer.yPrev = pointer.y;
  };

  const handlePointerUp = (event: React.PointerEvent) => {
    if (!event.isPrimary) {
      return;
    }

    updatePointerPosition(event);

    if (introCompleted) {
      setHasInteracted(true);
    }

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.remove(styles.pointerDown);
    }

    state.pointer.isDown = false;
  };

  const handlePointerMove = (event: React.PointerEvent) => {
    if (!event.isPrimary) {
      return;
    }

    updatePointerPosition(event);
  };

  useEffect(() => {
    setTimeout(() => {
      // move carousel during intro
      state.snap = false;
      state.friction = 0.3;
      state.positionPrev = state.position + 0.8;

      setTimeout(() => {
        // stop moving after intro
        state.snap = true;
        state.friction = 1;
      }, 1000);
    }, 2800);
  }, []);

  useEffect(() => {
    let frameRequest: number;
    let lastTime = 0;

    // main frame loop
    const onFrame = (time: number) => {
      // timing
      const delta = clamp(time - lastTime || 1000 / 60, 1, 1000 / 30);
      lastTime = time;
      state.timeBuffer += delta;

      // physics
      let updates = 0;

      while (state.timeBuffer >= state.timestep) {
        updatePhysics(state);

        state.timestamp += state.timestep;
        state.timeBuffer -= state.timestep;

        updates += 1;

        if (updates > state.maxUpdates) {
          break;
        }
      }

      updateBounds();

      const itemCount = itemRefs.length;
      const totalDistance = itemCount * state.itemGap;

      // limit position at start and end of items
      state.position = clamp(
        state.position,
        -totalDistance * 0.5 - state.itemGap * 0.35,
        totalDistance * 0.5 - state.itemGap * 0.55
      );

      const wrapPosition = state.position; // % totalDistance;

      // render
      for (let i = 0; i < itemCount; i += 1) {
        const itemRef = itemRefs[i];

        if (!itemRef.current) {
          continue;
        }

        // find screen relative position
        const itemOffset = i * state.itemGap;
        const centerOffset = totalDistance * 0.5 - state.itemGap * 0.5;

        const position =
          itemOffset + wrapPosition + state.itemGap * 0.5 - centerOffset;

        const offset = position;

        // scale grows towards center
        const scaleCenter = 1 / (1 + 0.12 * abs(position));
        const scale = scaleCenter;
        const rotate = -position * 0.03 - 0.045;

        // position
        const width = state.bounds.width;
        const translateX = clamp(position, -7, 7) * -0.06 * width;
        const translateY =
          Math.max(
            0,
            -sin(clamp(position, -8, 8) / (itemCount * 0.6 * Math.PI))
          ) * 280;

        // apply transforms and effects
        const f = (x: number) => x.toFixed(3);
        const style = itemRef.current.style;

        // perf: only update visible items
        if (offset > -2.5 * state.itemGap && offset < 2.5 * state.itemGap) {
          // apply current item class at center else remove it
          itemRef.current.classList.toggle(
            styles.itemCurrent,
            abs(offset) < state.itemGap * 0.45
          );

          // increase z-index towards center item
          style.zIndex = `${100 - round(abs(offset))}`;

          // apply transforms
          style.transform = `translate3d(${f(translateX)}px, ${f(
            translateY
          )}px, 0) scale(${f(scale)}) rotate(${f(rotate)}rad)`;

          // draw visible item
          style.opacity = "1";
        } else {
          // perf: skip drawing hidden item
          style.opacity = "0";
        }
      }

      frameRequest = requestAnimationFrame(onFrame);
    };

    // start the frame loop
    frameRequest = requestAnimationFrame(onFrame);

    return () => {
      cancelAnimationFrame(frameRequest);
    };
  });

  return (
    <div
      ref={itemsContainerRef}
      onPointerMove={handlePointerMove}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      className={cn(
        styles.itemsContainer,
        introCompleted ? styles.introCompleted : "",
        hasInteracted ? styles.hasInteracted : ""
      )}
    >
      {state.items.map((item, index) => (
        <img
          key={index}
          ref={itemRefs[itemRefs.length - 1 - index]}
          className={styles.item}
          src={item.path}
          style={{ zIndex: 100 - index, opacity: 0 }}
          {...{ fetchpriority: index < 4 ? "high" : undefined }}
        />
      ))}
    </div>
  );
};
