import { Assets } from "pixi.js";
import background from "./textures/background.webp";
import noisePath from "./textures/noise.webp";
import rune1 from "./textures/rune1.webp";
import rune2 from "./textures/rune2.webp";
import rune3 from "./textures/rune3.webp";
import rune4 from "./textures/rune4.webp";

export const TEXTURES = {
  BACKGROUND: background,
  RUNE_1: rune1,
  RUNE_2: rune2,
  RUNE_3: rune3,
  RUNE_4: rune4,
  NOISE: noisePath,
} as const;

export async function preload() {
  const isLoading = Object.keys(Assets.loader.promiseCache).find((path) =>
    path.includes(TEXTURES.NOISE)
  );
  if (!!isLoading || Assets.get(TEXTURES.NOISE)) return;

  const promises = Object.values(TEXTURES).map((path) => Assets.load(path));
  await Promise.all(promises);
}
