import { Howl } from "howler";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { RevealCard } from "../components/RevealCard";
import { getCharacterChoice } from "../config/characters";
import { ROUTES } from "../constants/routes";
import { useInteractionStore } from "../store/interactionStore";

interface CharacterRevealProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  audio: Howl;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

const PATHS_WITH_CUSTOM_AUDIO = [ROUTES.REWARDS, ROUTES.CHARACTER_REVEAL];

export const CharacterReveal = ({
  setRouteIndex,
  audio,
  setIsDarkMode,
  setHideNavigation,
}: CharacterRevealProps) => {
  const { getTotalPoints, group } = useInteractionStore();
  const [character, setCharacter] = useState<string>();
  const totalPoints = getTotalPoints();
  const location = useLocation();

  useEffect(() => {
    const character = getCharacterChoice(group, totalPoints);
    setCharacter(character);
  }, [group, totalPoints]);

  useEffect(() => {
    // Change the music back to heartbeat when going back to a previous route.
    if (!PATHS_WITH_CUSTOM_AUDIO.includes(location.pathname as ROUTES)) {
      // audio.stop is called here to prevent a memory leak.
      // When fading audio into another sprite, the track is still playing in
      //  the background, its just muted. audio.stop() clears these.
      audio.stop();
      audio.fade(0, 1, 1200);
      audio.play("heartbeat");
    }
  }, [audio, location]);

  return (
    <div>
      <RevealCard
        character={character || ""}
        setRouteIndex={setRouteIndex}
        audio={audio}
        setIsDarkMode={setIsDarkMode}
        setHideNavigation={setHideNavigation}
      />
    </div>
  );
};
