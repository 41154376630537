import { Point, Sprite } from "pixi.js";

export enum BackgroundSize {
  COVER,
  CONTAIN,
}

export function backgroundCover(
  sprite: Sprite,
  targetWidth: number,
  targetHeight: number
) {
  return backgroundSize(
    sprite.texture.width,
    sprite.texture.height,
    targetWidth,
    targetHeight,
    BackgroundSize.COVER
  );
}

export function backgroundSize(
  width: number,
  height: number,
  targetWidth: number,
  targetHeight: number,
  sizing: BackgroundSize
) {
  const targetRatio = targetWidth / targetHeight;
  const currentRatio = width / height;
  let scale: number;
  const position = new Point();

  const conditional =
    sizing === BackgroundSize.COVER
      ? targetRatio > currentRatio
      : targetRatio < currentRatio;
  if (conditional) {
    scale = targetWidth / width;
    position.y = -(height * scale - targetHeight) / 2;
  } else {
    scale = targetHeight / height;
    position.x = -(width * scale - targetWidth) / 2;
  }

  return { scale, position };
}
