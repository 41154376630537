import { Howl } from "howler";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DeviceNotice } from "./components/DeviceNotice/DeviceNotice";

import { Layout } from "./components/Layout/Layout";
import { Menu } from "./components/Menu/Menu";
import { Navigation } from "./components/Navigation/Navigation";
import { OrientationNotice } from "./components/OrientationNotice/OrientationNotice";
import { TransitionLayout } from "./components/TransitionLayout";

import { ROUTES } from "./constants/routes";
import { useAnalyticsPageView } from "./hooks/useAnalyticsPageView";
import {
  CharacterReveal,
  ChooseSpell,
  FeedCalcifer,
  HideAmongThings,
  Home,
  Landing,
  Rewards,
  TheDoorDial,
  WhichHat,
  YourStarDemon,
} from "./pages";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "");

function App() {
  const [routeIndex, setRouteIndex] = useState<number>(0);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const pageview = useAnalyticsPageView();

  const [howl] = useState<Howl>(
    () =>
      new Howl({
        src: ["./assets/music/music.webm", "./assets/music/music.mp3"],
        sprite: {
          heartbeat: [0, 15000],
          "walk-in-the-skies": [17000, 24384.01360544218],
          "white-noise": [43000, 12000],
        },
        loop: true,
        // enables 'html5' option for iOS
        // https://github.com/goldfire/howler.js/issues/1407
        html5: true,
        // autoplay: true,
      })
  );

  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [hideNavigation, setHideNavigation] = useState<boolean>(false);
  const url = new URL(window.location.href);
  const showDeviceNotice = !isMobile;

  /**
   * Fixes issue with location.pathname changing
   * when using MemoryRouter and setting multiple
   * initial entries
   */
  useEffect(() => {
    if (url.pathname === "/landing") {
      navigate("/landing");
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case ROUTES.HOME:
      case ROUTES.THE_DOOR_DIAL:
      case ROUTES.CHARACTER_REVEAL:
      case "/landing":
        return setIsDarkMode(false);
      default:
        return setIsDarkMode(true);
    }
  }, [location]);

  const changeDocumentLanguage = (lang: string) => {
    document.documentElement.lang = lang;
  };

  useEffect(() => {
    let lang = i18n.language;

    if (i18n.language.includes("en")) {
      lang = "en";
    }

    if (
      i18n.language.toLowerCase() === "zh-cn" ||
      url.hostname.includes("com.cn")
    ) {
      lang = "zh";
    }

    changeDocumentLanguage(lang);

    // don't fire if pathname is root.
    if (location.pathname !== "/") {
      // use i18n.resolvedLanguage to deal with any fallback issues
      // i.e fr would be set as language but resolved would be en
      // as that is the global fallback and misreporting in analytics
      pageview(`${i18n.resolvedLanguage}${location.pathname}`);
    }
  }, [location.pathname]);

  useEffect(() => {
    // for any detected languages that are "en" based e.g en-US etc,
    // set the language to just "en"
    if (i18n.language.includes("en")) {
      i18n.changeLanguage("en");
    }
    // for simplified chinese which could be zh or zh-cn
    if (i18n.language.toLowerCase() === "zh-cn") {
      i18n.changeLanguage("zh");
    }

    if (i18n.language === "zh-TW") {
      i18n.changeLanguage("zh-tw");
    }

    if (url.hostname.includes("com.cn")) {
      i18n.changeLanguage("zh");
    }

    changeDocumentLanguage(i18n.language);
  }, []);

  useEffect(() => {
    changeDocumentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const setViewHeight = () => {
      document.documentElement.style.setProperty(
        "--dvh",
        `${window.innerHeight * 0.01}px`
      );
    };

    setViewHeight();

    window.addEventListener("resize", setViewHeight);

    return () => {
      window.removeEventListener("resize", setViewHeight);
    };
  }, [location.pathname]);

  const setMuted = useCallback(
    (flag: boolean) => {
      const muted = isAudioMuted ? true : flag;
      if (muted) {
        howl.pause();
      } else if (!howl.playing()) {
        howl.fade(0, 1, 1000).play();
      }
    },
    [isAudioMuted]
  );

  useEffect(() => {
    const mute = () => setMuted(true);
    const unmute = () => setMuted(false);
    const onVisibilityChange = () => setMuted(document.hidden);
    window.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener("blur", mute);
    window.addEventListener("focus", unmute);

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("blur", mute);
      window.removeEventListener("focus", unmute);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("app-ready");

    if (showDeviceNotice) {
      document.body.classList.add("app-device-notice");
    }
  }, []);

  return (
    <Layout>
      {showDeviceNotice ? (
        <DeviceNotice />
      ) : (
        <>
          <Menu
            routeIndex={routeIndex}
            setRouteIndex={setRouteIndex}
            isDarkMode={isDarkMode}
            isAudioMuted={isAudioMuted}
            setIsAudioMuted={setIsAudioMuted}
            setHideNavigation={setHideNavigation}
          />

          <Routes>
            <Route
              path="/landing"
              element={<Landing setRouteIndex={setRouteIndex} />}
            />

            <Route element={<TransitionLayout />}>
              <Route
                path={ROUTES.HOME}
                element={<Home setRouteIndex={setRouteIndex} audio={howl} />}
              />
              <Route
                path={ROUTES.THE_DOOR_DIAL}
                element={
                  <TheDoorDial
                    setRouteIndex={setRouteIndex}
                    setIsDarkMode={setIsDarkMode}
                  />
                }
              />
              <Route
                path={ROUTES.WHICH_HAT}
                element={<WhichHat setRouteIndex={setRouteIndex} />}
              />
              <Route
                path={ROUTES.CHOOSE_A_SPELL}
                element={
                  <ChooseSpell
                    routeIndex={routeIndex}
                    setRouteIndex={setRouteIndex}
                  />
                }
              />
              <Route
                path={ROUTES.HIDE_AMONG_THINGS}
                element={
                  <HideAmongThings
                    routeIndex={routeIndex}
                    setRouteIndex={setRouteIndex}
                  />
                }
              />
              <Route
                path={ROUTES.FEED_CALCIFER}
                element={<FeedCalcifer setRouteIndex={setRouteIndex} />}
              />
              <Route
                path={ROUTES.YOUR_STAR_DEMON}
                element={<YourStarDemon setRouteIndex={setRouteIndex} />}
              />
              <Route
                path={ROUTES.CHARACTER_REVEAL}
                element={
                  <CharacterReveal
                    setRouteIndex={setRouteIndex}
                    audio={howl}
                    setIsDarkMode={setIsDarkMode}
                    setHideNavigation={setHideNavigation}
                  />
                }
              />
              <Route
                path={ROUTES.REWARDS}
                element={
                  <Rewards
                    setRouteIndex={setRouteIndex}
                    audio={howl}
                    setHideNavigation={setHideNavigation}
                  />
                }
              />
            </Route>
          </Routes>
          <Navigation
            routeIndex={routeIndex}
            setRouteIndex={setRouteIndex}
            isDarkMode={isDarkMode}
            hideNavigation={hideNavigation}
          />
          <OrientationNotice />
        </>
      )}
    </Layout>
  );
}

export default App;
