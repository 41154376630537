import { ObjectGlowInterpolationStrength } from "../../constants";
import { ObjectData } from "../../data";
import { interpolate } from "../math/interpolate";
import { Scroller } from "../scroller";

export class ObjectToFind {
  /** The active object hit-box. */
  readonly hitBox = new DOMRect();
  revealed = false;
  /** Float ranging from 0 to 1. */
  revealedProgress = 0;
  /** Size the lens should resize to when hovering over this object. */
  lensSize = 0;

  constructor(readonly rawData: ObjectData) {}

  /** The hit-box BEFORE all viewport scaling. */
  get originalHitBox(): DOMRect {
    return this.rawData.hitBox;
  }

  update() {
    const target = this.revealed ? 1 : 0;
    const alpha = this.revealed
      ? ObjectGlowInterpolationStrength.ACTIVE
      : ObjectGlowInterpolationStrength.IDLE;
    this.revealedProgress = interpolate(target, this.revealedProgress, alpha);
  }

  syncWithScroller(scroller: Scroller) {
    this.hitBox.x = scroller.applyScale(this.originalHitBox.x) + scroller.x;
    this.hitBox.y = scroller.applyScale(this.originalHitBox.y) + scroller.y;
    this.hitBox.width = scroller.applyScale(this.originalHitBox.width);
    this.hitBox.height = scroller.applyScale(this.originalHitBox.height);
    this.lensSize = scroller.applyScale(this.rawData.lensSize);
  }
}
