import cn from "classnames";
import { useEffect, useRef } from "react";
import { InteractionOption } from "../../config/interactions";
import {
  GameEvent,
  LENS_FILTER_INTENSITY,
  LENS_FILTER_SIZE,
} from "./game/constants";
import { EntryPoint } from "./game/entry-point";
import styles from "./HidingAmongThingsGame.module.css";

interface HidingAmongThingsGameProps {
  /** Amount of pixels to distort in the magnifying glass. */
  lensDistortion: number;
  /** Size of the lens in pixels. */
  lensSize: number;
  /** Whether user has control. */
  interactive: boolean;
  onChange: (option: InteractionOption) => void;
}

export const HidingAmongThingsGame = ({
  lensSize,
  lensDistortion,
  interactive,
  onChange,
}: HidingAmongThingsGameProps) => {
  // Canvas element to render the game to.
  const container = useRef<HTMLDivElement | null>(null);
  // Game instance.
  const entryPoint = useRef<EntryPoint | null>(null);

  function onObjectSelected(event: CustomEventInit<InteractionOption>) {
    if (event.detail) onChange(event.detail);
  }

  // Game initialisation.
  useEffect(() => {
    if (!entryPoint.current && container.current) {
      entryPoint.current = new EntryPoint(container.current);
      container.current.addEventListener(GameEvent.FINISH, onObjectSelected);
    }

    return () => {
      container.current?.removeEventListener(
        GameEvent.FINISH,
        onObjectSelected
      );
      entryPoint.current?.dispose();
      entryPoint.current = null;
    };
  }, [container]);

  useEffect(() => {
    if (entryPoint.current) entryPoint.current.lensDistortion = lensDistortion;
  }, [lensDistortion]);

  useEffect(() => {
    if (entryPoint.current) entryPoint.current.lensSize = lensSize;
  }, [lensSize]);

  useEffect(() => {
    if (entryPoint.current) entryPoint.current.interactive = interactive;
  }, [interactive]);

  return (
    <div className={cn(styles.wrapper)}>
      <div ref={container} />
    </div>
  );
};

HidingAmongThingsGame.defaultProps = {
  lensSize: LENS_FILTER_SIZE,
  lensDistortion: LENS_FILTER_INTENSITY,
  interactive: false,
};
