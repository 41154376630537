import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import foreground from "./assets/foreground.png";
import rotate from "./assets/rotate@2x.png";
import leaves from "./assets/leaves.png";

import styles from "./OrientationNotice.module.css";

export const OrientationNotice = () => {
  const [isDevicePortrait, setIsDevicePortrait] = useState<boolean>(true);
  const { t } = useTranslation();

  const onOrientationChange = (event: MediaQueryListEvent) => {
    setIsDevicePortrait(event.matches);
  };

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", onOrientationChange);
    setIsDevicePortrait(portrait.matches);

    return () => {
      portrait.removeEventListener("change", onOrientationChange);
    };
  }, []);

  return (
    <>
      {!isDevicePortrait && (
        <div
          role={"alert"}
          className={styles.wrapper}
          style={{ backgroundImage: `url(${foreground})` }}
        >
          <img className={styles.rotate} role="presentation" src={rotate} />
          <div
            className={styles.leaves}
            style={{ backgroundImage: `url(${leaves})` }}
          ></div>
          <p className={styles.copy}>{t("misc.rotate-device")}</p>
        </div>
      )}
    </>
  );
};
