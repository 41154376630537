import styles from "./index.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";

import {
  getInteractionOptions,
  InteractionType,
} from "../../config/interactions";

import { useInteractionStore } from "../../store/interactionStore";
import { ROUTES } from "../../constants/routes";

import { clamp, snap } from "./math";
import { updatePhysics } from "./physics";

import howlsGardenAsset from "../../assets/images/door-dial/howls-garden.webp";
import kingsburyAsset from "../../assets/images/door-dial/kingsbury.webp";
import marketChippingAsset from "../../assets/images/door-dial/market-chipping.webp";
import theWasteAsset from "../../assets/images/door-dial/the-waste.webp";

import cursorAsset from "../../assets/images/door-dial/cursor.webp";
import windowGlassAsset from "../../assets/images/door-dial/window-glass.svg";
import windowCloudsAsset from "../../assets/images/door-dial/window-clouds.webp";
import textureOverlayAsset from "../../assets/images/door-dial/texture-overlay.webp";

import howlsGardenOverlayAsset from "../../assets/images/door-dial/howls-garden-overlay.webp";
import kingsburyOverlayAsset from "../../assets/images/door-dial/kingsbury-overlay.webp";
import marketChippingOverlayAsset from "../../assets/images/door-dial/market-chipping-overlay.webp";
import theWasteOverlayAsset from "../../assets/images/door-dial/the-waste-overlay.webp";

import doorStrokeAsset from "../../assets/images/door-dial/door-stroke.svg";
import doorOverlayAsset from "../../assets/images/door-dial/door-overlay.webp";

import dialDiffuseAsset from "../../assets/images/door-dial/dial-diffuse.webp";
import dialShadowAsset from "../../assets/images/door-dial/dial-shadow.webp";
import dialSpecularAsset from "../../assets/images/door-dial/dial-specular.webp";
import dialArrowAsset from "../../assets/images/door-dial/dial-arrow.webp";
import dialArrowBehindAsset from "../../assets/images/door-dial/dial-arrow-behind.webp";
import dialArrowShadowAsset from "../../assets/images/door-dial/dial-arrow-shadow.webp";
import { SvgButton } from "../SvgButton";

const { atan2, random, round, sin, PI } = Math;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

interface DoorDialProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DoorDialState {
  timestep: number;
  maxUpdates: number;
  index: number;
  bounds: DOMRect;
  anglePrev: number;
  angle: number;
  angularSpeed: number;
  stopped: boolean;
  timeBuffer: number;
  skipped: number;
  viewHeight: number;
  pointer: {
    isDown: boolean;
    xPrev: number;
    yPrev: number;
    x: number;
    y: number;
    startAngle: number;
  };
}

export const DoorDialGame = ({
  setRouteIndex,
  setIsDarkMode,
}: DoorDialProps) => {
  const { t } = useTranslation();
  const { updateGroup } = useInteractionStore();
  const navigate = useNavigate();
  const event = useAnalyticsEvent();

  const doorDialDropShadowRef = useRef<HTMLImageElement>(null);
  const doorDialContainerRef = useRef<HTMLDivElement>(null);
  const doorDialImageRef = useRef<HTMLImageElement>(null);
  const dialShadowImageRef = useRef<HTMLImageElement>(null);
  const dialSpecularImageRef = useRef<HTMLImageElement>(null);
  const doorDialTextRef = useRef<HTMLSpanElement>(null);

  const locations = [
    `${t("door-dial.location-kingsbury")}`,
    `${t("door-dial.location-the-waste")}`,
    `${t("door-dial.location-market-chipping")}`,
    `${t("door-dial.location-howls-garden")}`,
  ];

  const locationAssets = [
    kingsburyAsset,
    theWasteAsset,
    marketChippingAsset,
    howlsGardenAsset,
  ];

  const sceneContainerRefs = locationAssets.map(() =>
    useRef<HTMLDivElement>(null)
  );

  // internal real-time state (performance: no setState)
  const [state] = useState<DoorDialState>(() => {
    const randomInitialAngle = random() * 2 * PI;
    return {
      timestep: 1000 / 60,
      maxUpdates: 4,
      index: 0,
      bounds: new DOMRect(),
      anglePrev: randomInitialAngle,
      angle: randomInitialAngle + 1,
      angularSpeed: 0,
      stopped: false,
      timeBuffer: 0,
      skipped: 0,
      viewHeight: window.innerHeight,
      pointer: {
        isDown: false,
        xPrev: 0,
        yPrev: 0,
        x: 0,
        y: 0,
        startAngle: 0,
      },
    };
  });

  const [choiceIndex, setChoiceIndex] = useState<number>(-1);
  const [showChoice, setShowChoice] = useState<boolean>(false);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  const getChoiceId = (index: number) =>
    getInteractionOptions(InteractionType.DOOR_DIAL)[index]?.id || "none";

  const choiceId = getChoiceId(choiceIndex);

  const updateBounds = () => {
    if (doorDialImageRef.current) {
      state.bounds = doorDialImageRef.current.getBoundingClientRect();
    }
  };

  const updatePointerPosition = (event: React.PointerEvent) => {
    updateBounds();

    state.pointer.x = event.clientX - state.bounds.x;
    state.pointer.y = event.clientY - state.bounds.y;
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    const { pointer, bounds } = state;

    event.preventDefault();
    updatePointerPosition(event);

    if (!hasInteracted) {
      setTimeout(() => {
        if (state.angularSpeed > 0.01) {
          setHasInteracted(true);
        }
      }, 500);
    }

    pointer.isDown = true;

    pointer.startAngle = atan2(
      pointer.y - bounds.height * 0.5,
      pointer.x - bounds.width * 0.5
    );
  };

  const handlePointerUp = (event: React.PointerEvent) => {
    updatePointerPosition(event);
    state.pointer.isDown = false;

    if (!hasInteracted) {
      setTimeout(() => {
        if (state.angularSpeed > 0.01) {
          setHasInteracted(true);
        }
      }, 500);
    }
  };

  const handlePointerMove = (event: React.PointerEvent) => {
    updatePointerPosition(event);
  };

  const handleChoice = (
    index: number,
    type: InteractionType,
    nextRouteIndex: number,
    nextRoute: ROUTES,
    navigateDelay: number
  ) => {
    const options = getInteractionOptions(type);
    const option = options[index];

    if (option) {
      event(EventCategories.MainExperience, "the-door-dial", option.id);

      updateGroup(option.points);

      setTimeout(() => {
        setRouteIndex(nextRouteIndex);
        navigate(nextRoute);
      }, navigateDelay);
    } else {
      throw `Missing option for index ${index}`;
    }
  };

  const handleGoClick = () => {
    setTimeout(() => setIsDarkMode(true), 680);

    setShowChoice(true);

    handleChoice(
      state.index,
      InteractionType.DOOR_DIAL,
      2,
      ROUTES.WHICH_HAT,
      4100
    );
  };

  useEffect(() => {
    // wait for intro animations
    setTimeout(() => {
      // only allow interaction after
      if (doorDialContainerRef.current) {
        doorDialContainerRef.current.style.pointerEvents = "all";
      }
    }, 2700);
  }, []);

  useEffect(() => {
    const setViewHeight = () => {
      state.viewHeight = window.innerHeight;
    };

    window.addEventListener("resize", setViewHeight);

    return () => {
      window.removeEventListener("resize", setViewHeight);
    };
  }, []);

  useEffect(() => {
    let frameRequest: number;
    let lastTime = 0;

    const doorDialImage = doorDialImageRef.current;
    const dialShadowImage = dialShadowImageRef.current;
    const dialSpecularImage = dialSpecularImageRef.current;
    const doorDialDropShadowImage = doorDialDropShadowRef.current;

    if (
      !doorDialImage ||
      !dialShadowImage ||
      !dialSpecularImage ||
      !doorDialDropShadowImage
    ) {
      return;
    }

    // main frame loop
    const onFrame = (time: number) => {
      // stop frame loop when choice is made
      if (showChoice) {
        return;
      }

      // timing
      const delta = clamp(time - lastTime || 1000 / 60, 1, 1000 / 30);
      lastTime = time;
      state.timeBuffer += delta;

      updateBounds();

      // physics
      let updates = 0;

      while (state.timeBuffer >= state.timestep) {
        updatePhysics(state);

        state.timeBuffer -= state.timestep;
        updates += 1;

        if (updates > state.maxUpdates) {
          break;
        }
      }

      // render
      doorDialImage.style.transform = `translate3d(0, 0, 0) rotate(${
        state.angle + 0.5 * PI
      }rad)`;

      const wobble = 0.005;

      dialShadowImage.style.transform = `translate3d(0.1%, 0.7%, 0) rotate(${
        sin(state.angle * 2) * 0.16 - 1.47
      }rad) scale(${1 + sin(state.angle * 2) * wobble}, ${
        1 + sin(state.angle * 2) * wobble
      })`;

      dialSpecularImage.style.transform = `translate3d(0, 0, 0) rotate(${
        sin(state.angle * 2) * 0.24 - 1.51
      }rad) scale(${1 + sin(state.angle * 2) * wobble}, ${
        1 + sin(state.angle * 2) * wobble
      })`;

      // wrap position infinitely
      const itemGap = 0.5 * PI;
      const itemCount = sceneContainerRefs.length;
      const totalDistance = itemCount * itemGap;
      const wrapPosition = totalDistance + (state.angle % totalDistance);

      for (let i = 0; i < itemCount; i += 1) {
        const sceneContainerRef = sceneContainerRefs[i];

        if (sceneContainerRef.current) {
          // note: renders in reverse order
          const itemOffset = (itemCount - 2 - i) * itemGap;
          const centerOffset = totalDistance * 0.5;

          const position =
            ((itemOffset + wrapPosition + 0.01) % totalDistance) - centerOffset;

          // apply transforms and effects
          const f = (x: number) => x.toFixed(3);
          const style = sceneContainerRef.current.style;

          // perf: local dvh as calc per frame can lower fps
          const dvh = state.viewHeight * 0.01;

          // position follows the wheel
          const translateX = position * 87 * dvh - 30 * dvh;
          style.transform = `translate3d(${f(translateX)}px, 0, 0)`;
        }
      }

      // get selected index from angle
      const index = round(snap(wrapPosition - 0.25, 0.5 * PI) / (0.5 * PI)) % 4;

      // callback when index changed by user interaction
      if (state.index !== index) {
        state.index = index;

        // update text only on change
        if (hasInteracted && doorDialTextRef.current) {
          const location = locations[state.index];
          doorDialTextRef.current.textContent = location;
        }
      }

      // update choice if interacted
      if (hasInteracted) {
        if (state.index !== -1 && state.angularSpeed < 0.06) {
          // perf: check avoids thrashing set state
          if (choiceIndex !== state.index) {
            setChoiceIndex(state.index);
          }

          // update text only on change
          if (doorDialTextRef.current) {
            const location = locations[state.index];
            doorDialTextRef.current.textContent = location;
          }
        } else {
          // perf: check avoids thrashing set state
          if (choiceIndex !== -1) {
            setChoiceIndex(-1);
          }
        }
      }

      frameRequest = requestAnimationFrame(onFrame);
    };

    // start the frame loop
    frameRequest = requestAnimationFrame(onFrame);

    return () => {
      cancelAnimationFrame(frameRequest);
    };
  });

  return (
    <div
      ref={doorDialContainerRef}
      className={cn(
        styles.doorDialContainer,
        styles[`choice-${choiceId}`],
        showChoice ? styles.showChoice : "",
        isSafari ? styles.isSafari : "",
        hasInteracted ? styles.hasInteracted : ""
      )}
    >
      <div className={styles.arc}>
        <div className={styles.doorBackground}></div>
        <div className={styles.windowContainer}>
          <img
            className={styles.windowGlass}
            src={windowGlassAsset}
            role="presentation"
            alt=""
          />
          <div className={styles.windowScenes}>
            <img
              className={styles.windowClouds}
              src={windowCloudsAsset}
              role="presentation"
              alt=""
            />
            {sceneContainerRefs.map((ref, index) => (
              <div ref={ref} className={styles.windowScene} key={index}>
                <img
                  className={cn(
                    styles.windowSceneImage,
                    styles[`windowSceneImage-${getChoiceId(index)}`]
                  )}
                  src={locationAssets[index]}
                  role="presentation"
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles.doorDial}
          onPointerMove={handlePointerMove}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerOut={handlePointerUp}
        >
          <img
            ref={doorDialDropShadowRef}
            className={styles.doorDialDropShadow}
            src={dialDiffuseAsset}
            role="presentation"
            alt=""
          />
          <img
            ref={dialSpecularImageRef}
            className={styles.doorDialImageSpecular}
            src={dialSpecularAsset}
            role="presentation"
            alt=""
          />
          <img
            ref={dialShadowImageRef}
            className={styles.doorDialImageShadow}
            src={dialShadowAsset}
            role="presentation"
            alt=""
          />
          <img
            className={styles.doorDialArrowShadow}
            src={dialArrowShadowAsset}
            role="presentation"
            alt=""
          />
          <img
            className={styles.doorDialImageArrowBehind}
            src={dialArrowBehindAsset}
            role="presentation"
            alt=""
          />
          <img
            className={styles.doorDialImageArrow}
            src={dialArrowAsset}
            role="presentation"
            alt=""
          />
          <img
            ref={doorDialImageRef}
            className={styles.doorDialImage}
            src={dialDiffuseAsset}
            role="presentation"
            alt=""
          />
        </div>
        <div className={styles.cursorContainer}>
          <img
            className={styles.cursor}
            src={cursorAsset}
            role="presentation"
            alt=""
          />
        </div>
        <span className={styles.instructionText}>{t("instructions.spin")}</span>
        <span
          ref={doorDialTextRef}
          className={styles.doorDialText}
          dangerouslySetInnerHTML={{ __html: t("door-dial.instructions") }}
        />
      </div>
      <nav className={styles.navigationWrapper}>
        <SvgButton
          className={styles.goButton}
          svgClassName={styles.goButtonSvg}
          color="currentColor"
          handler={handleGoClick}
          text={t("door-dial.cta")}
        />
      </nav>
      <img
        className={styles.doorStroke}
        src={doorStrokeAsset}
        role="presentation"
        alt=""
      />
      <img
        className={styles.doorOverlay}
        src={doorOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={cn(styles.howlsGardenOverlay, styles.sceneOverlay)}
        src={howlsGardenOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={cn(styles.kingsburyOverlay, styles.sceneOverlay)}
        src={kingsburyOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={cn(styles.theWasteOverlay, styles.sceneOverlay)}
        src={theWasteOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={cn(styles.marketChippingOverlay, styles.sceneOverlay)}
        src={marketChippingOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={styles.textureOverlay}
        src={textureOverlayAsset}
        role="presentation"
        alt=""
      />
    </div>
  );
};
