import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChooseYourSpellGame } from "../components/ChooseYourSpellGame/ChooseYourSpellGame";
import background from "../components/ChooseYourSpellGame/game/resources/textures/background.webp";
import { preload } from "../components/HidingAmongThingsGame/game/resources";
import { SceneManager } from "../components/SceneManager";
import {
  getInteractionOptions,
  InteractionOption,
  InteractionType,
} from "../config/interactions";
import { ROUTES } from "../constants/routes";
import { EventCategories, useAnalyticsEvent } from "../hooks/useAnalyticsEvent";
import { useInteractionStore } from "../store/interactionStore";

interface ChooseSpellProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  routeIndex: number;
}

export const ChooseSpell = ({
  routeIndex,
  setRouteIndex,
}: ChooseSpellProps) => {
  const { t } = useTranslation();
  const { updateHistory } = useInteractionStore();
  const options = getInteractionOptions(InteractionType.CHOOSE_SPELL);
  const navigate = useNavigate();
  const event = useAnalyticsEvent();

  const getWords = (): [string, string, string, string] => [
    t("rune.one"),
    t("rune.two"),
    t("rune.three"),
    t("rune.four"),
  ];

  // Start preloading HideAmongThings assets.
  useEffect(() => {
    preload();
  }, []);

  const handleChange = (option: InteractionOption) => {
    const points = (option.points % options.length) + 1;
    updateHistory(points, InteractionType.CHOOSE_SPELL);
    event(EventCategories.MainExperience, "choose-a-spell", option.id);

    setRouteIndex(routeIndex + 1);
    navigate(ROUTES.HIDE_AMONG_THINGS);
  };

  return (
    <div>
      <SceneManager
        background={background}
        quoteStopPercent={10}
        sceneWidth="200 * var(--dvh)"
        sceneStopPercent={50}
        scene={
          <ChooseYourSpellGame
            onOptionSelected={handleChange}
            words={getWords()}
          />
        }
      />
    </div>
  );
};
