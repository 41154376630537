// Maximum amount of concurrent stars on-screen.
export const MAX_STARS_AMOUNT = 4;

// [min, max] scale.
export const STAR_RAY_SCALE = [0.2, 0.5];

// Trail thickness in pixels.
export const STAR_TRAIL_THICKNESS = 16;

// The art board dimensions used to create the SVG Paths.
// These values are used for scaling. [width, height].
export const FIGMA_ART_BOARD_SIZE = [378, 641];
