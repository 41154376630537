import { Assets, BLEND_MODES, filters, Graphics, Sprite } from "pixi.js";
import { MinimalContainer } from "../../../../../../StarDemonGame/core/utilities/minimal-container";
import { TEXTURES } from "../../../../resources";

export class Reticle extends MinimalContainer {
  /** Main visual. */
  readonly sprite: Sprite;
  /** Static glow around the lens which fades in when an object is detected. */
  readonly progressGlow = new Graphics();
  /** Glow animating clock-wise indicating the actual progress. */
  readonly progressRadial: Sprite;
  /** Blur shader. */
  readonly progressBlur = new filters.BlurFilter();
  readonly reticleBlur = new filters.BlurFilter();
  progress = 0;

  constructor() {
    super();

    const spriteTexture = Assets.get(TEXTURES.LENS);
    this.sprite = new Sprite(spriteTexture);

    const radialTexture = Assets.get(TEXTURES.LENS_PROGRESS);
    this.progressRadial = new Sprite(radialTexture);

    this.reticleBlur.quality = 4;
    this.progressBlur.quality = 8;
    this.progressBlur.blur = 6;
    this.progressBlur.blendMode = BLEND_MODES.ADD;

    this.sprite.anchor.set(0.45, 0.216);
    this.progressRadial.anchor.set(0.5);

    this.addChild(this.progressGlow);
    this.addChild(this.sprite);
    this.progressGlow.blendMode = BLEND_MODES.ADD;

    this.addChild(this.progressRadial);
    this.progressRadial.blendMode = BLEND_MODES.ADD;

    this.filters = [this.reticleBlur];
    this.progressGlow.filters = [this.progressBlur];
  }

  drawProgressRing(lensSize: number) {
    this.progressGlow.clear();
    this.progressGlow.lineStyle(8, 0xffe400, 1, 0.5);
    const startAngle = -Math.PI / 2;
    const endAngle = startAngle + Math.PI * 2 * this.progress;
    this.progressGlow.arc(0, 0, (lensSize * 0.95) / 2, startAngle, endAngle);
  }

  resize(lensSize: number) {
    this.sprite.width = lensSize * 1.1;
    this.sprite.height = this.sprite.width * 2;

    this.progressRadial.width = this.sprite.width;
    this.progressRadial.height = this.sprite.width;

    this.drawProgressRing(lensSize);
  }
}
