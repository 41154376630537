import { useEffect, useState } from "react";

import { DoorDialQuote } from "./DoorDialQuote";
import { DoorDialGame } from "./DoorDialGame";

interface DoorDialProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DoorDial = ({ setRouteIndex, setIsDarkMode }: DoorDialProps) => {
  const [showGame, setShowGame] = useState<boolean>(false);

  useEffect(() => {
    // delay starting game until after quote
    const showGameTimeout = setTimeout(() => {
      setShowGame(true);
    }, 4700);

    return () => {
      clearTimeout(showGameTimeout);
    };
  }, []);

  return (
    <>
      {showGame ? (
        <DoorDialGame
          setRouteIndex={setRouteIndex}
          setIsDarkMode={setIsDarkMode}
        />
      ) : (
        <DoorDialQuote />
      )}
    </>
  );
};
