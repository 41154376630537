import { Emitter } from "@pixi/particle-emitter";
import { MinimalContainer } from "../../../../core/utilities/minimal-container";
import { StarData } from "../../../data";
import { CONFIG } from "./config";
import { createEmitterConfig } from "./config-big";

export class Particles {
  readonly container = new MinimalContainer();
  readonly emitter = new Emitter(this.container, CONFIG);
  emitterBig?: Emitter;

  constructor() {
    this.emitter.emit = false;
  }

  createEmitterBig(starData: StarData) {
    const [startColor, endColor] = starData.particleColors;
    this.emitterBig = new Emitter(
      this.container,
      createEmitterConfig(startColor, endColor)
    );
  }

  /** Update the pointer position. */
  public updatePointer(x: number, y: number) {
    if (this.emitter.destroyed) return;
    this.emitter.updateSpawnPos(x, y);
    this.emitterBig?.updateSpawnPos(x, y);
  }

  /** Clear memory. */
  public dispose() {
    this.container.destroy();
    this.emitter.destroy();
    this.emitterBig?.destroy();
  }
}
