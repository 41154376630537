import { Howl } from "howler";
import { RewardsScreen } from "../components/RewardsScreen";

interface RewardsProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  routeIndex?: number;
  audio: Howl;
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Rewards = ({
  setRouteIndex,
  audio,
  setHideNavigation,
}: RewardsProps) => {
  return (
    <RewardsScreen
      setRouteIndex={setRouteIndex}
      audio={audio}
      setHideNavigation={setHideNavigation}
    />
  );
};
