/**
 * @param element The path element.
 * @param length The length of the path.
 * @param precision The interval on which points should be sampled.
 */
export function samplePointsFromPath(
  element: SVGPathElement,
  length: number,
  precision: number
) {
  const points = [];

  // TODO(pimdewit): add more points in a curve.

  for (let pathLength = 0; pathLength < length; pathLength += precision) {
    const point = element.getPointAtLength(pathLength);
    points.push(point);
  }

  return points;
}
