import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import gsap from "gsap";
import Splitting from "splitting";

import styles from "./Quote.module.css";

interface QuoteProps {
  token?: string;
  className?: string;
  background?: string;
}

export const Quote = ({
  token,
  className,
  background = "rgba(0, 0, 0, 0.4)",
}: QuoteProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentToken, setCurrentToken] = useState<string>();

  const tl = useRef<GSAPTimeline>();
  const app = useRef(null);

  useEffect(() => {
    if (token) {
      setCurrentToken(token);
    } else {
      setCurrentToken(location.pathname.replace("/", ""));
    }
  }, []);

  useLayoutEffect(() => {
    if (currentToken) {
      Splitting({
        target: "#line",
        by: "chars",
        key: null,
      });
      Splitting({
        target: "#by",
        by: "chars",
        key: null,
      });
      const ctx = gsap.context(() => {
        tl.current && tl.current.progress(0).kill();
        tl.current = gsap
          .timeline({
            autoRemoveChildren: true,
            smoothChildTiming: true,
            delay: 0.05,
          })
          .from("#line .word", {
            duration: 0.5,
            ease: "sine.out",
            opacity: 0,
            stagger: 0.1,
            y: 20,
          })
          .to(
            app.current,
            {
              duration: 2,
              background,
            },
            "<+0.5"
          )
          .from(
            "#by .word",
            {
              duration: 0.5,
              ease: "sine.out",
              opacity: 0,
              stagger: 0.1,
              y: 20,
            },
            ">-0.3"
          );
      }, app);

      return () => ctx.revert();
    }
  }, [currentToken]);
  return (
    <div className={cn(styles.wrapper, className)} ref={app}>
      <div className={styles.copy}>
        {currentToken && (
          <>
            <h2
              id="line"
              className="heading-two"
              dangerouslySetInnerHTML={{
                __html: `&quot;${t(`quotes.${currentToken}-line`)}&quot;`,
              }}
            />
            <h3
              id="by"
              className="heading-three"
              dangerouslySetInnerHTML={{
                __html: `${t(`quotes.${currentToken}-by`)}`,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
