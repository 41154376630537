import { Assets, Sprite, Texture } from "pixi.js";
import { GameGraphics } from "../../../../StarDemonGame/core/game-graphics";
import { MinimalContainer } from "../../../../StarDemonGame/core/utilities/minimal-container";
import { TEXTURES } from "../../resources";
import { ObjectToFind } from "../object-to-find";
import { Scroller } from "../scroller";
import { LensVisual } from "./lens-visual";
import { ObjectToFindCollection } from "./object-to-find-collection";
import { ObjectToFindVisual } from "./object-to-find-visual";
import { SharpenFilter } from "./sharpen-filter";

export class Graphics extends GameGraphics {
  /** Every buffer acts as a "layer". */
  readonly layers = {
    scrollLayer: new MinimalContainer(),
    gui: new MinimalContainer(),
  };
  /** Main visual. */
  readonly backgroundSprite = new Sprite(
    Assets.get(TEXTURES.BACKGROUND) as Texture
  );
  /** Lens graphics. */
  readonly lens = new LensVisual();
  readonly objectsToFindContainer = new ObjectToFindCollection();
  readonly objectsToFind: ObjectToFindVisual[] = [];

  constructor(containerElement: HTMLElement, objectsToFind: ObjectToFind[]) {
    super(containerElement);

    // Scene graph.
    this.layers.scrollLayer.addChild(this.backgroundSprite);
    this.layers.gui.addChild(this.lens);
    this.container.scale.set(1 / this.renderer.resolution);

    for (const objectToFind of objectsToFind) {
      const instance = new ObjectToFindVisual(objectToFind);
      this.objectsToFindContainer.addChild(instance.glow);
      this.objectsToFind.push(instance);
    }
    this.objectsToFindContainer.onObjectsAdded();

    this.layers.scrollLayer.addChild(this.objectsToFindContainer);

    this.layers.scrollLayer.filters = [this.lens.filter];

    const sharpenFilter = new SharpenFilter();
    this.container.filters = [sharpenFilter];

    // Add all frame-buffer layers to the primary buffer.
    for (const l of Object.values(this.layers)) this.container.addChild(l);
  }

  get width() {
    return this.renderer.width;
  }

  get height() {
    return this.renderer.height;
  }

  syncScroller(scroller: Scroller) {
    this.backgroundSprite.width = scroller.scrollable.width;
    this.backgroundSprite.height = scroller.scrollable.height;
    this.objectsToFindContainer.objectMask.width = scroller.scrollable.width;
    this.objectsToFindContainer.objectMask.height = scroller.scrollable.height;
    for (const visual of this.objectsToFind) visual.resize(scroller.scale);
  }

  dispose() {
    for (const layer of Object.values(this.layers)) layer.destroy();
    super.dispose();
  }
}
