export type InteractionOption = {
  id: string;
  points: number;
};

export enum InteractionType {
  DOOR_DIAL,
  WHICH_HAT,
  CHOOSE_SPELL,
  HIDE_AMONG_THINGS,
  FEED_CALCIFER,
  YOUR_STAR_DEMON,
}

const interactionOptions: InteractionOption[][] = [
  // Door Dial.
  [
    { id: "kingsbury", points: 3 },
    { id: "the-waste", points: 2 },
    { id: "market-chipping", points: 1 },
    { id: "howls-garden", points: 4 },
  ],
  // Which Hat.
  [
    { id: "hat-1", points: 1 },
    { id: "hat-2", points: 2 },
    { id: "hat-3", points: 3 },
    { id: "hat-4", points: 4 },
    { id: "hat-5", points: 1 },
    { id: "hat-6", points: 2 },
    { id: "hat-7", points: 3 },
  ],
  // Choose Spell.
  [
    { id: "castle-redecorates", points: 1 },
    { id: "castle-moves", points: 2 },
    { id: "witches-command", points: 3 },
    { id: "witches-curse", points: 4 },
  ],
  // Hide Among Things.
  [
    { id: "mask", points: 1 },
    { id: "soft-toy", points: 1 },
    { id: "watering-can", points: 2 },
    { id: "feathers", points: 2 },
    { id: "arrow", points: 3 },
    { id: "hunters-horn", points: 3 },
    { id: "moon", points: 4 },
    { id: "snake", points: 4 },
  ],
  // Feed Calcifer.
  [
    { id: "wood", points: 1 },
    { id: "plait", points: 1 },
    { id: "egg-shell", points: 2 },
    { id: "bacon", points: 2 },
    { id: "rotten-wood", points: 4 },
  ],
  // Your Star Demon.
  [
    { id: "pink", points: 1 },
    { id: "blue", points: 2 },
    { id: "yellow", points: 3 },
    { id: "green", points: 4 },
  ],
];

export const getInteractionOptions = (type: InteractionType) => {
  return interactionOptions[type];
};
