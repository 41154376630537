import {
  Assets,
  CLEAR_MODES,
  Filter,
  FilterSystem,
  RenderTexture,
  Texture,
  WRAP_MODES,
} from "pixi.js";
import { TEXTURES } from "../../index";
import { fragmentShader, vertexShader } from "./rune-shader.glsl";

export class RuneShader extends Filter {
  constructor() {
    const noiseSampler = Assets.get(TEXTURES.NOISE) as Texture;

    // We animate the noise texture in the shader, so it should infinitely loop.
    noiseSampler.baseTexture.wrapMode = WRAP_MODES.MIRRORED_REPEAT;

    const uniforms = {
      uProgress: 0,
      uGlow: 0,
      uTime: Math.round(Math.random() * 1000),
      uNoiseSampler: noiseSampler,
    };

    super(vertexShader, fragmentShader, uniforms);
  }

  apply(
    filterManager: FilterSystem,
    input: RenderTexture,
    output: RenderTexture,
    clear: CLEAR_MODES
  ): void {
    this.uniforms.uTime += 0.015;
    filterManager.applyFilter(this, input, output, clear);
  }
}
