import { Text, TextStyle } from "pixi.js";

const MAX_WIDTH = 210;

const textStyle = new TextStyle({
  fontFamily: "Loewe",
  fill: ["#ffffff"],
  fontSize: 34,
  fontWeight: "400",
  breakWords: true,
  wordWrap: true,
  align: "center",
  wordWrapWidth: MAX_WIDTH,
});

export function text(text: string) {
  const instance = new Text(text, textStyle);

  if (instance.width > MAX_WIDTH) {
    const ratio = MAX_WIDTH / instance.width;
    instance.scale.set(ratio);
  }

  return instance;
}
