import cn from "classnames";
import styles from "./Button.module.css";
import backgroundGreenLight from "./images/green-light.png";
import backgroundCreamDark from "./images/cream-dark.png";
import backgroundBlueLight from "./images/blue-light.png";
import backgroundRedLight from "./images/red-light.png";

interface ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  shape?: "circle" | "rectangle";
  background?: "cream-dark" | "green-light" | "blue-light" | "red-light";
  className?: string;
}

export const Button = ({
  onClick,
  children,
  shape,
  background,
  className,
}: ButtonProps) => {
  const wrapperStyles = cn(
    shape === "circle" && styles.circle,
    shape === "rectangle" && styles.rectangle,
    background === "green-light" && styles.backgroundGreenLight,
    background === "cream-dark" && styles.backgroundCreamDark,
    background === "blue-light" && styles.backgroundBlueLight,
    background === "red-light" && styles.backgroundRedLight,
    className
  );

  const textStyles = cn(styles.text, shape === "circle" && styles.textCircle);

  const setImageSource = (background: ButtonProps["background"]) => {
    switch (background) {
      case "green-light":
        return backgroundGreenLight;
      case "cream-dark":
        return backgroundCreamDark;
      case "blue-light":
        return backgroundBlueLight;
      case "red-light":
        return backgroundRedLight;
      default:
        return backgroundGreenLight;
    }
  };

  return (
    <button onClick={onClick} className={wrapperStyles}>
      <span className={textStyles}>{children}</span>
      {shape === "circle" && (
        <img
          className={styles.backgroundImage}
          src={setImageSource(background)}
        />
      )}
    </button>
  );
};
