import {
  BLEND_MODES,
  Mesh,
  MeshMaterial,
  Point,
  RopeGeometry,
  Texture,
} from "pixi.js";
import { STAR_TRAIL_THICKNESS } from "../../../constants";
import { program, uniforms } from "../../../resources/programs/trail";

export class Trail {
  readonly mesh: Mesh;

  constructor(
    points: Point[],
    texture: Texture,
    thickness: number = STAR_TRAIL_THICKNESS
  ) {
    const ropeGeometry = new RopeGeometry(thickness, points);
    const meshMaterial = new MeshMaterial(texture, {
      program,
      uniforms,
    });

    this.mesh = new Mesh(ropeGeometry, meshMaterial);
    this.mesh.blendMode = BLEND_MODES.ADD;
  }
}
