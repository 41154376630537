import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import cn from "classnames";
import { Button } from "../Button/Button";
import { InstructionsModal } from "../InstructionsModal";
import { useInteractionStore } from "../../store/interactionStore";
import { LANGUAGES, LanguageType } from "../../constants/languages";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { setThemeColour } from "../../utils/theme";
import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";
import { getCharacterChoice } from "../../config/characters";
import { characterNames } from "../../constants/characterNames";

import styles from "./Menu.module.css";
interface MenuProps {
  routeIndex: number;
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  isDarkMode: boolean;
  isAudioMuted: boolean;
  setIsAudioMuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Menu = ({
  routeIndex,
  setRouteIndex,
  setHideNavigation,
  isDarkMode,
  isAudioMuted,
  setIsAudioMuted,
}: MenuProps) => {
  const { reset, getTotalPoints, group } = useInteractionStore();
  const { t, i18n } = useTranslation();
  const event = useAnalyticsEvent();
  const location = useLocation();
  const totalPoints = getTotalPoints();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  const [languageName, setLanguageName] = useState<LanguageType["name"]>();

  const [instructionsOpen, setInstructionsOpen] = useState<boolean>(false);
  const [exitUrl, setExitUrl] = useState<string>("");

  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLSpanElement>(null);
  const closeButtonRef = useRef<HTMLSpanElement>(null);
  const languageSelectIconRef = useRef<SVGSVGElement>(null);
  const languageListRef = useRef<HTMLUListElement>(null);
  const languagesBackgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (routeIndex >= 7) {
      const character = getCharacterChoice(group, totalPoints) || "";
      setExitUrl(
        t("character-reveal.exit-url", {
          name: characterNames.find((c) => c.name === character)?.value,
        }) || ""
      );
    } else {
      setExitUrl(t("navigation.exit-url") || "");
    }
  }, [routeIndex]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRestartSelect = () => {
    reset();
    setRouteIndex(1);
    setHideNavigation(false);
    event(EventCategories.Navigation, "Menu interaction", "Back to start");
    if (isMenuOpen) setIsMenuOpen(false);
  };

  const handleInstructionsSelect = () => {
    setIsMenuOpen(!isMenuOpen);
    setInstructionsOpen(true);
    event(EventCategories.Navigation, "Menu interaction", "Show instructions");
    setTimeout(() => {
      setInstructionsOpen(false);
    }, 2000);
  };

  const handleLanguagesToggle = () => {
    setIsLanguagesOpen(!isLanguagesOpen);
  };

  const handleLanguageSelect = (language: LanguageType) => {
    i18n.changeLanguage(language.code);
    setIsLanguagesOpen(!isLanguagesOpen);
    event(
      EventCategories.Navigation,
      "Menu interaction",
      `Language : ${language.code}`
    );
  };

  const handleMute = () => {
    window.Howler.mute(!isAudioMuted);
    setIsAudioMuted(!isAudioMuted);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [routeIndex]);

  useEffect(() => {
    if (!isMenuOpen) {
      setIsLanguagesOpen(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const activeCode = i18n.language;
    const activeLanguage = LANGUAGES.find(
      (language) => language.code === activeCode
    );
    setLanguageName(activeLanguage?.name);
  }, [i18n.language]);

  const animationDefaults = {
    duration: 1,
    ease: "power4.inOut",
  };

  useEffect(() => {
    gsap.set(menuRef.current, { opacity: 0, display: "none" });
    gsap.set(menuButtonRef.current, { opacity: 1, y: 0 });
    gsap.set(closeButtonRef.current, { opacity: 0, y: "100%" });
    gsap.set(languageListRef.current, { opacity: 0, height: 0 });
    gsap.set(languageSelectIconRef.current, { rotate: 0 });
    gsap.set(languagesBackgroundRef.current, { opacity: 0 });
  }, []);

  useEffect(() => {
    const timeline = gsap.timeline({ defaults: { ...animationDefaults } });
    if (isMenuOpen) {
      timeline.set(menuRef.current, { display: "block" });
      timeline.to(menuRef.current, { opacity: 1, y: 0 });
      timeline.to(menuButtonRef.current, { opacity: 0, y: "-100%" }, "<");
      timeline.to(closeButtonRef.current, { opacity: 1, y: 0 }, "<");
    } else {
      timeline.to(menuRef.current, { opacity: 0 });
      timeline.to(menuButtonRef.current, { opacity: 1, y: 0 }, "<");
      timeline.to(closeButtonRef.current, { opacity: 0, y: "100%" }, "<");
      timeline.set(menuRef.current, { display: "none" });
    }
    return () => {
      timeline.kill();
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const timeline = gsap.timeline({ defaults: { ...animationDefaults } });
    if (isLanguagesOpen) {
      timeline.to(languageListRef.current, { opacity: 1, height: "auto" });
      timeline.to(languagesBackgroundRef.current, { opacity: 1 }, "<");
      timeline.to(languageSelectIconRef.current, { rotate: 180 }, "<");
    } else {
      timeline.to(languageListRef.current, { opacity: 0, height: 0 });
      timeline.to(languagesBackgroundRef.current, { opacity: 0 }, "<");
      timeline.to(languageSelectIconRef.current, { rotate: 0 }, "<");
    }
    return () => {
      timeline.kill();
    };
  }, [isLanguagesOpen]);

  return (
    <>
      <InstructionsModal open={instructionsOpen} />
      <nav className={styles.controls}>
        <a
          target="_blank"
          rel="noreferrer"
          href={exitUrl}
          className={cn(
            styles.externalLink,
            isDarkMode && styles.isDarkMode,
            setThemeColour(isDarkMode)
          )}
        >
          <span className={styles.externalLinkText}>
            {t("navigation.exit-name")}
          </span>
        </a>
        <div className={styles.menuWrapper}>
          {location.pathname !== "/home" && location.pathname !== "/landing" && (
            <button className={styles.muteButton} onClick={handleMute}>
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                {isAudioMuted ? (
                  <>
                    <path
                      d="M.68 3.8c-.37 0-.68.3-.68.68v2.04c0 .38.3.69.68.69H2c.18 0 .36.07.48.2l1.48 1.47a.68.68 0 0 0 1.17-.48V2.6c0-.61-.74-.91-1.17-.48L2.47 3.59c-.12.13-.3.2-.48.2H.7Z"
                      fill={isDarkMode ? "#fff" : "#000"}
                    />
                    <path
                      d="M10 4L7 7"
                      stroke={isDarkMode ? "#fff" : "#000"}
                      strokeWidth="0.75"
                      strokeLinecap="round"
                    />
                    <path
                      d="M7 4L10 7"
                      stroke={isDarkMode ? "#fff" : "#000"}
                      strokeWidth="0.75"
                      strokeLinecap="round"
                    />
                  </>
                ) : (
                  <path
                    fill={isDarkMode ? "#fff" : "#000"}
                    d="M7 .7c-.4-.1-.7.2-.7.5s.1.5.4.6A4 4 0 0 1 9 5.5a4 4 0 0 1-2.4 3.7.7.7 0 0 0-.4.5c0 .4.3.7.7.6a5.1 5.1 0 0 0 3.2-4.8A5.1 5.1 0 0 0 7 .7Zm.7 4.8c0-.6-.2-1.2-.6-1.6-.3-.4-.8-.1-.8.4v2.4c0 .5.5.8.8.4a2.5 2.5 0 0 0 .6-1.6Zm-7-1.7a.7.7 0 0 0-.7.7v2c0 .4.3.7.7.7H2c.2 0 .3 0 .5.2L4 9A.7.7 0 0 0 5 8.4V2.6A.7.7 0 0 0 4 2.1L2.5 3.6a.7.7 0 0 1-.5.2H.7Z"
                  />
                )}
              </svg>
            </button>
          )}
          <div>
            <Button
              onClick={handleMenuToggle}
              className={cn(styles.menuButton, "caps-medium")}
            >
              <span
                ref={menuButtonRef}
                className={cn(
                  styles.menuButtonText,
                  isDarkMode && styles.isDarkMode,
                  styles.isOpen,
                  setThemeColour(isDarkMode)
                )}
              >
                {t("menu.menu")}
              </span>
              <span ref={closeButtonRef} className={styles.menuButtonText}>
                &#10005; {t("menu.close")}
              </span>
            </Button>
            <nav ref={menuRef} className={styles.menu}>
              <ul className={styles.menuList}>
                {location.pathname !== "/home" &&
                  location.pathname !== "/landing" && (
                    <>
                      <li className={styles.menuListItem}>
                        <Button
                          className={"heading-four"}
                          onClick={handleRestartSelect}
                        >
                          {t("menu.back-to-start")}
                        </Button>
                      </li>
                      {routeIndex >= 2 && routeIndex <= 6 && (
                        <li className={styles.menuListItem}>
                          <Button
                            className={"heading-four"}
                            onClick={handleInstructionsSelect}
                          >
                            {t("menu.instructions")}
                          </Button>
                        </li>
                      )}
                    </>
                  )}
                <li className={cn(styles.menuListItem, styles.languageSelect)}>
                  <div
                    ref={languagesBackgroundRef}
                    className={styles.languageSelectBackground}
                  ></div>
                  <Button
                    className={cn("heading-four", styles.languageSelectButton)}
                    onClick={handleLanguagesToggle}
                  >
                    {languageName}
                    <svg
                      ref={languageSelectIconRef}
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.29289 4.29289L0.707107 1.70711C0.077142 1.07714 0.523309 0 1.41421 0H6.58579C7.47669 0 7.92286 1.07714 7.2929 1.70711L4.70711 4.29289C4.31658 4.68342 3.68342 4.68342 3.29289 4.29289Z"
                        fill="black"
                      />
                    </svg>
                  </Button>
                  <ul ref={languageListRef} className={styles.languageList}>
                    {LANGUAGES.map((language: LanguageType, index) => (
                      <li
                        className={cn(
                          styles.languageListItem,
                          language.code === i18n.language && styles.isActive
                        )}
                        key={index}
                      >
                        <Button
                          onClick={() => handleLanguageSelect(language)}
                          className={"cta"}
                        >
                          {language.name}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </>
  );
};
