import gsap from "gsap";
import { FederatedPointerEvent } from "pixi.js";
import { Graphics } from "./modules/graphics";
import { Rune } from "./modules/graphics/rune";

export enum GameEvents {
  GAME_OVER = "game-over",
}

const HELD_DOWN_FRAMES = 60;

export class Game {
  readonly graphics: Graphics;
  private selectedRune: Rune | null = null;
  private framesHeldDown = 0;

  constructor(
    readonly containerElement: HTMLElement,
    words: [string, string, string, string]
  ) {
    this.graphics = new Graphics(containerElement, words);
    this.graphics.ticker.add(this.render);

    this.addEventListeners();
    this.onResize();
    this.start();
  }

  set words(words: [string, string, string, string]) {
    for (let i = 0; i < this.graphics.runes.length; i++) {
      const rune = this.graphics.runes[i];
      rune.text.text = words[i];
    }
  }

  addEventListeners() {
    window.addEventListener("resize", this.onResize);
  }

  removeEventListeners() {
    window.removeEventListener("resize", this.onResize);
  }

  async start() {
    this.onResize();
    this.graphics.ticker.start();
    await this.graphics.playText();
    for (const r of this.graphics.runes) r.on("pointerdown", this.onSelect);
    window.addEventListener("pointerup", this.onPointerUp);
  }

  readonly render = () => {
    if (this.selectedRune) {
      this.framesHeldDown += 1;

      const normalised = this.framesHeldDown / HELD_DOWN_FRAMES;
      this.selectedRune.revealShader.uniforms.uGlow = normalised;
      if (this.selectedRune.particles) {
        this.selectedRune.particles.sparks.spawnChance = normalised;
      }

      if (this.framesHeldDown > HELD_DOWN_FRAMES) {
        this.runeHeldDown(this.selectedRune);
        this.selectedRune = null;
      }
    }
  };

  dispose() {
    window.removeEventListener("pointerup", this.onPointerUp);
    this.removeEventListeners();
    for (const rune of this.graphics.runes) {
      rune.off("pointerdown", this.onSelect);
      rune.dispose();
      rune.destroy();
    }
    this.graphics.dispose();
  }

  async runeHeldDown(selectedRune: Rune) {
    // Dispose event listeners, only 1 rune can be selected.
    for (const rune of this.graphics.runes) {
      rune.off("pointerdown", this.onSelect);
    }

    await this.graphics.activateGlow(selectedRune);

    const evt = new CustomEvent(GameEvents.GAME_OVER, {
      detail: selectedRune.data.option,
    });
    this.containerElement.dispatchEvent(evt);
  }

  private readonly onPointerUp = () => {
    if (this.selectedRune) {
      gsap.to(this.selectedRune.revealShader.uniforms, { uGlow: 0 });
      if (this.selectedRune.particles) {
        gsap.to(this.selectedRune.particles.sparks, { spawnChance: 0 });
      }
    }
    this.framesHeldDown = 0;
    this.selectedRune = null;
  };

  private onSelect = async (event: FederatedPointerEvent) => {
    const selectedRune = event.target as Rune;
    this.selectedRune = selectedRune;
  };

  private readonly onResize = () => {
    this.graphics.resize();
  };
}
