// language=glsl
export const vertexShader = `
  attribute vec2 aVertexPosition;

  uniform mat3 projectionMatrix;
  uniform mat3 filterMatrix;

  varying vec2 vTextureCoord;
  varying vec2 vFilterCoord;

  uniform vec4 inputSize;
  uniform vec4 outputFrame;

  vec4 filterVertexPosition(void) {
    vec2 position = aVertexPosition * max(outputFrame.zw, vec2(0.)) + outputFrame.xy;

    return vec4((projectionMatrix * vec3(position, 1.0)).xy, 0.0, 1.0);
  }

  vec2 filterTextureCoord(void) {
    return aVertexPosition * (outputFrame.zw * inputSize.zw);
  }

  void main(void) {
    gl_Position = filterVertexPosition();
    vTextureCoord = filterTextureCoord();
    vFilterCoord = (filterMatrix * vec3(vTextureCoord, 1.0)).xy;
  }
`;

// language=glsl
export const fragmentShader = `
  varying vec2 vFilterCoord;
  varying vec2 vTextureCoord;

  uniform float distortion;
  uniform mat2 rotation;
  uniform sampler2D uSampler;
  uniform sampler2D mapSampler;

  uniform highp vec4 inputSize;
  uniform vec4 inputClamp;

  void main(void) {
    vec4 map = texture2D(mapSampler, vFilterCoord);

    map -= 0.5;
    map.xy = distortion * inputSize.zw * map.xy;

    gl_FragColor = texture2D(uSampler, clamp(vec2(vTextureCoord.x + map.x, vTextureCoord.y + map.y), inputClamp.xy, inputClamp.zw));
  }
`;
