import {
  getInteractionOptions,
  InteractionOption,
  InteractionType,
} from "../../../../config/interactions";
import { SvgPath } from "../resources/svg-path";

const OPTIONS = getInteractionOptions(InteractionType.YOUR_STAR_DEMON);

export enum StarOptionIdentifier {
  PINK,
  BLUE,
  YELLOW,
  GREEN,
}

export interface StarData {
  option: InteractionOption;
  colors: number[];
  particleColors: [string, string];
  path: SvgPath;
  offset: DOMPoint;
}

export const STARS: StarData[] = [
  {
    option: OPTIONS[StarOptionIdentifier.PINK],
    colors: [0xf000ff, 0xff69e8],
    particleColors: ["#b838c7", "#2c95d1"],
    path: SvgPath.SWOOSH_3,
    offset: new DOMPoint(96, -32),
  },
  {
    option: OPTIONS[StarOptionIdentifier.BLUE],
    colors: [0x00ccff],
    particleColors: ["#a9bdcf", "#3854c7"],
    path: SvgPath.SWOOSH,
    offset: new DOMPoint(64, -32),
  },
  {
    option: OPTIONS[StarOptionIdentifier.YELLOW],
    colors: [0xffd200],
    particleColors: ["#8d8d3b", "#c77d38"],
    path: SvgPath.SWOOSH_2,
    offset: new DOMPoint(128, -32),
  },
  {
    option: OPTIONS[StarOptionIdentifier.GREEN],
    colors: [0x72ff00, 0x00ffae],
    particleColors: ["#81c597", "#5ac738"],
    path: SvgPath.WAVE,
    offset: new DOMPoint(128, -32),
  },
];
