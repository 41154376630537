import gsap from "gsap";
import { Assets, Sprite } from "pixi.js";
import { SharpenFilter } from "../../../../HidingAmongThingsGame/game/modules/graphics/sharpen-filter";
import { GameGraphics } from "../../../../StarDemonGame/core/game-graphics";
import { MinimalContainer } from "../../../../StarDemonGame/core/utilities/minimal-container";
import {
  backgroundCover,
  BackgroundSize,
  backgroundSize,
} from "../../../../StarDemonGame/game/modules/graphics/utilities/background-cover";
import { wait } from "../../../../StarDemonGame/game/modules/wait";
import { SHARPEN_STRENGTH, SHARPEN_STRENGTH_RUNE } from "../../constants";
import { RUNE_DATA } from "../../data";
import { TEXTURES } from "../../resources";
import { Rune } from "./rune";
import { Particles } from "./rune/particles";

export class Graphics extends GameGraphics {
  readonly background: Sprite;
  readonly scaleLayer = new MinimalContainer(true);
  readonly sharpenShader = new SharpenFilter();
  readonly layers = [new MinimalContainer(true), new MinimalContainer()];
  readonly runes: Rune[] = [];

  constructor(
    containerElement: HTMLElement,
    words: [string, string, string, string]
  ) {
    super(containerElement, { backgroundColor: 0x653c28 }, true);

    const backgroundTexture = Assets.get(TEXTURES.BACKGROUND);
    this.background = new Sprite(backgroundTexture);

    this.container.addChild(this.background);
    for (const layer of this.layers) this.scaleLayer.addChild(layer);
    this.container.addChild(this.scaleLayer);

    for (let i = 0; i < RUNE_DATA.length; i++) {
      const rune = new Rune(RUNE_DATA[i], words[i]);
      const smoke = new Particles();
      smoke.smoke.updateSpawnPos(
        rune.x + rune.width / 2,
        rune.y + rune.height / 2
      );
      rune.particles = smoke;
      this.scaleLayer.addChild(rune, smoke);
      this.runes.push(rune);
    }

    // Sharpen all output.
    this.sharpenShader.uniforms.uStrength = SHARPEN_STRENGTH;
    this.container.filters = [this.sharpenShader];

    this.resize();
  }

  async playIntro(rune: Rune, delay: number) {
    await wait(4000);
    await wait(delay);

    if (rune.disposed) return;

    await rune.playText();
    if (!this.sharpenShader) return;

    if (this.sharpenShader.uniforms) {
      gsap.to(this.sharpenShader.uniforms, {
        uStrength: SHARPEN_STRENGTH_RUNE,
      });
    }
    await rune.toggleRune(true);
  }

  async activateGlow(rune: Rune) {
    await rune.runeSelectedAnimation();
  }

  async playText() {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < this.runes.length; i++) {
      promises.push(this.playIntro(this.runes[i], 200 * i));
    }

    await Promise.all(promises);
  }

  resize() {
    super.resize(window.innerWidth, window.innerHeight);
    const { width, height } = this.renderer;
    const cover = backgroundCover(this.background, width, height);
    this.background.scale.set(cover.scale);
    this.background.position.set(cover.position.x, cover.position.y);

    const SCALE_LAYER_WIDTH = 386; //this.scaleLayer.width;
    const SCALE_LAYER_HEIGHT = 563; //this.scaleLayer.height;

    const mainSize = backgroundSize(
      SCALE_LAYER_WIDTH,
      SCALE_LAYER_HEIGHT,
      width,
      height,
      BackgroundSize.CONTAIN
    );

    this.scaleLayer.position.set(mainSize.position.x, mainSize.position.y);

    const paddingPercentage = 0.075;
    const scaleModifier = 1 - paddingPercentage * 2;
    this.scaleLayer.scale.set(mainSize.scale * scaleModifier);
    this.scaleLayer.position.x +=
      (this.scaleLayer.width * paddingPercentage * 2) / 2;
    this.scaleLayer.position.y +=
      (this.scaleLayer.height * paddingPercentage) / 2;

    for (const rune of this.runes) {
      const x = rune.x + rune.width / 4;
      const y = rune.y + rune.height / 2;
      rune.particles?.smoke.updateSpawnPos(x, y);
      rune.particles?.sparks.updateSpawnPos(x, y);
    }
  }
}
