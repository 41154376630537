import { Container } from "pixi.js";

/**
 * A PIXI.Container instance with a couple runtime checks disabled.
 * @see {@link https://pixijs.download/dev/docs/PIXI.Container.html}
 */
export class MinimalContainer extends Container {
  /** @param interactive Whether we use PIXI's internal hitbox detection. */
  constructor(interactive = false) {
    super();
    this.interactive = interactive;
    this.interactiveChildren = interactive;
    this.accessible = false;
    this.accessibleChildren = false;
  }
}
