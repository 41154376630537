import create from "zustand";
import produce from "immer";
import { InteractionType } from "../config/interactions";

interface InteractionStore {
  group: number;
  interactionHistory: { points: number; type: InteractionType }[];
  updateHistory: (
    currentPoints: number,
    currentInteraction: InteractionType
  ) => void;
  removePreviousHistory: () => void;
  updateGroup: (group: number) => void;
  getTotalPoints: () => number;
  reset: () => void;
}

export const useInteractionStore = create<InteractionStore>((set, get) => ({
  group: 0,
  interactionHistory: [],
  getTotalPoints: () =>
    get().interactionHistory.reduce(
      (total, current) => total + current.points,
      0
    ),
  updateHistory: (currentPoints, currentInteraction) =>
    set(
      produce((draft) => {
        draft.interactionHistory.push({
          points: currentPoints,
          type: currentInteraction,
        });
      })
    ),
  removePreviousHistory: () =>
    set(
      produce((draft) => {
        draft.interactionHistory.pop();
      })
    ),
  updateGroup: (selectedGroup) => set(() => ({ group: selectedGroup })),
  reset: () => set(() => ({ interactionHistory: [] })),
}));
