import { useTranslation } from "react-i18next";
import styles from "./WeChatShare.module.css";

interface WeChatShareProps {
  character: string;
  isOpen: boolean;
  handleClose: () => void;
}
export const WeChatShare = ({
  character,
  isOpen,
  handleClose,
}: WeChatShareProps) => {
  const { t, i18n } = useTranslation();
  return isOpen ? (
    <div className={styles.wrapper}>
      <div className={styles.shareImage}>
        <img
          src={`./assets/images/share/${character}-share-${i18n.language}.jpg`}
          alt={`${character} share image`}
        />
      </div>
      <p className={styles.instructions}>{t("rewards.instructions")}</p>
      <span className={styles.close} onClick={handleClose}>
        &#10005;
      </span>
    </div>
  ) : null;
};
