// Event names.
export enum GameEvent {
  // An object has been found - game is over.
  FINISH = "game-over",
}

// The area to scroll through - very likely the same dimensions as the main image.
export const SCROLL_AREA = { width: 2048, height: 1024 };
// Default intensity for the lens filter (potentially overwritten by React props).
export const LENS_FILTER_INTENSITY = 16;
// Default size of the lens (potentially overwritten by React props).
export const LENS_FILTER_SIZE = 96;
// Percentage from the edges of the viewport before the screen starts panning. 0.5 (50%) means there is no safe area.
export const SCROLLER_PAN_HIT_BOX_SIZE = 0.45;
// Default Pan speed; @see {Scroller.panSpeedMultiplier}
export const SCROLLER_PAN_SPEED_MULTIPLIER = 0.06;
// If the lens progressbar goes past this threshold, do something.
export const LENS_PROGRESS_TRIGGER = 0.98;

// Object-glow interpolation strength.
export enum ObjectGlowInterpolationStrength {
  IDLE = 0.05,
  ACTIVE = 0.012,
}

// Responsive lens sizing interpolation strength.
export enum LensSizeInterpolationStrength {
  IDLE = 0.15,
  ACTIVE = 0.06,
}

// Lens progress-ring interpolation strength.
export enum LensProgressInterpolationStrength {
  INACTIVE = 0.05,
  ACTIVE = 0.022,
}
