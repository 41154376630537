import { useTranslation } from "react-i18next";
import {
  useAnalyticsEvent,
  EventActions,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";
import background from "./assets/bg.jpg";

import { Button } from "../Button/Button";

import styles from "./LandingPage.module.css";

interface LandingPageProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const LandingPage = ({ setRouteIndex }: LandingPageProps) => {
  const { t } = useTranslation();
  const event = useAnalyticsEvent();
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${background})` }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="149"
        height="35"
        fill="none"
        className={styles.logo}
      >
        <path
          fill="#202020"
          d="M20.504 28.925c-2.398 1.33-7.284 1.994-9.398 1.994H7.487V3.857c0-.427.33-.903.563-1.187L9.457.912s.096-.236 0-.568H.11a.947.947 0 0 0 0 .57l1.409 1.76c.233.283.565.759.565 1.186v26.584c0 .428-.47 1.091-.75 1.328L.061 32.914s-.14.284 0 .664h20.065l1.128-4.653c-.377-.096-.751 0-.751 0ZM54.238 16.333C54.002 7.595 47.282 0 38.165 0c-2.49 0-4.982.523-7.144 1.615-5.828 2.942-10.1 9.449-10.1 15.953 0 9.21 7.517 16.521 16.069 16.521 2.207 0 4.369-.284 6.342-1.14 6.25-2.657 10.762-8.497 10.903-16.616M31.963 3.846c10.29-2.043 16.868 5.316 16.868 14.194 0 4.749-2.066 9.686-6.06 12.676-9.68 1.376-16.307-5.269-16.307-14.67 0-4.7 1.597-9.258 5.5-12.203M77.552 29.448c-1.973.951-7.754 1.471-9.868 1.471h-3.148V19.1h5.684l1.785 1.091s.33.096.658 0v-5.604c-.281-.095-.658 0-.658 0l-1.88.808h-5.592V3.002h8.127l4.324.996s.377.096.75 0L76.937.34H57.152a.936.936 0 0 0 0 .568l1.409 1.758c.233.284.562.76.562 1.187v26.59c0 .428-.47 1.091-.75 1.328l-1.268 1.139s-.14.284 0 .664H77.17l1.127-4.13c-.377-.096-.75 0-.75 0M147.89 29.448c-1.974.951-7.754 1.471-9.868 1.471h-3.149V19.1h5.685l1.785 1.091s.329.096.658 0v-5.604c-.281-.095-.658 0-.658 0l-1.881.808h-5.592V3.002h8.128l4.324.996s.376.096.753 0L147.277.34h-19.784a.932.932 0 0 0 0 .568l1.408 1.758c.237.284.566.76.566 1.187v26.59c0 .428-.47 1.091-.751 1.328l-1.268 1.139s-.14.284 0 .664h20.065l1.127-4.13c-.376-.096-.75 0-.75 0M125.281.404h-7.802a.983.983 0 0 0 0 .57l1.456 1.376c.282.284.658.712.566 1.14h.003l-6.911 21.697L105.686.451c-.47-.143-3.054-.143-3.571 0L93.844 25.14 88.109 3.726c-.093-.428-.093-.76.095-.996L89.85.972s.093-.237 0-.571h-9.632a.947.947 0 0 0 0 .57l1.597 1.759c.14.144.47.76.518.948h.005l8.218 29.96c.421.143 2.864.095 3.43 0L102.3 8.666l7.188 24.972c.47.143 2.868.143 3.385 0l9.632-29.485c.14-.424.377-.9.706-1.184l2.069-1.994s.093-.237 0-.571Z"
        />
      </svg>
      <section className={styles.collection}>
        <p>{t("experience-landing.title")}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: t("experience-landing.collection-copy"),
          }}
        />

        <Button
          shape="rectangle"
          onClick={() => {
            event(
              EventCategories.Landing,
              EventActions.Select,
              "See the collection"
            );
            window.open(t("experience-landing.collection-url") || "", "_blank");
          }}
          background={"cream-dark"}
        >
          {t("experience-landing.collection-cta")}
        </Button>
      </section>
      <section className={styles.game}>
        <h2 className="heading-two">{t("experience-landing.game-copy")}</h2>

        <Button
          shape="rectangle"
          onClick={() => {
            event("landing", EventActions.Select, "Try the experience");
            setRouteIndex(0);
            window.open("/", "_self");
          }}
          background={"cream-dark"}
        >
          {t("experience-landing.game-cta")}
        </Button>
      </section>
    </div>
  );
};
