import styles from "./index.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import {
  getInteractionOptions,
  InteractionType,
} from "../../config/interactions";

import { useInteractionStore } from "../../store/interactionStore";
import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";

import { ROUTES } from "../../constants/routes";

import { clamp, snap } from "./math";
import { updatePhysics } from "./physics";

import woodAsset from "../../assets/images/feed-calcifer/wood.webp";
import rottenWoodAsset from "../../assets/images/feed-calcifer/wood-2.webp";
import baconAsset from "../../assets/images/feed-calcifer/bacon.webp";
import eggAsset from "../../assets/images/feed-calcifer/egg.webp";
import plaitAsset from "../../assets/images/feed-calcifer/plait.webp";

import backgroundAsset from "../../assets/images/feed-calcifer/background.webp";
import mouthAsset from "../../assets/images/feed-calcifer/mouth.webp";
import selectOverlayAsset from "../../assets/images/feed-calcifer/select-overlay.webp";
import selectOverlaySparkleAsset from "../../assets/images/feed-calcifer/select-overlay-sparkle.webp";

const { abs, sin, PI, round, max } = Math;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

interface FeedCalciferGameProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  introCompleted: boolean;
}

export interface FeedCalciferState {
  timestamp: number;
  timestep: number;
  maxUpdates: number;
  index: number;
  bounds: DOMRect;
  positionPrev: number;
  position: number;
  speed: number;
  snap: boolean;
  friction: number;
  items: Array<{ z: number }>;
  itemGap: number;
  stopped: boolean;
  timeBuffer: number;
  select: number;
  selectThreshold: number;
  selected: boolean;
  leftEyeOffset: { x: number; y: number };
  rightEyeOffset: { x: number; y: number };
  mouthWidth: number;
  mouthHeight: number;
  pointer: {
    isDown: boolean;
    xPrev: number;
    yPrev: number;
    x: number;
    y: number;
  };
}

const itemAssets = [
  woodAsset,
  plaitAsset,
  eggAsset,
  baconAsset,
  rottenWoodAsset,
];

export const FeedCalciferGame = ({
  setRouteIndex,
  introCompleted = false,
}: FeedCalciferGameProps) => {
  const { t } = useTranslation();
  const { updateHistory } = useInteractionStore();
  const navigate = useNavigate();
  const event = useAnalyticsEvent();

  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const pupilLeftRef = useRef<HTMLDivElement>(null);
  const pupilRightRef = useRef<HTMLDivElement>(null);
  const mouthOutlineRef = useRef<HTMLDivElement>(null);
  const mouthRef = useRef<HTMLImageElement>(null);

  const itemRefs = itemAssets.map(() => useRef<HTMLImageElement>(null));
  const shadowRefs = itemAssets.map(() => useRef<HTMLImageElement>(null));
  const imageRefs = itemAssets.map(() => useRef<HTMLImageElement>(null));

  // internal real-time state (performance: no setState)
  const [state] = useState<FeedCalciferState>(() => {
    const itemGap = PI * 0.64;

    return {
      timestamp: 0,
      timestep: 1000 / 60,
      maxUpdates: 4,
      index: -1,
      bounds: new DOMRect(),
      positionPrev: itemGap * 3,
      position: itemGap * 3,
      speed: 0,
      snap: true,
      friction: 1,
      items: itemRefs.map(() => ({ z: 0 })),
      itemGap,
      stopped: false,
      timeBuffer: 0,
      select: 0,
      selectThreshold: 0.95,
      selected: false,
      leftEyeOffset: { x: 0, y: 0 },
      rightEyeOffset: { x: 0, y: 0 },
      mouthWidth: 0.98,
      mouthHeight: 0.3,
      pointer: {
        isDown: false,
        xPrev: 0,
        yPrev: 0,
        x: 0,
        y: 0,
      },
    };
  });

  const [showChoice, setShowChoice] = useState<boolean>(false);

  const updateBounds = () => {
    if (itemsContainerRef.current) {
      state.bounds = itemsContainerRef.current.getBoundingClientRect();
    }
  };

  const updatePointerPosition = (event: React.PointerEvent) => {
    updateBounds();

    state.pointer.x = event.clientX - state.bounds.x;
    state.pointer.y = event.clientY - state.bounds.y;
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    if (!event.isPrimary || state.selected) {
      return;
    }

    const { pointer } = state;

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.add(styles.pointerDown);
    }

    event.preventDefault();
    updatePointerPosition(event);

    pointer.isDown = true;
    pointer.xPrev = pointer.x;
    pointer.yPrev = pointer.y;
  };

  const handlePointerUp = (event: React.PointerEvent) => {
    if (!event.isPrimary || state.selected) {
      return;
    }

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.add(styles.hasInteracted);
      itemsContainerRef.current.classList.remove(styles.pointerDown);
    }

    updatePointerPosition(event);
    state.pointer.isDown = false;
  };

  const handlePointerMove = (event: React.PointerEvent) => {
    if (!event.isPrimary || state.selected) {
      return;
    }

    updatePointerPosition(event);
  };

  const handleChoice = (
    index: number,
    type: InteractionType,
    nextRouteIndex: number,
    nextRoute: ROUTES,
    navigateDelay: number
  ) => {
    const options = getInteractionOptions(type);
    const option = options[index];

    if (option) {
      updateHistory(option.points, type);

      event(EventCategories.MainExperience, "feed-calcifer", option.id);

      setTimeout(() => {
        setRouteIndex(nextRouteIndex);
        navigate(nextRoute);
      }, navigateDelay);
    } else {
      throw `Missing option for index ${index}`;
    }
  };

  useEffect(() => {
    let frameRequest: number;
    let lastTime = 0;

    // convert constants to screen space
    const nx = (x: number) => (x / 530) * state.bounds.width;
    const ny = (y: number) => (y / 550) * state.bounds.height;

    // main frame loop
    const onFrame = (time: number) => {
      // timing
      const delta = clamp(time - lastTime || 1000 / 60, 1, 1000 / 30);
      lastTime = time;

      state.timeBuffer += delta;

      // physics
      let updates = 0;

      while (state.timeBuffer >= state.timestep) {
        // stop simulation after selection
        if (state.selected) {
          break;
        }

        updatePhysics(state);

        state.timestamp += state.timestep;
        state.timeBuffer -= state.timestep;

        // selection state slowly increases near fire, decreases near carousel
        if (state.pointer.isDown && introCompleted) {
          state.select = clamp(state.select + (1 - state.select) * 0.2, 0, 1);
        } else {
          state.select = clamp(state.select + (0 - state.select) * 0.1, 0, 1);
        }

        updates += 1;

        if (updates > state.maxUpdates) {
          break;
        }
      }

      updateBounds();

      // wrap position infinitely
      const itemCount = itemRefs.length;
      const totalDistance = itemCount * state.itemGap;
      const wrapPosition = totalDistance + (state.position % totalDistance);

      // mouth control
      if (mouthRef.current && mouthOutlineRef.current) {
        const mouseYOffset =
          state.pointer.isDown && introCompleted
            ? (ny(440) - state.pointer.y) * 0.008
            : 0;

        const mouthWidthTarget =
          0.85 + state.select * 0.03 + mouseYOffset * 0.05;
        const mouthHeightTarget =
          0.5 + state.select * 0.08 + mouseYOffset * 0.34;

        state.mouthWidth += (mouthWidthTarget - state.mouthWidth) * 0.7;
        state.mouthHeight += (mouthHeightTarget - state.mouthHeight) * 0.2;

        state.mouthHeight = Math.min(state.mouthHeight, state.mouthWidth);

        const maskScale = 98;
        const outlineOffset = 0.018;
        const outlineRatio = 2;

        const mouthMaskOutlineSize = `${state.mouthWidth * maskScale}% ${
          state.mouthHeight * maskScale
        }%`;

        const mouthMaskSize = `${
          (state.mouthWidth - outlineOffset) * maskScale
        }% ${(state.mouthHeight - outlineOffset * outlineRatio) * maskScale}%`;

        mouthOutlineRef.current.style.webkitMaskSize = mouthMaskOutlineSize;
        mouthRef.current.style.webkitMaskSize = mouthMaskSize;
        mouthOutlineRef.current.style.maskSize = mouthMaskOutlineSize;
        mouthRef.current.style.maskSize = mouthMaskSize;
      }

      // eye control
      const eyeRadius = ny(4.8);
      const eyeSpeed = 0.3;

      const eyeCenterPulse =
        state.pointer.isDown || state.selected
          ? 1
          : max(
              0,
              sin(state.timestamp * 0.0005) *
                30 *
                sin(state.timestamp * 0.0005 + 1)
            );

      const f = (x: number) => x.toFixed(3);

      // left eye
      if (pupilLeftRef.current) {
        const eyeXTrack = (state.pointer.x - nx(250)) * 0.15;
        const eyeXFocus = clamp(state.pointer.y - ny(300), ny(-200), 0) * -0.1;
        const leftEyeOffset = state.leftEyeOffset;

        const eyeX = clamp(
          (state.pointer.y > ny(350) ? eyeXTrack : eyeXFocus) * eyeCenterPulse,
          -eyeRadius * 1.35,
          eyeRadius * 1.35
        );

        const eyeY = clamp(
          (state.pointer.y - ny(230)) * 0.05 * eyeCenterPulse,
          0,
          eyeRadius
        );

        leftEyeOffset.x += (eyeX - leftEyeOffset.x) * eyeSpeed;
        leftEyeOffset.y += (eyeY - leftEyeOffset.y) * eyeSpeed;

        const scale = state.selected
          ? 1.17
          : 0.05 +
            clamp(5.5 / Math.sqrt(leftEyeOffset.y * leftEyeOffset.y), 0.8, 1);

        pupilLeftRef.current.style.transform = `translate3d(${f(
          leftEyeOffset.x
        )}px, ${f(leftEyeOffset.y)}px, 0px) scale(${f(scale)})`;
      }

      // right eye
      if (pupilRightRef.current) {
        const eyeXTrack = (state.pointer.x - nx(250)) * 0.15;
        const eyeXFocus = clamp(state.pointer.y - ny(300), ny(-200), 0) * 0.1;
        const rightEyeOffset = state.rightEyeOffset;

        const eyeX = clamp(
          (state.pointer.y > ny(350) ? eyeXTrack : eyeXFocus) * eyeCenterPulse,
          -eyeRadius,
          eyeRadius
        );

        const eyeY = clamp(
          (state.pointer.y - ny(230)) * 0.05 * eyeCenterPulse,
          0,
          eyeRadius
        );

        rightEyeOffset.x += (eyeX - rightEyeOffset.x) * eyeSpeed;
        rightEyeOffset.y += (eyeY - rightEyeOffset.y) * eyeSpeed;

        const scale = state.selected
          ? 1.2
          : 0.05 +
            clamp(5.5 / Math.sqrt(rightEyeOffset.y * rightEyeOffset.y), 0.8, 1);

        pupilRightRef.current.style.transform = `translate3d(${f(
          rightEyeOffset.x
        )}px, ${f(rightEyeOffset.y)}px, 0px) scale(${f(scale)})`;
      }

      const centerOffset = totalDistance * 0.5 - state.itemGap * 0.5;

      // render items
      for (let i = 0; i < itemCount; i += 1) {
        const itemRef = itemRefs[i];
        const shadowRef = shadowRefs[i];
        const imageRef = imageRefs[i];

        if (!itemRef.current || !shadowRef.current || !imageRef.current) {
          continue;
        }

        // find screen relative position
        const itemOffset = i * state.itemGap;

        // position in carousel space
        const position =
          ((itemOffset + wrapPosition + 0.1) % totalDistance) - centerOffset;

        const offset = position + 0;

        // item moves forwards backwards in world space based on pointer y
        const itemZFactor = 1.5 + abs(offset * offset * offset * 10);
        let itemZTarget = 0;

        const dy =
          state.pointer.isDown && introCompleted
            ? (ny(440) - state.pointer.y) * 1.4
            : 0;

        if (!state.selected) {
          itemZTarget = clamp(dy / itemZFactor, 0, ny(190));
        } else {
          itemZTarget = clamp(ny(380) / itemZFactor, 0, ny(160));
        }

        if (
          introCompleted &&
          state.pointer.isDown &&
          state.select > state.selectThreshold &&
          state.pointer.y < ny(320)
        ) {
          setShowChoice(true);

          const isInsideFire = itemZFactor < 1.8;
          if (isInsideFire) {
            itemRef.current.classList.add(styles.isSelected);
          }

          if (!state.selected) {
            state.selected = true;

            handleChoice(
              state.index,
              InteractionType.FEED_CALCIFER,
              6,
              ROUTES.YOUR_STAR_DEMON,
              3750
            );
          }
        }

        // tween item z position
        state.items[i].z +=
          (itemZTarget - state.items[i].z) *
          (state.pointer.isDown && !state.selected ? 0.15 : 0.05);

        const itemZ = state.items[i].z;

        // item moves up and down in world space based on carousel position
        const itemY = ny(55 - abs(offset) * 25);
        const itemX = 0;

        // item rotates as item moves forwards backwards in world space
        const itemAngle = clamp(
          (itemZ * 0.007) / (1 + 0.5 * abs(offset)),
          0,
          0.5
        );

        // item scales as item moves forwards backwards in world space
        const scalePerspective = max(ny(0.0018 * -itemZ), -0.5);

        // item scale grows at center of carousel
        const scaleCarousel =
          0.6 * clamp(abs(0.6 / (0.1 * offset * offset)), 0, 2);

        // item scale shrinks as it gets selected
        const scaleSelect = state.select * 0.0011 * itemZ;

        // final scale
        const screenScale =
          0.5 + scalePerspective + scaleCarousel - scaleSelect;

        // item on carousel moves on horizontal path
        const height = state.bounds.height;
        const translateX = height * 0.14 * -position + itemX;
        const translateY = position + ny(150) - itemY - itemZ;

        // drop shadow effect with faux fixed light
        const shadowX = offset * -3 + sin(itemAngle) * 100;
        const shadowY = max(8, 2 + abs(offset) * 2 + itemY * 0.8);

        const shadowBlur =
          max(0.18 - offset * offset, 0.1) + itemZ * itemZ * 0.000005;

        const shadow = max(
          0.235 / (1 + abs((offset - 1) * 0.3)) - itemZ * itemZ * 0.000004,
          0
        );

        // brightness based on item distance to fire and carousel position
        const lightCarousel = clamp(0.5 / abs(offset * 0.27), 0.2, 1.2);
        const lightSelect = itemZ * itemZ * 0.001 * state.select;

        // final lighting and shading
        const finalBrightness = clamp(lightCarousel + lightSelect, 0, 1.3);

        // glow pulsing over time
        const glowPulse = isSafari
          ? 0
          : sin(state.timestamp * 0.0015) *
            1.5 *
            sin(1 + state.timestamp * 0.0015) *
            2;

        // glow based on carousel position
        const finalGlow =
          (1.2 + 0.4 * glowPulse) / (1.5 + abs(offset * offset * offset * 10));

        // apply transforms and effects
        const style = itemRef.current.style;
        const imageStyle = imageRef.current.style;

        // perf: fade away items towards the edges
        style.opacity = `${f(
          clamp(abs(15 / (0.3 * offset * offset)) - 2, 0, 1)
        )}`;

        style.transform = `translate3d(${f(translateX)}px, ${f(
          translateY
        )}px, 0px) scale(${f(screenScale)}) rotate(${f(itemAngle)}rad)`;

        // perf: only update item filter if it is in view
        if (offset > -3 && offset < 3) {
          const glowFilter =
            finalGlow > 0
              ? `drop-shadow(0px 0px ${f(finalGlow)}vh orange)`
              : "";

          shadowRef.current.style.filter = `drop-shadow(${f(shadowX)}px ${f(
            shadowY
          )}px ${f(shadowBlur)}vh rgba(0,0,0,${f(shadow)}))`;

          imageStyle.filter = `brightness(${f(finalBrightness)}) ${glowFilter}`;
        }
      }

      // get unwrapped index in rendered order from position
      const renderedIndex = round(
        snap(wrapPosition + centerOffset, state.itemGap) / state.itemGap
      );

      // get wrapped index in InteractionOption order
      const index = itemCount - 1 - (renderedIndex % itemCount);

      // callback when item index changed
      if (state.index !== index) {
        state.index = index;
      }

      // only render further frames after intro animation
      if (introCompleted) {
        frameRequest = requestAnimationFrame(onFrame);
      }
    };

    // start the frame loop
    frameRequest = requestAnimationFrame(onFrame);

    return () => {
      cancelAnimationFrame(frameRequest);
    };
  });

  return (
    <div
      ref={itemsContainerRef}
      className={cn(
        styles.itemsContainer,
        showChoice ? styles.showChoice : "",
        introCompleted ? styles.introCompleted : ""
      )}
      onPointerMove={handlePointerMove}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerOut={handlePointerUp}
    >
      <img
        className={styles.backgroundImage}
        src={backgroundAsset}
        role="presentation"
        alt=""
      />
      <img
        className={styles.selectOverlay}
        src={selectOverlayAsset}
        role="presentation"
        alt=""
      />
      <img
        className={styles.selectOverlaySparkle}
        src={selectOverlaySparkleAsset}
        role="presentation"
        alt=""
      />
      <div ref={pupilLeftRef} className={styles.pupilLeft} />
      <div ref={pupilRightRef} className={styles.pupilRight} />
      <span
        className={styles.instructionText}
        dangerouslySetInnerHTML={{ __html: t("instructions.drag-to-feed") }}
      />
      <div ref={mouthOutlineRef} className={styles.calciferMouthOutline} />
      <img
        ref={mouthRef}
        className={styles.calciferMouth}
        src={mouthAsset}
        role="presentation"
        alt=""
      />
      {itemRefs.map((ref, index) => (
        <div key={index} ref={ref} className={styles.item}>
          <img
            ref={imageRefs[index]}
            className={styles.itemImage}
            src={itemAssets[index]}
            role="presentation"
            alt=""
          />
          <img
            ref={shadowRefs[index]}
            className={styles.itemShadow}
            src={itemAssets[index]}
            role="presentation"
            alt=""
          />
        </div>
      ))}
    </div>
  );
};
