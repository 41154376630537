// language=glsl
export const vertexShader = `
  attribute vec2 aVertexPosition;

  uniform mat3 projectionMatrix;

  varying vec2 vTextureCoord;

  uniform vec4 inputSize;
  uniform vec4 outputFrame;

  vec4 filterVertexPosition(void) {
    vec2 position = aVertexPosition * max(outputFrame.zw, vec2(0.)) + outputFrame.xy;
    return vec4((projectionMatrix * vec3(position, 1.0)).xy, 0.0, 1.0);
  }

  vec2 filterTextureCoord(void) {
    return aVertexPosition * (outputFrame.zw * inputSize.zw);
  }

  void main(void) {
    gl_Position = filterVertexPosition();
    vTextureCoord = filterTextureCoord();
  }
`;

// language=glsl
export const fragmentShader = `
  varying vec2 vTextureCoord;

  uniform sampler2D uSampler;

  uniform vec4 filterArea;
  uniform vec4 filterClamp;

  uniform vec2 dimensions;
  uniform float uStrength;

  vec4 sharpen(in sampler2D tex, in vec2 coord, in vec2 bufferDimensions) {
    float dx = 1.0 / bufferDimensions.x;
    float dy = 1.0 / bufferDimensions.y;
    vec4 sum = 5. * texture2D(tex, coord);
    sum += -1. * texture2D(tex, coord + vec2(-1.0 * dx, 0.0));
    sum += -1. * texture2D(tex, coord + vec2(1.0 * dx, 0.0));
    sum += -1. * texture2D(tex, coord + vec2(0.0, -1.0 * dy));
    sum += -1. * texture2D(tex, coord + vec2(0.0, 1.0 * dy));

    return sum;
  }

  void main() {
    vec4 tex = texture2D(uSampler, vTextureCoord);
    vec4 color = sharpen(uSampler, vTextureCoord, dimensions);
    color = mix(tex, color, uStrength);
    gl_FragColor = color;
  }
`;
