import { BLEND_MODES, Sprite, Texture } from "pixi.js";
import { STAR_RAY_SCALE } from "../../../constants";
import { STAR_VIDEO } from "../../../resources";
import { PathTracer } from "../../path-tracer";
import { Particles } from "../particles";
import { domPointsToPixiPoints } from "../utilities/dom-points-to-pixi-points";
import { Trail } from "./trail";

const TRAIL_RANDOMNESS = 8;

export class StarVisual {
  readonly trails: Trail[] = [];
  readonly ray = new Sprite(STAR_VIDEO);
  readonly particles = new Particles();

  constructor(
    readonly tracer: PathTracer,
    trailTexture: Texture,
    colors: number[]
  ) {
    this.ray.anchor.set(0.5, 0.5);
    // Adjust scale of the rays for some visual variety.
    const [min, max] = STAR_RAY_SCALE;
    this.ray.scale.set(min + Math.random() * (max - min));
    // Random rotation.
    this.ray.rotation = Math.random() * Math.PI * 2;
    this.ray.blendMode = BLEND_MODES.ADD;

    const trailPoints = domPointsToPixiPoints(tracer.points);
    for (const color of colors) {
      const trail = new Trail(trailPoints, trailTexture);
      trail.mesh.position.set(tracer.offset.x, tracer.offset.y);
      trail.mesh.tint = color;

      if (this.trails.length) {
        // trail.mesh.scale.x = 2;
        trail.mesh.x += Math.random() * TRAIL_RANDOMNESS - TRAIL_RANDOMNESS / 2;
      }

      this.trails.push(trail);
    }
  }

  update() {
    for (let i = 0; i < this.trails.length; i++) {
      const trail = this.trails[i];
      trail.mesh.material.uniforms.uTime += 1.0;
      trail.mesh.material.uniforms.uProgress = this.tracer.progress;
    }
  }
}
