/**
 * @fileoverview
 *
 * PIXI particle config for the cursor.
 * @see {@link http://pixijs.io/particle-emitter/docs/}
 */

import { BehaviorEntry, EmitterConfigV3 } from "@pixi/particle-emitter";
import { Texture } from "pixi.js";
import particle from "../../../resources/textures/circle.png";

const MOVE_SPEED: BehaviorEntry = {
  type: "moveSpeed",
  config: {
    speed: {
      list: [
        { time: 0, value: 1 },
        { time: 1, value: 1024 },
      ],
    },
  },
};

const ALPHA: BehaviorEntry = {
  type: "alpha",
  config: {
    alpha: {
      list: [
        { time: 0, value: 1 },
        { time: 1, value: 0 },
      ],
    },
  },
};

const SCALE: BehaviorEntry = {
  type: "scale",
  config: {
    scale: {
      list: [
        { time: 0, value: 0.05 },
        { time: 1, value: 0.2 },
      ],
    },
  },
};

const ROTATION: BehaviorEntry = {
  type: "rotationStatic",
  config: {
    min: 0,
    max: 360,
  },
};

const COLOR: BehaviorEntry = {
  type: "color",
  config: {
    color: {
      list: [
        { time: 0, value: "#ff0000" },
        { time: 1, value: "#0066ff" },
      ],
    },
  },
};

const BLEND_MODE: BehaviorEntry = {
  type: "blendMode",
  config: { blendMode: "add" },
};

const TEXTURE: BehaviorEntry = {
  type: "textureRandom",
  config: { textures: [Texture.from(particle)] },
};

const BASE_CONFIG = {
  lifetime: { min: 0.2, max: 0.8 },
  frequency: 0.001,
  emitterLifetime: -1,
  maxParticles: 256,
  pos: { x: -100, y: -100 },
};

export const CONFIG: EmitterConfigV3 = {
  ...BASE_CONFIG,
  behaviors: [MOVE_SPEED, ROTATION, ALPHA, SCALE, COLOR, BLEND_MODE, TEXTURE],
};
