import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const url = new URL(window.location.href);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: url.hostname.includes("com.cn") ? "zh" : "en",
    debug: process.env.NODE_ENV === "production" ? false : true,

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    lowerCaseLng: true,
  });

export default i18n;
