import characters from "../assets/images/homepage/characters.webp";
import cloudBottom from "../assets/images/homepage/cloud-bottom.webp";
import cloudLeft from "../assets/images/homepage/cloud-left.webp";
import cloudRight from "../assets/images/homepage/cloud-right.webp";
import doorFill from "../assets/images/homepage/door-fill.webp";
import door from "../assets/images/homepage/door.webp";
import doorOverlayAsset from "../assets/images/door-dial/door-overlay.webp";
import howlsGarden from "../assets/images/door-dial/howls-garden.webp";
import kingsbury from "../assets/images/door-dial/kingsbury.webp";
import marketChipping from "../assets/images/door-dial/market-chipping.webp";
import theWaste from "../assets/images/door-dial/the-waste.webp";
import windowGlass from "../assets/images/door-dial/window-glass.svg";
import windowClouds from "../assets/images/door-dial/window-clouds.webp";
import textureOverlay from "../assets/images/door-dial/texture-overlay.webp";
import howlsGardenOverlay from "../assets/images/door-dial/howls-garden-overlay.webp";
import kingsburyOverlay from "../assets/images/door-dial/kingsbury-overlay.webp";
import marketChippingOverlay from "../assets/images/door-dial/market-chipping-overlay.webp";
import theWasteOverlay from "../assets/images/door-dial/the-waste-overlay.webp";
import doorDialBackground from "../assets/images/door-dial/background.webp";
import doorDialCursor from "../assets/images/door-dial/cursor.webp";
import doorStroke from "../assets/images/door-dial/door-stroke.svg";
import doorOverlay from "../assets/images/door-dial/door-overlay.webp";
import dialDiffuse from "../assets/images/door-dial/dial-diffuse.webp";
import dialShadow from "../assets/images/door-dial/dial-shadow.webp";
import dialSpecular from "../assets/images/door-dial/dial-specular.webp";
import dialArrow from "../assets/images/door-dial/dial-arrow.webp";
import dialArrowBehind from "../assets/images/door-dial/dial-arrow-behind.webp";
import dialArrowShadow from "../assets/images/door-dial/dial-arrow-shadow.webp";
import dialMask from "../assets/images/door-dial/door-mask.webp";
import hat1 from "../assets/images/which-hat/item-1.webp";
import hat2 from "../assets/images/which-hat/item-2.webp";
import hat3 from "../assets/images/which-hat/item-3.webp";
import hat4 from "../assets/images/which-hat/item-4.webp";
import hat5 from "../assets/images/which-hat/item-5.webp";
import hat6 from "../assets/images/which-hat/item-6.webp";
import hat7 from "../assets/images/which-hat/item-7.webp";
import whichHatBackground from "../assets/images/which-hat/background.webp";
import chooseYourSpellBackground from "../components/ChooseYourSpellGame/game/resources/textures/background.webp";
import hideAmongThingsBackground from "../components/HidingAmongThingsGame/game/resources/textures/diffuse.webp";
import selectOverlay from "../assets/images/which-hat/select-overlay.webp";
import wood from "../assets/images/feed-calcifer/wood.webp";
import wood2 from "../assets/images/feed-calcifer/wood-2.webp";
import bacon from "../assets/images/feed-calcifer/bacon.webp";
import egg from "../assets/images/feed-calcifer/egg.webp";
import plait from "../assets/images/feed-calcifer/plait.webp";
import calciferBackground from "../assets/images/feed-calcifer/background.webp";
import calciferSelectOverlay from "../assets/images/feed-calcifer/select-overlay.webp";
import calciferSparkle from "../assets/images/feed-calcifer/select-overlay-sparkle.webp";
import leftPupil from "../assets/images/feed-calcifer/left-pupil.png";
import rightPupil from "../assets/images/feed-calcifer/right-pupil.png";
import mouth from "../assets/images/feed-calcifer/mouth.webp";
import mouth2 from "../assets/images/feed-calcifer/mouth-2.webp";
import sophie2x from "../assets/images/star-demon/sophie-2x.webp";
import sophie from "../assets/images/star-demon/sophie.webp";
import background from "../assets/images/reveal/background.webp";
import cursor from "../assets/images/reveal/cursor.webp";
import revealDoorPanel from "../assets/images/reveal/door-panel.webp";
import revealDoor from "../assets/images/reveal/door.webp";
import revealForeground from "../assets/images/reveal/reveal-foreground.webp";
import rewardBackground from "../assets/images/rewards-screen/background.webp";
import rewardForeground from "../assets/images/rewards-screen/foreground.webp";

export const assets = [
  characters,
  cloudBottom,
  cloudLeft,
  cloudRight,
  doorFill,
  door,
  howlsGarden,
  kingsbury,
  marketChipping,
  theWaste,
  windowGlass,
  windowClouds,
  textureOverlay,
  howlsGardenOverlay,
  kingsburyOverlay,
  marketChippingOverlay,
  theWasteOverlay,
  doorDialBackground,
  doorDialCursor,
  doorStroke,
  doorOverlay,
  doorOverlayAsset,
  dialDiffuse,
  dialShadow,
  dialSpecular,
  dialArrow,
  dialArrowBehind,
  dialArrowShadow,
  dialMask,
  chooseYourSpellBackground,
  hideAmongThingsBackground,
  hat1,
  hat2,
  hat3,
  hat4,
  hat5,
  hat6,
  hat7,
  whichHatBackground,
  selectOverlay,
  wood,
  wood2,
  bacon,
  egg,
  plait,
  calciferBackground,
  calciferSelectOverlay,
  calciferSparkle,
  leftPupil,
  rightPupil,
  mouth,
  mouth2,
  sophie2x,
  sophie,
  background,
  cursor,
  revealDoorPanel,
  revealDoor,
  revealForeground,
  rewardBackground,
  rewardForeground,
  // // Instruction gifs.
  // "./assets/images/instructions/choose-a-spell.gif",
  // "./assets/images/instructions/feed-calcifer.gif",
  // "./assets/images/instructions/hide-among-things.gif",
  // "./assets/images/instructions/which-hat.gif",
  // // Wallpapers.
  // "./assets/images/wallpapers/calcifer-1.jpg",
  // "./assets/images/wallpapers/calcifer-2.jpg",
  // "./assets/images/wallpapers/castle-1.jpg",
  // "./assets/images/wallpapers/castle-2.jpg",
  // "./assets/images/wallpapers/heen-1.jpg",
  // "./assets/images/wallpapers/heen-2.jpg",
  // "./assets/images/wallpapers/howl-1.jpg",
  // "./assets/images/wallpapers/howl-2.jpg",
  // "./assets/images/wallpapers/markl-1.jpg",
  // "./assets/images/wallpapers/markl-2.jpg",
  // "./assets/images/wallpapers/sophie-1.jpg",
  // "./assets/images/wallpapers/sophie-2.jpg",
  // "./assets/images/wallpapers/turnip-head-1.jpg",
  // "./assets/images/wallpapers/turnip-head-2.jpg",
  // "./assets/images/wallpapers/witch-1.jpg",
  // "./assets/images/wallpapers/witch-2.jpg",
  // // Characters.
  // "./assets/images/characters/calcifer.webp",
  // "./assets/images/characters/calcifer-bg.webp",
  // "./assets/images/characters/calcifer-foreground.webp",
  // "./assets/images/characters/castle.webp",
  // "./assets/images/characters/castle-bg.webp",
  // "./assets/images/characters/castle-foreground.webp",
  // "./assets/images/characters/heen.webp",
  // "./assets/images/characters/heen-bg.webp",
  // "./assets/images/characters/heen-foreground.webp",
  // "./assets/images/characters/howl.webp",
  // "./assets/images/characters/howl-bg.webp",
  // "./assets/images/characters/howl-foreground.webp",
  // "./assets/images/characters/howl-foreground-1.webp",
  // "./assets/images/characters/markl.webp",
  // "./assets/images/characters/markl-bg.webp",
  // "./assets/images/characters/markl-foreground.webp",
  // "./assets/images/characters/rune.webp",
  // "./assets/images/characters/rune-bg.webp",
  // "./assets/images/characters/rune-foreground.webp",
  // "./assets/images/characters/sophie.webp",
  // "./assets/images/characters/sophie-bg.webp",
  // "./assets/images/characters/sophie-foreground.webp",
  // "./assets/images/characters/turnip-head.webp",
  // "./assets/images/characters/turnip-head-bg.webp",
  // "./assets/images/characters/turnip-head-foreground.webp",
  // "./assets/images/characters/witch.webp",
  // "./assets/images/characters/witch-bg.webp",
  // "./assets/images/characters/witch-foreground.webp",
];
