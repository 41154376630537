import { clamp, snap, angleDifference } from "./math";
import { DoorDialState } from "./DoorDialGame";

const { abs, atan2, sqrt, min, PI } = Math;

// main physics update
export const updatePhysics = (state: DoorDialState) => {
  const { pointer, bounds, timestep } = state;

  // angular physics properties
  const angularVelocity = clamp(state.angle - state.anglePrev, -1, 1);

  state.angularSpeed = abs(angularVelocity);
  const angularDirection = angularVelocity > 0 ? 1 : -1;

  const friction =
    -angularDirection * min(0.07 * state.angularSpeed, 0.4 * timestep);

  // angular integration
  state.anglePrev = state.angle;
  state.angle = state.angle + angularVelocity + friction;

  const pointerVelocityX = pointer.x - pointer.xPrev;
  const pointerVelocityY = pointer.y - pointer.yPrev;

  // track pointer speed
  const pointerSpeed = sqrt(
    pointerVelocityX * pointerVelocityX + pointerVelocityY * pointerVelocityY
  );

  pointer.xPrev = pointer.x;
  pointer.yPrev = pointer.y;

  // when user is moving the dial
  if (pointer.isDown && pointerSpeed > 0.5) {
    const angle = atan2(
      pointer.y - bounds.height * 0.5,
      pointer.x - bounds.width * 0.5
    );

    const angleDelta = angleDifference(angle, pointer.startAngle);

    // rotate the dial to follow pointer
    state.angle += angleDelta * 0.22;

    pointer.startAngle = angle;
  }

  // apply snapping to nearest quadrant when rotation stops
  if (pointer.isDown && (pointerSpeed > 0.01 || state.angularSpeed > 0.01)) {
    state.stopped = false;
  } else {
    if (state.angularSpeed < 0.01) {
      state.stopped = true;
    }

    if (state.stopped) {
      // shortest angle between current angle and nearest quadrant angle
      const deltaAngle = angleDifference(
        state.angle,
        snap(state.angle, 0.5 * PI)
      );

      // snapping impulse
      state.anglePrev += deltaAngle * 0.1;
    }
  }
};
