import gsap from "gsap";
import { Assets, Sprite, Text, Texture } from "pixi.js";
import { MinimalContainer } from "../../../../../StarDemonGame/core/utilities/minimal-container";
import {
  RUNE_EASE,
  RUNE_GLOW_DURATION,
  RUNE_GLOW_EASE,
  RUNE_TEXT_EASE,
  RUNE_TEXT_HIDE_DELAY,
  RUNE_TEXT_VISIBILITY_DURATION,
  RUNE_VISIBILITY_DURATION,
  SKEW,
} from "../../../constants";
import { RuneData } from "../../../data";
import { RuneShader } from "../../../resources/programs/rune-shader";
import { Particles } from "./particles";
import { text } from "./text";

const MAX_PROGRESS = 1;

async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class Rune extends MinimalContainer {
  readonly text: Text;
  readonly sprite: Sprite;
  readonly revealShader = new RuneShader();
  readonly timeline = gsap.timeline();
  particles: Particles | null = null;
  disposed = false;

  constructor(readonly data: RuneData, word: string) {
    super(true);
    this.text = text(word);

    this.sprite = new Sprite(Assets.get(data.texturePath) as Texture);
    this.sprite.scale.set(0.35);
    this.sprite.alpha = 0;

    this.text.y = this.sprite.height / 2 - this.text.height / 2;
    this.text.x = this.sprite.width / 2 - this.text.width / 2;

    this.addChild(this.text, this.sprite);

    // Skew the entire container.
    this.skew.set(SKEW[0], SKEW[1]);
    this.position.set(data.position.x + 64, data.position.y);

    this.filters = [this.revealShader];
  }

  async playText() {
    this.revealShader.uniforms.uProgress = 0;
    this.sprite.alpha = 0;
    this.text.alpha = 1;

    this.timeline.clear();

    if (this.particles) {
      this.timeline.set(this.particles.smoke, {
        spawnChance: 0,
        autoUpdate: true,
      });
    }

    this.timeline.to(this.revealShader.uniforms, {
      uProgress: 0.6,
      ease: RUNE_TEXT_EASE,
      duration: RUNE_TEXT_VISIBILITY_DURATION.REVEAL,
    });

    await wait(RUNE_TEXT_HIDE_DELAY);

    if (this.particles) {
      this.timeline.fromTo(
        this.particles.smoke,
        { spawnChance: 0.2 },
        { spawnChance: 0 }
      );
    }

    this.timeline.to(this.revealShader.uniforms, {
      uProgress: 0,
      ease: RUNE_TEXT_EASE,
      duration: RUNE_TEXT_VISIBILITY_DURATION.HIDE,
    });

    await this.timeline;

    await wait(1500);

    this.text.alpha = 0;
  }

  async runeSelectedAnimation() {
    this.text.alpha = 0;
    this.sprite.alpha = 1;

    this.timeline.clear();

    if (this.particles) {
      this.timeline.set([this.particles.smoke, this.particles.sparks], {
        spawnChance: 0,
        autoUpdate: true,
      });

      this.timeline.to(
        this.particles.smoke,
        {
          spawnChance: 1,
          delay: RUNE_GLOW_DURATION / 4,
          duration: RUNE_GLOW_DURATION,
        },
        "a"
      );

      this.timeline.to(this.particles.sparks, { spawnChance: 1 }, "a");
      this.timeline.to(this.particles.sparks, { spawnChance: 0 });
      this.timeline.to(this.particles.smoke, { spawnChance: 0, duration: 1.5 });
    }

    this.timeline.to(
      this.revealShader.uniforms,
      {
        uGlow: 1,
        ease: RUNE_GLOW_EASE,
        duration: RUNE_GLOW_DURATION,
      },
      "-=90%"
    );

    this.timeline.to(
      this.revealShader.uniforms,
      {
        uGlow: 0,
        ease: RUNE_EASE,
        duration: RUNE_VISIBILITY_DURATION.HIDE,
      },
      "-=100%"
    );

    this.timeline.to(
      this.revealShader.uniforms,
      {
        uProgress: 0,
        ease: RUNE_EASE,
        duration: RUNE_VISIBILITY_DURATION.HIDE,
      },
      "-=130%"
    );

    return this.timeline;
  }

  async toggleRune(flag: boolean) {
    this.text.alpha = 0;
    this.sprite.alpha = 1;

    this.timeline.clear();

    const uProgress = flag ? MAX_PROGRESS : 0;
    const ease = RUNE_EASE;
    const duration = flag
      ? RUNE_VISIBILITY_DURATION.REVEAL
      : RUNE_VISIBILITY_DURATION.HIDE;

    await this.timeline.to(this.revealShader.uniforms, {
      uProgress,
      ease,
      duration,
    });
  }

  async glow(progress = 1) {
    this.sprite.alpha = 1;

    this.timeline.clear();

    if (this.particles) {
      this.timeline.set([this.particles.smoke, this.particles.sparks], {
        spawnChance: 0,
        autoUpdate: true,
      });

      this.timeline.to(
        [this.particles.smoke, this.particles.sparks],
        {
          spawnChance: 1,
          yoyo: true,
          delay: RUNE_GLOW_DURATION / 4,
          duration: RUNE_GLOW_DURATION,
        },
        "a"
      );
    }

    await this.timeline.to(
      this.revealShader.uniforms,
      {
        uGlow: progress,
        ease: RUNE_GLOW_EASE,
        duration: RUNE_GLOW_DURATION,
      },
      "a"
    );
  }

  dispose() {
    this.disposed = true;
    this.timeline.kill();
    if (this.particles) {
      this.particles.smoke.emit = false;
      this.particles.smoke.autoUpdate = false;
      this.particles.smoke.destroy();
      this.particles.destroy();
    }
    gsap.killTweensOf(this.revealShader.uniforms);
    this.revealShader.destroy();
    this.text.destroy();
    this.sprite.destroy();
  }
}
