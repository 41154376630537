// Sharpen amount.
export const SHARPEN_STRENGTH = 0.2;
export const SHARPEN_STRENGTH_RUNE = 0.45;

// Skew [x, y].
export const SKEW = [-0.6, 0.25];

// Duration in seconds.
export const RUNE_TEXT_VISIBILITY_DURATION = {
  REVEAL: 5,
  HIDE: 1.5,
};

// in ms.
export const RUNE_TEXT_HIDE_DELAY = 8000;

// Duration in seconds.
export const RUNE_VISIBILITY_DURATION = {
  REVEAL: 1.5,
  HIDE: 2,
};

export const RUNE_GLOW_DURATION = 1.25;

export const RUNE_EASE = "Power1.easeOut";
export const RUNE_TEXT_EASE = "Power1.easeInOut";
export const RUNE_GLOW_EASE = "Power3.easeOut";
