import {
  CLEAR_MODES,
  Filter,
  FilterSystem,
  Matrix,
  RenderTexture,
  Sprite,
} from "pixi.js";
import { LENS_FILTER_INTENSITY } from "../../../../constants";
import { fragmentShader, vertexShader } from "./shader.glsl";

export class DisplacementFilter extends Filter {
  readonly matrix = new Matrix();

  constructor(readonly sprite: Sprite) {
    const uniforms = {
      mapSampler: sprite.texture,
      filterMatrix: new Matrix(),
      distortion: LENS_FILTER_INTENSITY,
    };
    sprite.renderable = false;

    super(vertexShader, fragmentShader, uniforms);

    this.sprite = sprite;
  }

  private _distortion = LENS_FILTER_INTENSITY;

  set distortion(distortion: number) {
    this._distortion = distortion;
    this.uniforms.distortion = distortion;
  }

  get size() {
    return this.sprite.width;
  }

  set size(size: number) {
    const clampedSize = Math.max(size, 1);
    this.sprite.width = clampedSize;
    this.sprite.height = clampedSize;

    // Hack to prevent precision artefacts.
    if (size < 16) {
      this.uniforms.distortion = this._distortion / 16;
    } else {
      this.distortion = this._distortion;
    }
  }

  apply(
    manager: FilterSystem,
    input: RenderTexture,
    output: RenderTexture,
    clearMode: CLEAR_MODES
  ): void {
    super.apply(manager, input, output, clearMode);
    this.uniforms.filterMatrix = manager.calculateSpriteMatrix(
      this.matrix,
      this.sprite
    );

    manager.applyFilter(this, input, output, clearMode);
  }
}
