// import ReactGA from "react-ga4";
import ReactGA from "react-ga";

export enum EventCategories {
  "MainExperience" = "Main experience",
  "Landing" = "Landing",
  "Navigation" = "Navigation",
}

export enum EventActions {
  "Tap" = "Tap",
  "Select" = "Select",
  "Back" = "Back",
  "Skip" = "Skip",
}

export const useAnalyticsEvent = () => {
  const event = (category: string, action: string, label: string) => {
    // ReactGA.event({ category: category, action: action, label: label });
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      nonInteraction: false,
    });
  };
  return event;
};
