export enum ROUTES {
  HOME = "/home",
  THE_DOOR_DIAL = "/the-door-dial",
  WHICH_HAT = "/which-hat",
  CHOOSE_A_SPELL = "/choose-a-spell",
  HIDE_AMONG_THINGS = "/hide-among-things",
  FEED_CALCIFER = "/feed-calcifer",
  YOUR_STAR_DEMON = "/your-star-demon",
  CHARACTER_REVEAL = "/character-reveal",
  REWARDS = "/rewards",
}
