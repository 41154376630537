import { clamp, snap } from "./math";
import { RewardsCarouselState } from "./index";

const { abs, min, sqrt } = Math;

// main physics update
export const updatePhysics = (state: RewardsCarouselState) => {
  const { pointer, timestep } = state;

  // physics properties
  const velocity = clamp(state.position - state.positionPrev, -0.3, 0.3);
  state.speed = abs(velocity);

  const direction = velocity > 0 ? 1 : -1;
  const friction = -direction * min(0.1 * state.speed, 0.15 * timestep);

  // physics integration
  state.positionPrev = state.position;
  state.position = state.position + velocity + friction * state.friction;

  // track pointer speed
  let pointerVelocityX = pointer.x - pointer.xPrev;
  const pointerVelocityY = pointer.y - pointer.yPrev;

  pointer.xPrev = pointer.x;
  pointer.yPrev = pointer.y;

  const pointerSpeed = sqrt(
    pointerVelocityX * pointerVelocityX + pointerVelocityY * pointerVelocityY
  );

  const pointerDeltaX = pointer.x - state.bounds.width * 0.5;

  // when pointer is held down near left or right edge
  const isEdgeMoving =
    pointer.isDown &&
    pointerSpeed < 0.01 &&
    abs(pointerDeltaX) > state.bounds.width * 0.25;

  // apply constant speed in the touched edge direction
  if (isEdgeMoving) {
    pointerVelocityX =
      (0.45 * clamp(pointer.x - state.bounds.width * 0.5, -1, 1)) /
      (1 + 15 * pointerSpeed);
  }

  // when user is moving pointer
  if (pointer.isDown && (pointerSpeed > 1 || isEdgeMoving)) {
    const trackDirectionX = 0.5;
    const trackDirectionY = 0.5;

    const positionDelta =
      pointerVelocityX * trackDirectionX - pointerVelocityY * trackDirectionY;

    // move to follow pointer
    state.position += -positionDelta * 0.1;
  }

  // apply snapping to nearest item
  if (state.snap) {
    if (pointer.isDown) {
      state.stopped = false;
    } else {
      if (state.speed < 1) {
        state.stopped = true;
      }

      if (state.stopped) {
        // distance between current position and nearest item
        const snapDelta = state.position - snap(state.position, state.itemGap);

        // snapping impulse
        state.positionPrev += snapDelta * 0.01;
      }
    }
  }
};
