export type LanguageType = {
  code: string;
  name: string;
};

// Each language code matches those in frontend/public/locales
export const LANGUAGES: Array<LanguageType> = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "es",
    name: "Español",
  },
  {
    code: "ja",
    name: "日本語",
  },
  {
    code: "ko",
    name: "한국어",
  },
  {
    code: "zh",
    name: "简体中文",
  },
  {
    code: "zh-tw",
    name: "繁體中文",
  },
];
