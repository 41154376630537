import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";

import styles from "./index.module.css";

import {
  getInteractionOptions,
  InteractionType,
} from "../../config/interactions";

import { useInteractionStore } from "../../store/interactionStore";

import { ROUTES } from "../../constants/routes";

import { clamp, snap } from "./math";
import { updatePhysics } from "./physics";

import itemAsset1 from "../../assets/images/which-hat/item-1.webp";
import itemAsset2 from "../../assets/images/which-hat/item-2.webp";
import itemAsset3 from "../../assets/images/which-hat/item-3.webp";
import itemAsset4 from "../../assets/images/which-hat/item-4.webp";
import itemAsset5 from "../../assets/images/which-hat/item-5.webp";
import itemAsset6 from "../../assets/images/which-hat/item-6.webp";
import itemAsset7 from "../../assets/images/which-hat/item-7.webp";

import backgroundAsset from "../../assets/images/which-hat/background.webp";
import selectOverlayAsset from "../../assets/images/which-hat/select-overlay.webp";

const { abs, cos, sin, PI, round, sqrt } = Math;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

interface WhichHatGameProps {
  introCompleted: boolean;
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
}

export interface WhichHatState {
  timestamp: number;
  timestep: number;
  maxUpdates: number;
  index: number;
  bounds: DOMRect;
  positionPrev: number;
  position: number;
  speed: number;
  snap: boolean;
  friction: number;
  itemGap: number;
  hasChanged: boolean;
  items: Array<{
    asset: {
      path: string;
      transform: {
        scale: number;
        angle: number;
      };
    };
  }>;
  select: number;
  selectThreshold: number;
  selected: boolean;
  stopped: boolean;
  timeBuffer: number;
  pointer: {
    isDown: boolean;
    xPrev: number;
    yPrev: number;
    x: number;
    y: number;
  };
}

export const WhichHatGame = ({
  introCompleted = false,
  setRouteIndex,
}: WhichHatGameProps) => {
  const { t } = useTranslation();
  const { updateHistory } = useInteractionStore();
  const navigate = useNavigate();
  const event = useAnalyticsEvent();

  const itemsContainerRef = useRef<HTMLDivElement>(null);

  // internal real-time state (performance: no setState)
  const [state] = useState<WhichHatState>(() => {
    return {
      timestamp: 0,
      timestep: 1000 / 60,
      maxUpdates: 4,
      index: -1,
      bounds: new DOMRect(),
      positionPrev: 6,
      position: 6,
      speed: 0,
      snap: false,
      friction: 0,
      select: 0,
      selectThreshold: 0.93,
      selected: false,
      stopped: false,
      timeBuffer: 0,
      skipped: 0,
      pointer: {
        isDown: false,
        xPrev: 0,
        yPrev: 0,
        x: 0,
        y: 0,
      },
      itemGap: PI * 1.4,
      hasChanged: false,
      items: [
        {
          asset: {
            path: itemAsset1,
            transform: {
              scale: 1.15,
              angle: 0,
            },
          },
        },
        {
          asset: {
            path: itemAsset2,
            transform: {
              scale: 0.95,
              angle: 0,
            },
          },
        },
        {
          asset: {
            path: itemAsset3,
            transform: {
              scale: 0.85,
              angle: 0,
            },
          },
        },
        {
          asset: {
            path: itemAsset4,
            transform: {
              scale: 0.65,
              angle: 0.1,
            },
          },
        },
        {
          asset: {
            path: itemAsset5,
            transform: {
              scale: 0.78,
              angle: 0.2,
            },
          },
        },
        {
          asset: {
            path: itemAsset6,
            transform: {
              scale: 0.9,
              angle: 0,
            },
          },
        },
        {
          asset: {
            path: itemAsset7,
            transform: {
              scale: 1.15,
              angle: -0.3,
            },
          },
        },
      ],
    };
  });

  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  const selectOverlayRef = useRef<HTMLImageElement>(null);

  const itemRefs = state.items.map(() => useRef<HTMLImageElement>(null));

  const updateBounds = () => {
    if (itemsContainerRef.current) {
      state.bounds = itemsContainerRef.current.getBoundingClientRect();
    }
  };

  const updatePointerPosition = (event: React.PointerEvent) => {
    updateBounds();

    state.pointer.x = event.clientX - state.bounds.x;
    state.pointer.y = event.clientY - state.bounds.y;
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    if (!event.isPrimary || state.selected) {
      return;
    }

    const { pointer } = state;

    event.preventDefault();

    updatePointerPosition(event);

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.add(styles.pointerDown);
    }

    if (introCompleted) {
      pointer.isDown = true;
      pointer.xPrev = pointer.x;
      pointer.yPrev = pointer.y;

      state.hasChanged = false;
    }
  };

  const handlePointerUp = (event: React.PointerEvent) => {
    if (!event.isPrimary || state.selected) {
      return;
    }

    updatePointerPosition(event);

    if (introCompleted) {
      setHasInteracted(true);
    }

    if (itemsContainerRef.current) {
      itemsContainerRef.current.classList.remove(styles.pointerDown);
    }

    state.pointer.isDown = false;
  };

  const handlePointerMove = (event: React.PointerEvent) => {
    if (!event.isPrimary) {
      return;
    }

    updatePointerPosition(event);
  };

  const handleChoice = (
    index: number,
    type: InteractionType,
    nextRouteIndex: number,
    nextRoute: ROUTES,
    navigateDelay: number
  ) => {
    const options = getInteractionOptions(type);
    const option = options[index];

    if (option) {
      event(EventCategories.MainExperience, "which-hat", option.id);

      updateHistory(option.points, type);

      setTimeout(() => {
        setRouteIndex(nextRouteIndex);
        navigate(nextRoute);
      }, navigateDelay);
    } else {
      throw `Missing option for index ${index}`;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // start initial carousel intro animation
      state.snap = false;
      state.friction = 0.032;
      state.positionPrev = state.position - 0.3;
    }, 4300);
  }, []);

  useEffect(() => {
    let frameRequest: number;
    let lastTime = 0;

    // main frame loop
    const onFrame = (time: number) => {
      // timing
      const delta = clamp(time - lastTime || 1000 / 60, 1, 1000 / 30);
      lastTime = time;
      state.timeBuffer += delta;

      // physics
      let updates = 0;

      while (state.timeBuffer >= state.timestep) {
        // stop simulation after selection
        if (state.select > state.selectThreshold) {
          break;
        }

        updatePhysics(state);

        state.timestamp += state.timestep;
        state.timeBuffer -= state.timestep;

        // switch on snapping once initial spin ends
        if (state.speed < 0.07) {
          state.snap = true;
          state.friction = 1;
        }

        if (introCompleted) {
          if (state.speed > 0.05) {
            // reduce selection when carousel moving
            state.select = clamp(
              state.select + (state.select - 0) * -0.05,
              0,
              1
            );
          }

          if (state.pointer.isDown && state.speed < 0.01 && !state.hasChanged) {
            const deltaX = state.bounds.width * 0.5 - state.pointer.x;
            const deltaY = state.bounds.height * 0.5 - state.pointer.y;
            const distance = sqrt(deltaX * deltaX + deltaY * deltaY);
            const interactionRadius = state.bounds.height * 0.2;

            if (distance < interactionRadius) {
              // increase selection when pointer down and not carousel moving
              state.select = clamp(
                state.select + (state.select - 1) * -0.07,
                0,
                1
              );
            }
          } else {
            // reduce selection when pointer down and carousel moving
            state.select = clamp(
              state.select + (state.select - 0) * -0.03,
              0,
              1
            );
          }
        }

        updates += 1;

        if (updates > state.maxUpdates) {
          break;
        }
      }

      updateBounds();

      // wrap position infinitely
      const itemCount = itemRefs.length;
      const totalDistance = itemCount * state.itemGap;
      const wrapPosition = totalDistance + (state.position % totalDistance);
      const centerOffset = totalDistance * 0.5;

      // render
      for (let i = 0; i < itemCount; i += 1) {
        const itemRef = itemRefs[i];

        if (!itemRef.current) {
          continue;
        }

        // find screen relative position
        const item = state.items[i];
        const itemAssetTransform = item.asset.transform;
        const itemOffset = i * state.itemGap;

        const position =
          ((itemOffset + wrapPosition + 0.5) % totalDistance) - centerOffset;

        const offset = position + 1;

        // scale grows towards center
        const scaleCenter =
          0.5 * clamp(abs(0.7 / (0.3 * offset * offset)), 0, 2);

        // scale grows with selection
        const scaleSelect =
          -0.3 *
          state.select *
          (0.5 - clamp(abs(0.7 / (0.3 * offset * offset)), 0, 2));

        const scale =
          itemAssetTransform.scale *
          0.66 *
          (1.2 + 1.3 * scaleSelect + scaleCenter);

        // gently swing
        const rotateSwing =
          sin(offset * 0.15) * 0.3 +
          sin(offset * 0.5) * 0.3 +
          +0.2 +
          itemAssetTransform.angle;

        const rotateSelect =
          -0.2 * state.select * clamp(abs(0.7 / (0.2 * offset * offset)), 0, 2);

        const rotate = rotateSwing + rotateSelect;

        // position follows a wavy path
        const width = clamp(state.bounds.width, 0, 460);
        const translateX =
          width * -0.3 * cos(position * 0.2 + 2) +
          width * -0.15 * cos(position * 0.2 + 5) +
          width * -0.015 * sin(position * 1) +
          position * -width * 0.055 -
          width * 0.13;

        const translateY =
          width * -0.1 * cos(position * 0.3 + 2) +
          position * state.bounds.height * 0.041 +
          state.bounds.height * 0.045;

        // drop shadow grows with selection
        const shadowX = cos(rotate) * 4;
        const shadowY = -sin(rotate * 1.5) * 14;
        const shadow =
          0.4 *
          state.select *
          clamp(abs(0.7 / (0.2 * offset * offset * offset)), 0, 2);

        // glow pulsing over time
        const glowPulse = isSafari
          ? 0
          : sin(state.timestamp * 0.0015) *
            1.5 *
            sin(1 + state.timestamp * 0.0015) *
            2;

        // glow based on carousel position
        const finalGlow =
          (2.25 + 9 * state.select - 0.38 * glowPulse) *
          clamp(abs(0.7 / (0.2 * offset * offset * offset)), 0, 2);

        // blur based on carousel position and selection
        const finalBlur =
          1.2 *
          state.select *
          (1 - clamp(abs(0.7 / (0.2 * offset * offset * offset)), 0, 2));

        // brightness based on distance from center
        const brightness =
          0.6 +
          0.7 * clamp(abs(20 / (0.25 * offset * offset * offset)), 0, 0.6);

        // apply transforms and effects
        const f = (x: number) => x.toFixed(3);
        const style = itemRef.current.style;

        style.transform = `translate3d(${f(translateX)}px, ${f(
          translateY
        )}px, 0) scale(${f(scale)}) rotate(${f(rotate)}rad)`;

        // increase z-index for center item
        if (abs(offset) < 2) {
          style.zIndex = `1`;
        } else {
          style.zIndex = `0`;
        }

        const glowColor =
          state.select > state.selectThreshold ? "#ff9200" : "#ffcf51";

        const glowFilter =
          finalGlow > 0.5
            ? `drop-shadow(0px 0px ${f(finalGlow)}px ${glowColor})`
            : "";

        const brightnessFilter = `brightness(${f(brightness)})`;

        const blurFilter = finalBlur > 0 ? `blur(${f(finalBlur)}px)` : "";

        const shadowFilter =
          !isSafari && shadow > 0.1
            ? `drop-shadow(${f(shadowX)}px ${f(shadowY)}px 2px rgba(0,0,0,${f(
                shadow
              )}))`
            : "";

        style.transition =
          state.select > state.selectThreshold ? `filter 800ms ease` : ``;

        style.filter = `${brightnessFilter} ${glowFilter} ${shadowFilter} ${blurFilter}`;
      }

      // get unwrapped index in rendered order from position
      const renderedIndex = round(
        snap(wrapPosition + centerOffset - 0.5, state.itemGap) / state.itemGap
      );

      // get wrapped index in InteractionOption order
      const index = itemCount - 1 - (renderedIndex % itemCount);

      if (introCompleted) {
        if (state.index !== index) {
          state.index = index;
          state.hasChanged = true;
        }

        if (!state.selected && state.select > state.selectThreshold) {
          if (itemsContainerRef.current) {
            itemsContainerRef.current.classList.add(styles.showChoice);
          }

          state.selected = true;

          handleChoice(
            state.index,
            InteractionType.WHICH_HAT,
            3,
            ROUTES.CHOOSE_A_SPELL,
            1600
          );
        }
      }

      frameRequest = requestAnimationFrame(onFrame);
    };

    // start the frame loop
    frameRequest = requestAnimationFrame(onFrame);

    return () => {
      cancelAnimationFrame(frameRequest);
    };
  });

  return (
    <div
      ref={itemsContainerRef}
      onPointerMove={handlePointerMove}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      className={cn(
        styles.itemsContainer,
        hasInteracted ? styles.hasInteracted : ""
      )}
    >
      <span
        className={styles.instructionText}
        dangerouslySetInnerHTML={{ __html: t("instructions.tap-to-hold") }}
      />
      <img
        className={styles.backgroundImage}
        src={backgroundAsset}
        role="presentation"
        alt=""
      />

      <img
        ref={selectOverlayRef}
        className={styles.selectOverlay}
        src={selectOverlayAsset}
        role="presentation"
        alt=""
      />

      {state.items.map((item, index) => (
        <img
          key={index}
          ref={itemRefs[index]}
          className={styles.item}
          src={item.asset.path}
          role="presentation"
          alt=""
        />
      ))}
    </div>
  );
};
