/**
 * @fileoverview
 * This acts as a middleman between React, DOM and the game logic. The goal is
 *    to obfuscate irrelevant concerns to the main game.
 */
import { GameEvent, LENS_PROGRESS_TRIGGER } from "./constants";
import { OPTIONS } from "./data";
import { HideAmongThings } from "./game";
import { ObjectToFind } from "./modules/object-to-find";

export class EntryPoint {
  /** Game instance. */
  readonly game: HideAmongThings;
  /** Game over flag. */
  gameOver = false;

  constructor(readonly container: HTMLElement) {
    this.game = new HideAmongThings(container);
    this.game.graphics.ticker.add(this.render);

    this.initialise();
    this.render();
  }

  private _interactive = false;

  set interactive(interactive: boolean) {
    if (this._interactive === interactive) return;
    this._interactive = interactive;

    this.onResize();

    if (this._interactive) {
      this.addUserInputListeners();
    } else {
      this.removeUserInputListeners();
    }
  }

  /** Set's the size of the lens. */
  set lensSize(size: number) {
    this.game.lens.size = size;
  }

  /** Sets the distortion intensity of the lens. */
  set lensDistortion(distortion: number) {
    this.game.graphics.lens.filter.distortion = distortion;
  }

  initialise() {
    window.addEventListener("resize", this.onResize);
  }

  addUserInputListeners() {
    window.addEventListener("pointerdown", this.onPointerDown);
    window.addEventListener("pointermove", this.onPointerMove);
    window.addEventListener("pointerup", this.onPointerUp);
  }

  removeUserInputListeners() {
    window.removeEventListener("pointerdown", this.onPointerDown);
    window.removeEventListener("pointermove", this.onPointerMove);
    window.removeEventListener("pointerup", this.onPointerUp);
    this.onPointerUp();
  }

  dispose() {
    this.removeUserInputListeners();
    window.removeEventListener("resize", this.onResize);
    this.game.graphics.dispose();
  }

  triggerGameOver(currentTarget: ObjectToFind) {
    this.gameOver = true;
    this.removeUserInputListeners();

    const option = OPTIONS[currentTarget.rawData.id];
    const customEvent = new CustomEvent(GameEvent.FINISH, { detail: option });
    this.container.dispatchEvent(customEvent);
  }

  readonly render = () => {
    this.game.animate();

    const { scopeProgress, currentTarget } = this.game.lens;
    const isGameOver = currentTarget && scopeProgress > LENS_PROGRESS_TRIGGER;
    if (isGameOver && !this.gameOver) this.triggerGameOver(currentTarget);
  };

  private setPointerCoordinates(x: number, y: number) {
    const xWithCorrectOffset = Math.round(x);
    const yWithCorrectOffset = Math.round(y);
    this.game.lens.setPointerPosition(xWithCorrectOffset, yWithCorrectOffset);
  }

  private readonly onResize = () => {
    this.game.resize();
    this.game.animate();
  };

  private readonly onPointerDown = (event: PointerEvent) => {
    this.setPointerCoordinates(event.x, event.y);
    this.game.lens.setActive(true);
    this.game.animate();
  };

  private readonly onPointerMove = (event: PointerEvent) => {
    this.setPointerCoordinates(event.x, event.y);
  };

  private readonly onPointerUp = () => {
    this.game.lens.setActive(false);
  };
}
