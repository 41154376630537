import {
  LENS_FILTER_SIZE,
  LensProgressInterpolationStrength,
  LensSizeInterpolationStrength,
} from "../../constants";
import { interpolate } from "../math/interpolate";
import { ObjectToFind } from "../object-to-find";

export class Lens {
  /** Lens size when it is not hovering over anything special in particular. */
  readonly sizeIdle = LENS_FILTER_SIZE;
  /** Pointer position. [x, y]. */
  readonly pointerPosition = [0, 0];
  /** The lens is positioned above the pointer.  the pointer. [x, y]. **/
  readonly pointerOffset = [-32, -LENS_FILTER_SIZE];
  /** The target lens size. */
  size = 0;
  /** The size animated, this value gets represented in the visuals. */
  sizeInterpolated = 0;
  /** Speed at which the size should animate. 1 = instant. 0 = frozen */
  interpolationSpeed = LensSizeInterpolationStrength.ACTIVE;
  /** Whether the lens should be visually rendered or not. */
  active = false;
  /** Current object that's under the lens. */
  currentTarget: ObjectToFind | null = null;
  private readonly _position = [0, 0];
  private _scopeProgressInterpolated = 0;

  get position() {
    return this._position;
  }

  get scopeProgress() {
    return this._scopeProgressInterpolated;
  }

  setActive(active: boolean) {
    this.active = active;

    this.interpolationSpeed = active
      ? LensSizeInterpolationStrength.ACTIVE
      : LensSizeInterpolationStrength.IDLE;
  }

  setPointerPosition(x: number, y: number) {
    this.pointerPosition[0] = x;
    this.pointerPosition[1] = y;
    this._position[0] = x + this.pointerOffset[0];
    this._position[1] = y + this.pointerOffset[1];
  }

  update() {
    this.size = this.calcLensSize();

    const iterateProgress = this.currentTarget && this.active;

    // Interpolate progress line.
    const progressSpeed = iterateProgress
      ? LensProgressInterpolationStrength.ACTIVE
      : LensProgressInterpolationStrength.INACTIVE;
    this._scopeProgressInterpolated = interpolate(
      iterateProgress ? 1 : 0,
      this._scopeProgressInterpolated,
      progressSpeed
    );

    // Interpolate size.
    this.sizeInterpolated = interpolate(
      this.size,
      this.sizeInterpolated,
      this.interpolationSpeed
    );
  }

  private calcLensSize() {
    if (!this.active) return 0;
    if (this.currentTarget) return this.currentTarget.lensSize;
    return this.sizeIdle;
  }
}
