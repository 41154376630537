export const characterNames = [
  { name: "sophie", value: "Sophie" },
  { name: "howl", value: "Howl" },
  { name: "heen", value: "Heen" },
  { name: "witch", value: "MadameSullivan" },
  { name: "turnip-head", value: "TurnipHead" },
  { name: "calcifer", value: "Califer" },
  { name: "markl", value: "Markl" },
  { name: "castle", value: "Castle" },
  { name: "rune", value: "EasterEgg" },
];
