import { Assets, BLEND_MODES, Sprite, Texture } from "pixi.js";
import { ObjectToFind } from "../../object-to-find";

export class ObjectToFindVisual {
  readonly glow: Sprite;

  constructor(readonly rawData: ObjectToFind) {
    // TODO(pimdewit): naming.
    const { path, rect } = rawData.rawData.glowMap;

    const texture = Assets.get(path) as Texture;
    this.glow = new Sprite(texture);
    this.glow.position.set(rect.x, rect.y);
    this.glow.width = rect.width;
    this.glow.height = rect.height;
    this.glow.alpha = 0;
    this.glow.blendMode = BLEND_MODES.ADD;
  }

  resize(scale: number) {
    const { rect } = this.rawData.rawData.glowMap;
    this.glow.position.set(rect.x * scale, rect.y * scale);
    this.glow.width = rect.width * scale;
    this.glow.height = rect.height * scale;
  }

  update() {
    this.glow.alpha = this.rawData.revealedProgress * 3;
  }
}
