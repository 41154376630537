import gsap from "gsap";
import { useEffect, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

import styles from "./TransitionLayout.module.css";

const LIGHT_COLOR = {
  "--color-r": "253",
  "--color-g": "252",
  "--color-b": "240",
};

const DARK_COLOR = {
  "--color-r": "0",
  "--color-g": "0",
  "--color-b": "0",
};

export const TransitionLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const [currentOutlet, setCurrentOutlet] = useState(outlet);
  const [lastLocation, setLastLocation] = useState<string>();

  useEffect(() => {
    const isPageHome = location.pathname === ROUTES.HOME;
    const isPageDoorDial = location.pathname === ROUTES.THE_DOOR_DIAL;
    const isPageHomeToDoorDial = lastLocation === ROUTES.HOME && isPageDoorDial;
    const isPageReveal = location.pathname === ROUTES.CHARACTER_REVEAL;
    const hasPageTransition = !isPageHome;

    setLastLocation(location.pathname);

    const timelineIn = gsap.timeline();

    const fadeInDuration = isPageReveal ? 1.5 : 0.75;
    const fadeOutDuration = isPageReveal ? 5 : 2;
    const fadeOutDelay = isPageReveal ? 0.35 : 0.25;

    if (hasPageTransition) {
      let faderColor = DARK_COLOR;
      if (isPageHomeToDoorDial || isPageReveal) {
        faderColor = LIGHT_COLOR;
      }

      timelineIn
        .set("#transition-fader", faderColor)
        .to("#transition-fader", {
          duration: 0.1,
          opacity: 1,
        })
        .to("#transition-fader", {
          duration: fadeInDuration,
          "--circumference": "0px",
          "--alpha": "1",
          onComplete: () => {
            setCurrentOutlet(outlet);
          },
        })
        .to("#transition-fader", {
          // slight delay for more load in time
          delay: fadeOutDelay,
          duration: 4,
          "--circumference": "300vh",
          "--alpha": "0",
          opacity: 1,
        })
        .to("#transition-fader", {
          duration: fadeOutDuration,
          opacity: 0,
        });
    }

    return () => {
      timelineIn.kill();
    };
  }, [location.pathname]);

  return (
    <>
      <div id="wrapper" className={styles.wrapper}>
        {currentOutlet}
      </div>
      <div id="transition-fader" className={styles.fader} />
    </>
  );
};
