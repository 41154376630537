import { Assets, Sprite, Texture } from "pixi.js";
import { MinimalContainer } from "../../../../../StarDemonGame/core/utilities/minimal-container";
import { LENS_FILTER_SIZE } from "../../../constants";
import { TEXTURES } from "../../../resources";
import { Lens } from "../../lens";
import { DisplacementFilter } from "./displacement-filter";
import { Reticle } from "./reticle/reticle";

export class LensVisual extends MinimalContainer {
  readonly reticle = new Reticle();
  /** Lens displacement. */
  readonly filter: DisplacementFilter;

  constructor() {
    super();

    const sprite = new Sprite(Assets.get(TEXTURES.DISPLACEMENT_MAP) as Texture);
    sprite.width = LENS_FILTER_SIZE;
    sprite.height = LENS_FILTER_SIZE;
    sprite.anchor.set(0.5);
    this.filter = new DisplacementFilter(sprite);

    this.addChild(this.filter.sprite);
    this.addChild(this.reticle);
  }

  update(lens: Lens) {
    const [x, y] = lens.position;

    this.reticle.reticleBlur.blur = lens.active ? 0 : 4;

    this.reticle.resize(lens.sizeInterpolated);
    this.reticle.progress = lens.scopeProgress;
    this.reticle.progressRadial.alpha = lens.scopeProgress;
    this.filter.size = lens.sizeInterpolated;
    this.reticle.x = x;
    this.reticle.y = y;
    this.filter.sprite.x = x;
    this.filter.sprite.y = y;
  }
}
