import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import gsap from "gsap";
import Splitting from "splitting";
import { useNavigate } from "react-router-dom";
import saveAs from "file-saver";
import { Howl } from "howler";

import {
  useAnalyticsEvent,
  EventCategories,
} from "../../hooks/useAnalyticsEvent";

import { Logo } from "../Logo";
import { SvgButton } from "../SvgButton";

import door from "../../assets/images/reveal/door.webp";
import doorPanel from "../../assets/images/reveal/door-panel.webp";
import background from "../../assets/images/reveal/background.webp";
import revealForeground from "../../assets/images/reveal/reveal-foreground.webp";
import cursor from "../../assets/images/reveal/cursor.webp";

import { ROUTES } from "../../constants/routes";
import { characterNames } from "../../constants/characterNames";

import styles from "./RevealCard.module.css";
import { WeChatShare } from "../WeChatShare";

interface RevealCardProps {
  character: string;
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  audio: Howl;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}
export const RevealCard = ({
  character,
  setRouteIndex,
  audio,
  setIsDarkMode,
  setHideNavigation,
}: RevealCardProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const event = useAnalyticsEvent();

  const [isLight, setIsLight] = useState<boolean>(false);
  const [isDark, setIsDark] = useState<boolean>(false);
  const [hasKnocked, setHasKnocked] = useState<boolean>(false);
  const [openDoor, setOpenDoor] = useState<boolean>(false);
  const [hasInstructionFinished, setHasInstructionFinished] = useState(false);
  const [isWeChatShareOpen, setIsWeChatShareOpen] = useState<boolean>(false);
  const lightCharacters = [
    "witch",
    "calcifer",
    "castle",
    "rune",
    "turnip-head",
  ];
  const darkCopy = ["sophie"];

  const tl = useRef<GSAPTimeline>();
  const app = useRef(null);
  const doorRef = useRef<HTMLImageElement>(null);
  const isWeChat =
    /miniprogram/i.test(navigator.userAgent) ||
    /micromessenger/i.test(navigator.userAgent);

  useLayoutEffect(() => {
    if (character) {
      audio.fade(1, 0, 2000).play("white-noise");
      gsap.set("#reveal-headline", { color: "var(--colour-cream)" });
      setIsDarkMode(true);
    }
  }, [character]);

  useLayoutEffect(() => {
    if (character) {
      const ctx = gsap.context(() => {
        tl.current && tl.current.progress(0).kill();
        tl.current = gsap
          .timeline({
            autoRemoveChildren: true,
          })
          .to("#instructions-wrapper", { duration: 2, opacity: 1 }, ">+1")
          .to("#reveal-door-panel", {
            duration: 0.2,
            rotateY: "12deg",
          })
          .to("#instructions-cursor", { duration: 0.2, scale: 0.9 }, "<")
          .to("#reveal-door-panel", {
            duration: 0.2,
            rotateY: "0deg",
          })
          .to("#instructions-cursor", { duration: 0.2, scale: 1 }, "<")
          .to("#reveal-door-panel", {
            duration: 0.2,
            rotateY: "12deg",
          })
          .to("#instructions-cursor", { duration: 0.2, scale: 0.9 }, "<")
          .to("#reveal-door-panel", {
            duration: 0.2,
            rotateY: "0deg",
          })
          .to("#instructions-cursor", { duration: 0.2, scale: 1 }, "<")
          .to(
            "#instructions-cursor",
            {
              duration: 1,
              opacity: 0,
              onComplete: () => {
                setHasInstructionFinished(true);
              },
            },
            ">"
          );
      }, app);

      return () => ctx.revert();
    }
  }, [character]);

  useLayoutEffect(() => {
    if (openDoor) {
      Splitting({
        target: "#reveal-headline",
        by: "chars",
        key: null,
      });
      Splitting({
        target: "#reveal-headline-large",
        by: "chars",
        key: null,
      });
      Splitting({
        target: "#reveal-name",
        by: "chars",
        key: null,
      });

      const ctx = gsap.context(() => {
        tl.current && tl.current.progress(0).kill();
        tl.current = gsap
          .timeline({
            autoRemoveChildren: true,
          })
          .to("#click-target", { display: "none" })
          .to("#reveal-door-panel", {
            duration: 0.9,
            rotateY: "90deg",
            ease: "expo.in",
          })
          .to("#reveal-background", { zIndex: 1 }, ">-0.001")
          .to("#reveal-clouds", { duration: 0.5, y: 500 }, "<")
          .to(
            "#reveal-headline-small",
            {
              opacity: character !== "rune" ? 1 : 0,
              color: isLight
                ? "var(--colour-cream-dark)"
                : "var(--colour-black",
            },
            "<"
          )
          .to(
            "#reveal-door",
            {
              duration: 0.9,
              scale: 4,
              stagger: 0.1,
              ease: "power2.in",
            },
            ">-0.5"
          )
          .to(
            "#reveal-background",
            {
              duration: 0.9,
              scale: 1.2,
              filter: "blur(0px)",
              ease: "power4.in",
              translateY: 0,
              top: 0,
              zIndex: "none",
              stagger: 0.1,
            },
            ">-0.9"
          )
          .to("#reveal-door-parts", { scale: 1 }, ">-0.6")
          .to(
            "#reveal-door",
            {
              opacity: 0,
            },
            ">"
          )

          .to("#reveal-logo", { duration: 0.1, opacity: 1, zIndex: 2 }, ">")
          .set("#reveal-name", { visibility: "visible" })

          .to("#reveal-foreground", { duration: 0.1, opacity: 1 }, ">-1")
          .to("#reveal-character", { duration: 0, opacity: 1 }, ">")
          .to(
            "#reveal-character",
            { duration: 1, zIndex: 1, bottom: 0, ease: "back.out(1)" },
            ">"
          )
          .from(
            "#reveal-name .word",
            {
              duration: 1,
              ease: "sine.out",
              opacity: 0,
              stagger: 0.1,
              y: 20,
            },
            ">"
          )
          .to("#reveal-fade", { opacity: 1 })
          .to(
            "#reveal-copy",
            {
              duration: 0.5,
              ease: "sine.out",
              opacity: 1,
            },
            ">"
          )
          .to("#reveal-buttons", { opacity: 1 })
          .to("#reveal-buttons button", {
            duration: 1,
            opacity: 1,
            scale: 1,
            smoothOrigin: true,
          });
      }, app);

      return () => ctx.revert();
    }
  }, [openDoor]);

  useEffect(() => {
    if (lightCharacters.includes(character)) {
      setIsLight(true);
    }
    if (darkCopy.includes(character)) {
      setIsDark(true);
    }
  }, [character]);

  useEffect(() => {
    if (character) {
      event(
        EventCategories.MainExperience,
        "Reveal",
        `Revealed Character > ${character}`
      );
    }
  }, [character]);

  const handleRestart = () => {
    // audio.stop is called here to prevent a memory leak.
    // When fading audio into another sprite, the track is still playing in
    //  the background, its just muted. audio.stop() clears these.
    audio.stop();
    audio.fade(0, 1, 1200).play("heartbeat");
    setRouteIndex(1);
    navigate(ROUTES.THE_DOOR_DIAL);
    setHideNavigation(false);
    event(
      EventCategories.MainExperience,
      "Play Again",
      `Revealed Character > ${character}`
    );
  };

  const handleReward = () => {
    event(
      EventCategories.MainExperience,
      "Reward",
      `Revealed Character > ${character}`
    );
    setTimeout(() => {
      setRouteIndex(9);
      navigate(ROUTES.REWARDS);
    }, 1000);
  };

  const handleShare = () => {
    const share = async () => {
      const blob = await (
        await fetch(
          `./assets/images/share/${character}-share-${i18n.language}.jpg`
        )
      ).blob();

      const image = new File([blob], `${character}.jpg`, { type: blob.type });
      const files = [image];
      if (navigator.canShare && navigator.canShare({ files: files })) {
        await navigator.share({
          files: files,
        });
      } else {
        saveAs(files[0]);
      }
    };
    event(
      EventCategories.MainExperience,
      "Share",
      `Revealed Character > ${character}`
    );
    setTimeout(() => {
      if (!isWeChat) {
        share();
      } else {
        setIsWeChatShareOpen(true);
      }
    }, 1000);
  };

  const handleDoorKnock = useCallback(() => {
    if (hasKnocked || !hasInstructionFinished) return;

    if (character !== "rune") {
      // audio.stop is called here to prevent a memory leak.
      // When fading audio into another sprite, the track is still playing in
      //  the background, its just muted. audio.stop() clears these.
      audio.stop();
      audio.fade(0, 1, 1200).play("walk-in-the-skies");
    }

    if (hasInstructionFinished) {
      setOpenDoor(true);
      setHideNavigation(true);
      if (isLight) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    }

    setHasKnocked(true);
  }, [hasKnocked, hasInstructionFinished]);

  const handleWeChatClose = () => {
    setIsWeChatShareOpen(false);
  };

  return (
    <>
      <WeChatShare
        character={character}
        isOpen={isWeChatShareOpen}
        handleClose={handleWeChatClose}
      />
      <div
        className={styles.wrapper}
        ref={app}
        style={{ backgroundImage: `url(${background})` }}
      >
        {character && (
          <>
            <div
              id="click-target"
              className={styles.clickTarget}
              onPointerUp={handleDoorKnock}
            >
              &nbsp;
            </div>
            <div id="instructions-wrapper" className={cn(styles.instructions)}>
              <span
                className={cn("heading-two")}
                id="reveal-instructions"
                dangerouslySetInnerHTML={{ __html: t("character-reveal.cta") }}
              />
              <img
                id="instructions-cursor"
                className={styles.cursor}
                src={cursor}
                alt="cursor"
                role="presentation"
              />
            </div>
            <div id="reveal-logo" className={styles.logo}>
              <Logo logoColour={isLight ? "white" : "black"} scale="0.7" />
            </div>
            <picture>
              <source
                type="image/webp"
                srcSet={`./assets/images/characters/${character}-bg.webp`}
              />
              <source
                type="image/jpeg"
                srcSet={`./assets/images/characters/${character}-bg.jpg`}
              />
              <img
                className={styles.characterBackground}
                id="reveal-background"
                src={`./assets/images/characters/${character}-bg.jpg`}
              />
            </picture>
            {openDoor ? (
              <h4
                id="reveal-headline-small"
                className={cn(
                  styles.headline,
                  styles.headlineSmall,
                  "heading-four"
                )}
              >
                {t("character-reveal.main-byline")}
              </h4>
            ) : (
              <h4
                id="reveal-headline-large"
                className={cn(
                  styles.headline,
                  styles.headlineLarge,
                  "heading-two"
                )}
                dangerouslySetInnerHTML={{
                  __html: `${t("character-reveal.main-byline-large")}`,
                }}
              />
            )}
            <h1
              id="reveal-name"
              className={cn(styles.characterName, isLight && styles.isLight)}
            >
              {character !== "rune"
                ? t(`character-reveal.${character}-name`)
                : t("character-reveal.rune-byline")}
            </h1>
            <picture>
              <source
                type="image/webp"
                srcSet={`./assets/images/characters/${character}-foreground.webp`}
              />
              <source
                type="image/png"
                srcSet={`./assets/images/characters/${character}-foreground.png`}
              />
              <img
                id="reveal-foreground"
                className={styles.characterForeground}
                src={`./assets/images/characters/${character}-foreground.png`}
              />
            </picture>
            {character !== "rune" && (
              <picture>
                <source
                  type="image/webp"
                  srcSet={`./assets/images/characters/${character}.webp`}
                />
                <source
                  type="image/png"
                  srcSet={`./assets/images/characters/${character}.png`}
                />
                <img
                  id="reveal-character"
                  src={`./assets/images/characters/${character}.png`}
                  className={styles.character}
                />
              </picture>
            )}
            <h5
              id="reveal-copy"
              className={cn(
                styles.copy,
                isDark ? styles.isDark : styles.isLight,
                "heading-five"
              )}
              dangerouslySetInnerHTML={{
                __html: t(`character-reveal.${character}-copy`),
              }}
            />
            <div
              id="reveal-fade"
              className={cn(
                styles.fade,
                character === "sophie" && styles.light
              )}
            ></div>
            <div id="reveal-buttons" className={cn(styles.navigationWrapper)}>
              <div className={styles.buttons}>
                <SvgButton
                  handler={handleRestart}
                  text={
                    character === "rune"
                      ? t("navigation.try-again")
                      : t("navigation.play-again")
                  }
                  color="#8dbdfa"
                  isSmall={character !== "rune"}
                />

                {character !== "rune" && (
                  <>
                    <SvgButton
                      handler={handleShare}
                      text={t("navigation.share")}
                      color="#f0ecd3"
                    />
                    <SvgButton
                      handler={handleReward}
                      text={t("navigation.rewards")}
                      color="#ff8080"
                      isSmall
                    />
                  </>
                )}
              </div>
              <a
                href={
                  t("character-reveal.collection-url", {
                    name: characterNames.find((c) => c.name === character)
                      ?.value,
                  }) || ""
                }
                target="_blank"
                rel="noreferrer"
                className={cn(isDark ? styles.isDark : styles.isLight)}
                onClick={() => {
                  event(
                    EventCategories.MainExperience,
                    "See the collection",
                    t("character-reveal.collection-url", {
                      name: characterNames.find((c) => c.name === character)
                        ?.value,
                    }) || ""
                  );
                }}
              >
                {t("navigation.discover-the-collection")}
              </a>
            </div>
            <div id="reveal-door-parts" className={styles.doorParts}>
              <div id="reveal-door" className={styles.door}>
                <img src={door} role="presentation" />
              </div>
              <div id="reveal-door-panel" className={styles.doorPanel}>
                <img src={doorPanel} role="presentation" ref={doorRef} />
              </div>
            </div>
            <div id="reveal-clouds" className={styles.clouds}>
              <img src={revealForeground} role="presentation" />
            </div>
          </>
        )}
      </div>
    </>
  );
};
