import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SceneManager } from "../components/SceneManager";
import background from "../components/StarDemonGame/game/resources/textures/background.webp";
import { StarDemonGame } from "../components/StarDemonGame/StarDemonGame";
import {
  getInteractionOptions,
  InteractionOption,
  InteractionType,
} from "../config/interactions";
import { ROUTES } from "../constants/routes";
import { EventCategories, useAnalyticsEvent } from "../hooks/useAnalyticsEvent";
import { useInteractionStore } from "../store/interactionStore";

interface YourStarDemonProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const YourStarDemon = ({ setRouteIndex }: YourStarDemonProps) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const { updateHistory } = useInteractionStore();
  const event = useAnalyticsEvent();
  const options = getInteractionOptions(InteractionType.YOUR_STAR_DEMON);

  const handleIntroductionComplete = (ended: boolean) => setActive(ended);

  const handleChange = (option: InteractionOption) => {
    const points = (option.points % options.length) + 1;
    updateHistory(points, InteractionType.YOUR_STAR_DEMON);
    event(EventCategories.MainExperience, "your-star-demon", option.id);
    setRouteIndex(7);
    navigate(ROUTES.CHARACTER_REVEAL);
  };

  return (
    <SceneManager
      background={background}
      quoteStopPercent={10}
      sceneWidth="200 * var(--dvh)"
      sceneStopPercent={50}
      onHasAnimationCompleted={handleIntroductionComplete}
      scene={<StarDemonGame active={active} onOptionSelected={handleChange} />}
    />
  );
};
