import { SCROLL_AREA } from "./constants";
import { OBJECTS_TO_FIND } from "./data";
import { Graphics } from "./modules/graphics";
import { Lens } from "./modules/lens";
import { rectContainsPoint } from "./modules/math/point-in-rect";
import { ObjectToFind } from "./modules/object-to-find";
import { Scroller } from "./modules/scroller";

export class HideAmongThings {
  /** Temporary graphics rendering. */
  readonly graphics: Graphics;
  /** Ability to pan around the scene, also responsible for scaling the scene to fit the viewport.. */
  readonly scroller = new Scroller(SCROLL_AREA.width, SCROLL_AREA.height);
  /** Objects to find through the pointer. */
  readonly objectsToFind: ObjectToFind[];
  readonly lens = new Lens();

  constructor(container: HTMLElement) {
    this.objectsToFind = OBJECTS_TO_FIND.map((data) => new ObjectToFind(data));
    this.graphics = new Graphics(container, this.objectsToFind);
    this.resize();

    // Center the viewport.
    this.scroller.goToCenter();
    this.graphics.layers.scrollLayer.x = this.scroller.x;
    for (const o of this.objectsToFind) o.syncWithScroller(this.scroller);
  }

  resize() {
    // Resize canvas.
    this.graphics.resize();
    // Update scroller.
    this.scroller.resize(this.graphics.width, this.graphics.height);
    // Sync hit-boxes.
    for (const o of this.objectsToFind) o.syncWithScroller(this.scroller);
    // Sync graphics.
    this.graphics.syncScroller(this.scroller);
  }

  animate() {
    const [x, y] = this.lens.position;
    const isPointerDown = this.lens.active;

    // Update scroller.
    if (isPointerDown) {
      this.scroller.processInput(x);
      this.graphics.layers.scrollLayer.x = this.scroller.x;
      for (const o of this.objectsToFind) o.syncWithScroller(this.scroller);
    }

    // Object detection.
    let underTheLens: ObjectToFind | null = null;
    for (const object of this.objectsToFind) {
      object.revealed =
        this.lens.active && rectContainsPoint(object.hitBox, x, y);
      object.update();
      if (object.revealed) underTheLens = object;
    }
    for (const o of this.graphics.objectsToFind) o.update();

    // Lens.
    this.lens.currentTarget = underTheLens;
    this.lens.update();
    this.graphics.lens.update(this.lens);
  }

  render() {
    this.animate();
  }
}
