import { useEffect } from "react";
import { DoorDial } from "../components/DoorDialGame";
import { useInteractionStore } from "../store/interactionStore";

interface TheDoorDialProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TheDoorDial = ({
  setRouteIndex,
  setIsDarkMode,
}: TheDoorDialProps) => {
  const { reset } = useInteractionStore();
  useEffect(() => {
    reset();
  }, []);
  return (
    <div>
      <DoorDial setRouteIndex={setRouteIndex} setIsDarkMode={setIsDarkMode} />
    </div>
  );
};
