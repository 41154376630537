const characters = [
  { group: 1, min: 0, max: 4, id: "rune" },
  { group: 1, min: 5, max: 8, id: "sophie" },
  { group: 1, min: 9, max: 12, id: "howl" },
  { group: 1, min: 13, max: 16, id: "castle" },
  { group: 1, min: 17, max: 20, id: "witch" },

  { group: 2, min: 0, max: 4, id: "rune" },
  { group: 2, min: 5, max: 8, id: "sophie" },
  { group: 2, min: 9, max: 12, id: "turnip-head" },
  { group: 2, min: 13, max: 16, id: "howl" },
  { group: 2, min: 17, max: 20, id: "calcifer" },

  { group: 3, min: 0, max: 4, id: "rune" },
  { group: 3, min: 5, max: 8, id: "sophie" },
  { group: 3, min: 9, max: 12, id: "heen" },
  { group: 3, min: 13, max: 16, id: "howl" },
  { group: 3, min: 17, max: 20, id: "witch" },

  { group: 4, min: 0, max: 4, id: "rune" },
  { group: 4, min: 5, max: 8, id: "sophie" },
  { group: 4, min: 9, max: 12, id: "markl" },
  { group: 4, min: 13, max: 16, id: "howl" },
  { group: 4, min: 17, max: 20, id: "calcifer" },
];

export const getCharacterChoice = (group: number, value: number) => {
  return characters.find(
    (character) =>
      character.group === group &&
      value >= character.min &&
      value <= character.max
  )?.id;
};
