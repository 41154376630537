import React, { useEffect, useState } from "react";
import { preload } from "../components/ChooseYourSpellGame/game/resources";
import { SceneManager } from "../components/SceneManager";
import { WhichHatGame } from "../components/WhichHatGame";

interface WhichHatProps {
  setRouteIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const WhichHat = ({ setRouteIndex }: WhichHatProps) => {
  const [introCompleted, setIntroCompleted] = useState<boolean>(false);

  useEffect(() => {
    preload();
  }, []);

  return (
    <>
      <SceneManager
        quoteStopPercent={25}
        sceneWidth="272 * var(--dvh)"
        sceneStopPercent={100}
        onHasAnimationCompleted={setIntroCompleted}
        scene={
          <WhichHatGame
            introCompleted={introCompleted}
            setRouteIndex={setRouteIndex}
          />
        }
      />
    </>
  );
};
