import { useEffect, useRef } from "react";
import { InteractionOption } from "../../config/interactions";
import styles from "./ChooseYourSpellGame.module.css";
import { Game, GameEvents } from "./game";

interface Props {
  onOptionSelected: (option: InteractionOption) => void;
  words: [string, string, string, string];
}

export const ChooseYourSpellGame = ({ onOptionSelected, words }: Props) => {
  const container = useRef<HTMLDivElement | null>(null);
  const game = useRef<Game | null>(null);

  function onOptionSelect(event: CustomEventInit) {
    const option = event.detail;
    onOptionSelected(option);
  }

  useEffect(() => {
    const containerElement = container.current;

    if (!game.current && containerElement) {
      game.current = new Game(containerElement, words);
      containerElement.addEventListener(GameEvents.GAME_OVER, onOptionSelect);
    }

    return () => {
      containerElement?.removeEventListener(
        GameEvents.GAME_OVER,
        onOptionSelect
      );
      game.current?.dispose();
      game.current = null;
    };
  }, [container.current]);

  useEffect(() => {
    if (game.current) game.current.words = words;
  }, [words]);

  return (
    <div className={styles.wrapper}>
      <div ref={container} />
    </div>
  );
};
