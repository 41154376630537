import {
  CLEAR_MODES,
  Filter,
  FilterSystem,
  Matrix,
  Rectangle,
  RenderTexture,
} from "pixi.js";
import { fragmentShader, vertexShader } from "./shader.glsl";

export class SharpenFilter extends Filter {
  readonly matrix = new Matrix();

  constructor() {
    const uniforms = { dimensions: new Float32Array(2), uStrength: 0.3 };
    super(vertexShader, fragmentShader, uniforms);
  }

  apply(
    filterManager: FilterSystem,
    input: RenderTexture,
    output: RenderTexture,
    clear: CLEAR_MODES
  ): void {
    const { width, height } = input.filterFrame as Rectangle;

    this.uniforms.dimensions[0] = width;
    this.uniforms.dimensions[1] = height;
    filterManager.applyFilter(this, input, output, clear);
  }
}
