import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

import styles from "./Instructions.module.css";

const BASE_PATH = "./assets/images/instructions/";

export const Instructions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [token, setToken] = useState<string>();
  const [imagePath, setImagePath] = useState<string>();

  useEffect(() => {
    const token = location.pathname.replace("/", "");
    let imagePath = `${token}.png`;

    switch (location.pathname) {
      case ROUTES.CHOOSE_A_SPELL:
      case ROUTES.YOUR_STAR_DEMON:
        imagePath = "choose-a-spell.gif";
        break;
      case ROUTES.WHICH_HAT:
        imagePath = "which-hat.gif";
        break;
      case ROUTES.FEED_CALCIFER:
        imagePath = "feed-calcifer.gif";
        break;
      case ROUTES.HIDE_AMONG_THINGS:
        imagePath = "hide-among-things.gif";
        break;
    }

    setToken(token);
    setImagePath(`${BASE_PATH}${imagePath}`);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.copy}>
        {token && (
          <>
            <h2
              id="title"
              className="heading-two"
              dangerouslySetInnerHTML={{
                __html: `${t(`instructions.${token}-title`)}`,
              }}
            />
            <img className={styles.image} src={imagePath} />
          </>
        )}
      </div>
    </div>
  );
};
